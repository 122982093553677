import { ChangeEvent, useState, useEffect, useMemo } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
} from '@mui/material'
import {
  Table,
  TableHead,
  TableBody,
  TablePagination,
  Button,
  SideBar,
  NotificationDialog,
} from 'components'
import NotificationRow from './components/NotificationRow'
import { settingsApi } from 'resources'
import { notificationApi } from 'resources/notification'
import NotificationForm from './components/NotificationsForm'
import { INotification, INotificationList } from 'models/Notification'
import { DEFAULT_PAGE, ITEMS_PER_PAGE } from 'constants/pagination'
import { capitalizeWords } from 'utils/helpers'
import useDialog from 'hooks/useDialog'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import NotificationDefaultSettings from './components/NotificationDefaultSettings'
import useColumnFiltering from 'hooks/useColumnFiltering'
import NoData from 'components/PageBase/NoData'
import LoadingData from 'components/PageBase/LoadingData'
import { DropdownOption, ToolbarControl } from 'types'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import useLoadingState from 'hooks/useLoadingState'
import useDeviceModes from 'hooks/useDeviceModes'
import {
  addStepsToAssetNotifications,
  addStepsToDeliveryNotifications,
  addStepsToServiceNotifications,
  addStepsToStorageNotifications,
} from './helper'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { setLastModeFilter } from 'redux/notification/Action'

const Notifications = (): React.ReactElement => {
  const [notifications, setNotifications] = useState<INotificationList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [currentNotification, setCurrentNotification] =
    useState<INotification>()
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE)
  const [search, setSearch] = useState('')
  const [openSidebar, setOpenSidebar] = useState(false)
  const [notificationTypes, setNotificationTypes] = useState<DropdownOption[]>(
    [],
  )
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [defaultNotificationsDialog, setDefaultNotificationsDialog] =
    useState(false)

  const { getSettings } = settingsApi()
  const { getMany, getNotificationTypes, remove } = notificationApi()
  const { modes, isLoadingModes } = useDeviceModes()

  const { dialog, displayMessage, closeDialog } = useDialog()
  const { filteredColumns, setFilteredColumns, getDefaultFilters } =
    useColumnFiltering({ displayMessage })
  const { loading, setLoading } = useLoadingState(true)

  const theme = useTheme()

  const dispatch = useDispatch()

  const lastModeFilter = useSelector(
    (state: RootStateOrAny) => state.lastModeFilterReducer,
  )

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => {
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, id]
        : previousValue.filter((rowId) => rowId !== id),
    )
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() =>
      checked ? notifications.items.map((device) => device.id) : [],
    )
  }

  const handleDeleteMany = async () => {
    try {
      await remove(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      setSelectedRows([])
      fetchNotifications()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const getAllNotificationTypes = async () => {
    try {
      const notificationTypes = await getNotificationTypes()
      const capitalizedTypes = notificationTypes.map((type) => ({
        ...type,
        label: capitalizeWords(type.label, '_', ' '),
      }))
      setNotificationTypes(capitalizedTypes)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }

  const handleModeChange = (event: SelectChangeEvent<string>): void => {
    const mode = event.target.value
    // setCurrentMode(mode)
    dispatch(setLastModeFilter(mode))
  }

  const fetchNotifications = async (): Promise<void> => {
    try {
      setLoading(true)
      const notifications = await getMany(currentPage + 1, rowsPerPage, search)
      const filterByMode = notifications.items.filter(
        (notification) => notification.mode === lastModeFilter,
      )
      let withSteps
      if (lastModeFilter === 'rental') {
        withSteps = addStepsToAssetNotifications(filterByMode)
      } else if (lastModeFilter === 'service') {
        withSteps = addStepsToServiceNotifications(filterByMode)
      } else if (lastModeFilter === 'delivery') {
        withSteps = addStepsToDeliveryNotifications(filterByMode)
      } else if (lastModeFilter === 'storage') {
        withSteps = addStepsToStorageNotifications(filterByMode)
      } else if (lastModeFilter === 'vending') {
        setNotifications({
          ...notifications,
          items: [],
        })
      } else {
      }
      setNotifications({
        ...notifications,
        items:
          lastModeFilter === 'all'
            ? notifications.items
            : lastModeFilter === 'vending'
            ? []
            : withSteps,
      })
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const getOrgSettings = async (): Promise<void> => {
    try {
      const settingsRes = await getSettings()
      localStorage.setItem('settings', JSON.stringify(settingsRes))
    } catch {
      // No need to display a <Dialog /> in case of failure, as this API fetch is only to
      // populate org_settings in case the /settings page hasn't been accessed
      // yet. Little edge-case.
      console.warn('Failed to retrieve default settings.')
    }
  }

  const handleClose = () => {
    setCurrentNotification(undefined)
    setOpenSidebar(false)
    setDefaultNotificationsDialog(false)
  }

  useEffect(() => {
    getDefaultFilters()
    fetchNotifications()
    getAllNotificationTypes()
  }, [])

  useEffect(() => {
    fetchNotifications()
  }, [search, currentPage, rowsPerPage])

  useEffect(() => {
    fetchNotifications()
  }, [lastModeFilter])

  useMemo(() => {
    getOrgSettings()
  }, [])

  useEffect(() => {
    // (KD-2759): Do not show 'All modes' under notifications if the org has only
    // one mode enabled. Default to relevant mode
    if (modes.length === 1) {
      dispatch(setLastModeFilter(modes[0].value))
    }
  }, [modes])

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => setOpenSidebar(true)}
        >
          Add Notification
        </Button>
      ),
    },
    {
      display: true,
      render: (
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => setDefaultNotificationsDialog(true)}
        >
          Default Settings
        </Button>
      ),
    },
    {
      display: true,
      render: (
        <FormControl size="small">
          <InputLabel>Filter by mode</InputLabel>
          <Select
            disabled={loading}
            label="Filter by mode"
            value={lastModeFilter}
            onChange={handleModeChange}
            inputProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundImage: 'none',
                    boxShadow: 3,
                    backgroundColor:
                      theme.palette.mode === 'dark' ? '#2A2E34' : '#f7f7f7',
                  },
                },
              },
            }}
          >
            {isLoadingModes && (
              <MenuItem key="loading" value="loading">
                Loading...
              </MenuItem>
            )}
            {!isLoadingModes && modes.length !== 1 && (
              <MenuItem value="all">All modes</MenuItem>
            )}
            {!isLoadingModes &&
              modes.map((mode) => (
                <MenuItem key={mode.value} value={mode.value}>
                  {mode.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ),
    },
  ]

  return (
    <>
      <Toolbar controls={controls.filter((control) => control.display)}>
        <ToolbarControls
          controls={controls.filter((control) => control.display)}
        />
        <ToolbarSearchbar
          handleSearch={handleSearch}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
        />
      </Toolbar>
      {selectedRows.length > 0 && (
        <MultiSelectToolbar
          itemsSelected={selectedRows.length}
          handleAction={handleDeleteMany}
        />
      )}
      {!loading && notifications.items.length > 0 && (
        <>
          <Table>
            <TableHead
              headers={filteredColumns.filter((c) => c.active)}
              handleSelectAll={handleSelectAll}
            />
            <TableBody>
              {notifications.items.map((notification) => (
                <NotificationRow
                  key={Math.random()}
                  notification={notification}
                  success={() => {
                    fetchNotifications()
                  }}
                  filteredColumns={filteredColumns}
                  handleOpen={() => {
                    setOpenSidebar(true)
                  }}
                  setCurrentNotification={setCurrentNotification}
                  displayMessage={displayMessage}
                  handleSelectRow={handleSelectRow}
                  selected={selectedRows.includes(notification.id)}
                />
              ))}
            </TableBody>
          </Table>

          <TablePagination
            totalItems={notifications.total}
            currentPage={currentPage}
            itemsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setRowsPerPage}
          />
        </>
      )}

      <LoadingData isLoading={loading} />

      <NoData condition={!loading && notifications.items.length === 0} />

      {openSidebar && (
        <SideBar open={openSidebar} onClose={handleClose}>
          <NotificationForm
            notification={currentNotification}
            success={() => fetchNotifications()}
            onClose={handleClose}
            displayMessage={displayMessage}
            modes={modes}
            notificationTypes={notificationTypes}
          />
        </SideBar>
      )}
      <NotificationDialog
        open={dialog.isOpen}
        onClose={closeDialog}
        message={dialog.message}
        type={dialog.type}
      />

      <NotificationDefaultSettings
        open={defaultNotificationsDialog}
        onClose={handleClose}
      />
    </>
  )
}

export default Notifications
