import { Card, CircularProgress, Typography } from '@mui/material'

interface ILoadingDataProps {
  isLoading: boolean
}

const LoadingData = ({ isLoading }: ILoadingDataProps) => {
  return (
    <>
      {isLoading ? (
        <Card
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            minHeight: '700px',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <CircularProgress size={80} color="primary" />
          <Typography fontSize={12} color="text.secondary">
            Loading data...
          </Typography>
        </Card>
      ) : null}
    </>
  )
}

export default LoadingData
