import { Button, Card, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { saveAs } from 'file-saver'
import { ConfirmDialog, NotificationDialog } from 'components/Dialog'
import { createCsvBlob } from 'utils/helpers'
import { DeviceType, IDevice } from 'models'
import { DEVICE_TYPES } from 'pages/Doors/constants'

interface IMultiSelectToolbarProps {
  // Pass down selected items to export them as CSV:
  itemsSelectedData?: any
  // To avoid breaking other components that depend on this component
  // we won't remove the "itemsSelected" param. This param simply
  // holds the length of the "itemsSelectedData" array
  itemsSelected: number
  handleAction?: () => void
  setAction?: React.Dispatch<React.SetStateAction<string>>
  actionsAllowed?: string[]
}

const MultiSelectToolbar = ({
  itemsSelectedData,
  itemsSelected,
  handleAction,
  setAction,
  actionsAllowed = ['delete'],
}: IMultiSelectToolbarProps) => {
  const [confirmationMessage, setConfirmationMessage] = useState({
    isOpen: false,
    message: '',
  })

  const [notificationMessage, setNotificationMessage] = useState({
    isOpen: false,
    message: '',
  })

  const onConfirm = () => {
    handleAction?.()
    closeDialog()
  }

  const setHardwareDetails = (hw: IDevice) => {
    const o = {
      linka: {
        mac_address: hw.mac_address,
      },
      spintly: {
        integration_id: hw.integration_id,
      },
      ojmar: {
        locker_udn: hw.locker_udn,
        user_code: hw.user_code,
        master_code: hw.master_code,
      },
      gantner: {
        gantner_id: hw.gantner_id,
      },
      harbor: {
        harbor_tower_id: hw.harbor_tower_id,
        harbor_locker_id: hw.harbor_locker_id,
      },
      dclocker: {
        dclock_terminal_no: hw.dclock_terminal_no,
        dclock_box_no: hw.dclock_box_no,
      },
    }

    return o[hw.hardware_type]
  }

  const convertArrayOfObjectsToCSV = (arrayData) => {
    // Array of headers supported by our bulk upload API.
    // Header order is important:
    const mainHeaders = new Set([
      'id',
      'id_location',
      'id_price',
      'id_size',
      'name',
      'custom_identifier',
      'item',
      'item_description',
      'locker_number',
      'mode',
      'price_required',
      'hardware_type',
      'status',
      'lock_status',
    ])

    const hardwareHeaders = new Set([
      'mac_address',
      'integration_id',
      'locker_udn',
      'user_code',
      'master_code',
      'gantner_id',
      'harbor_tower_id',
      'harbor_locker_id',
      'dclock_terminal_no',
      'dclock_box_no',
    ])

    const headers = new Set([...mainHeaders, ...hardwareHeaders])

    // Remove any properties that are not valid:
    let devicesData = arrayData
    for (let obj of devicesData) {
      for (let prop of Object.keys(obj)) {
        if (!headers.has(prop)) {
          delete obj[prop]
        }
      }
    }

    const csvRows: any = []

    // Set array to clean hardware CSV headers:
    const csvHardwareHeaders = Array.from(hardwareHeaders)

    // Get headers from the first hardware selected as reference:id_location
    const hardwareParsedHeaders = csvHardwareHeaders.filter(
      (header) => arrayData[0][header] !== null,
    )

    // Create CSV header:
    csvRows.push([...mainHeaders, ...hardwareParsedHeaders])

    // Force rows to follow exact format even if null values are present
    for (const obj of devicesData) {
      const hardwareInfo = setHardwareDetails(obj)

      // Create ordered CSV row with device data
      const deviceParsedData = Object.values({
        id: obj.id,
        id_location: obj.id_location,
        id_price: obj.id_price,
        id_size: obj.id_size,
        name: obj.name,
        custom_identifier: obj.custom_identifier,
        item: obj.item,
        item_description: obj.item_description,
        locker_number: obj.locker_number,
        mode: obj.mode,
        price_required: obj.price_required,
        hardware_type: obj.hardware_type,
        status: obj.status,
        lock_status: obj.lock_status,
        ...hardwareInfo,
      })

      csvRows.push(deviceParsedData)
    }

    // Start creation of CSV blob to download:
    const csvBlob = createCsvBlob(csvRows.join('\n'))

    saveAs(csvBlob, 'devices_export.csv')

    setNotificationMessage({
      isOpen: true,
      message: `Exported ${itemsSelected} item${
        itemsSelected > 1 ? 's' : ''
      } successfully`,
    })
  }

  const handleExportItems = () => {
    for (const obj of itemsSelectedData) {
      if (obj.hardware_type !== itemsSelectedData[0].hardware_type) {
        setNotificationMessage({
          isOpen: true,
          message: `Cannot export items with different hardware types`,
        })
        return
      }
    }

    convertArrayOfObjectsToCSV(itemsSelectedData)
  }

  const closeDialog = () => {
    setConfirmationMessage({ isOpen: false, message: '' })
    setNotificationMessage({ isOpen: false, message: '' })
  }

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: '5px',
        }}
      >
        <Typography color="text.secondary">
          {itemsSelected} item{itemsSelected > 1 ? 's' : ''} selected
        </Typography>
        <Stack direction="row" spacing={2}>
          {itemsSelectedData && (
            <Button variant="contained" onClick={handleExportItems}>
              Export
            </Button>
          )}

          {actionsAllowed.includes('edit') && (
            <Button
              variant="contained"
              onClick={() => {
                setAction?.('edit')
              }}
            >
              Edit
            </Button>
          )}

          {actionsAllowed.includes('delete') && (
            <Button
              variant="contained"
              onClick={() => {
                setAction?.('delete')
                setConfirmationMessage({
                  isOpen: true,
                  message: `Are you sure you want to delete ${itemsSelected} item${
                    itemsSelected > 1 ? 's' : ''
                  }?`,
                })
              }}
            >
              Delete
            </Button>
          )}

          {actionsAllowed.includes('putUnderMaintenance') && (
            <Button
              variant="contained"
              onClick={() => {
                setAction?.('putUnderMaintenance')
                setConfirmationMessage({
                  isOpen: true,
                  message: `Are you sure you want to switch ${itemsSelected} device${
                    itemsSelected > 1 ? 's' : ''
                  } to maintenance mode?`,
                })
              }}
            >
              Put under maintenance
            </Button>
          )}

          {actionsAllowed.includes('end') && (
            <Button
              variant="contained"
              onClick={() => {
                setAction?.('end')
                setConfirmationMessage({
                  isOpen: true,
                  message: `Are you sure you want to end ${itemsSelected} transaction${
                    itemsSelected > 1 ? 's' : ''
                  }?`,
                })
              }}
            >
              End
            </Button>
          )}
        </Stack>
      </Card>
      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={closeDialog}
        onClickConfirm={onConfirm}
        onClickCancel={closeDialog}
        confirmText="Yes"
        cancelText="No"
      />
      <NotificationDialog
        open={notificationMessage.isOpen}
        message={notificationMessage.message}
        onClose={closeDialog}
      />
    </>
  )
}

export default MultiSelectToolbar
