import {
  Checkbox,
  FormControlLabel,
  Typography,
  TextField,
  Grid,
  Box,
} from '@mui/material'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { Form, SelectField, ToggleField, Tabs, IDialogType } from 'components'
import { IPrice, PriceTypes } from 'models'
import { SyntheticEvent, useEffect, useState } from 'react'
import { getUserRole, parseCurrency, parseUnit } from 'utils/helpers'
import { priceApi, settingsApi } from 'resources'
import { PRICE_TYPES, TIME_UNITS, WEIGHT_UNITS } from '../constants'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import { BulkUploadEntity } from 'components/BulkUploadEntity'
import useLoadingState from 'hooks/useLoadingState'
import { RootStateOrAny, useSelector } from 'react-redux'

interface IPriceFormProps {
  price: IPrice | undefined
  defaultPriceExists: boolean
  onClose: () => void
  displayMessage: (message: string | JSX.Element, type?: IDialogType) => void
  success: (showNewestFirst?: boolean) => void
}

const PriceForm = ({
  price,
  defaultPriceExists,
  onClose,
  displayMessage,
  success,
}: IPriceFormProps) => {
  const [amount, setAmount] = useState<any>(price?.amount)
  const [cardOnFile, setCardOnFile] = useState(price?.card_on_file)
  const [unit_amount, setUnitAmount] = useState<any>(price?.unit_amount)
  const [priceDisplayStr, setPriceDisplayStr] = useState<string>('')
  const [defaultPrice, setDefaultPrice] = useState<IPrice | null>(null)

  const orgSettings = JSON.parse(localStorage.getItem('settings') || '{}')
  const { getSettings } = settingsApi()

  const { create, update } = priceApi()
  const { loading, setLoading } = useLoadingState()

  const [currentTab, setCurrentTab] = useState<string>('IndividualUpload')

  const currency = useSelector(
    (state: RootStateOrAny) => state.currencyReducer.currency,
  )

  const methods = useForm<IPrice>({
    defaultValues: {
      ...price,
      price_type: price ? price.price_type : PriceTypes.pay_per_time,
    },
  })

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }

  const onSubmit: SubmitHandler<IPrice> = async (newPrice) => {
    try {
      setLoading(true)
      const payload = { ...newPrice, currency }
      if (price) {
        await update(price.id, payload)
        onClose()
        displayMessage('Price updated successfully!', 'success')
        success()
      } else {
        await create(payload)
        onClose()
        displayMessage('Price created successfully!', 'success')
        success(true)
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleUnitAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target

    setUnitAmount(value)
    methods.setValue('unit_amount', parseFloat(value))
  }

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setAmount(isNaN(parseFloat(value)) ? '' : parseFloat(value))
    methods.setValue('amount', parseFloat(value))

    if (parseFloat(value) > 0) {
      methods.setValue('card_on_file', true)
      setCardOnFile(true)
    }
  }

  const handleCardOnFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCardOnFile(event.target.checked)
    methods.setValue('card_on_file', event.target.checked)
  }

  const handleDefaultPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked && defaultPrice) {
      methods.setValue('name', defaultPrice.name)
      methods.setValue('amount', defaultPrice.amount)
      methods.setValue('unit', defaultPrice.unit)
      methods.setValue('price_type', defaultPrice.price_type)
      methods.setValue('currency', defaultPrice.currency)
      methods.setValue('prorated', defaultPrice.prorated)

      // Set local state variables too
      setAmount(defaultPrice.amount)
      setUnitAmount(defaultPrice.unit_amount)
      setCardOnFile(defaultPrice.card_on_file)
    } else {
      // Reset the form values and local state variables to their initial state
      methods.reset()
      setAmount(price?.amount)
      setUnitAmount(price?.unit_amount)
      setCardOnFile(price?.card_on_file)
    }
  }

  const priceType = methods.watch('price_type')

  useEffect(() => {
    if (methods.watch('unit') && amount >= 0 && !isNaN(amount)) {
      setPriceDisplayStr(
        `${parseCurrency(currency)} ${amount} / ${
          priceType === 'pay_per_time' ? unit_amount : ''
        } ${parseUnit(methods.watch('unit'))}${
          priceType === 'pay_per_time' && unit_amount != 1 ? 's' : ''
        }`,
      )
    }
  }, [methods.watch('unit'), amount, unit_amount])

  useEffect(() => {
    methods.resetField('unit')
  }, [priceType])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  const PriceFormElement = (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit}>
        <FormWrapper title={price ? 'Edit Price' : 'Add Price'}>
          {defaultPrice && (
            <FormControlLabel
              control={
                <Checkbox
                  name="default_price"
                  onChange={handleDefaultPriceChange}
                />
              }
              label="Set to default price"
            />
          )}
          <TextField
            label="Name"
            placeholder="Name"
            required
            {...methods.register('name')}
          />
          <SelectField
            label="Unit"
            items={PRICE_TYPES}
            {...methods.register('price_type')}
            required
          />
          <TextField
            inputProps={{
              inputMode: 'numeric',
            }}
            name="amount"
            type="text"
            label="Amount"
            placeholder="Amount"
            onChange={handleAmountChange}
            defaultValue={amount}
            required
          />

          {amount === 0 && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={cardOnFile}
                  name="card_on_file"
                  onChange={handleCardOnFileChange}
                />
              }
              label="Card on file"
            />
          )}

          {methods.watch('price_type') === PriceTypes.pay_per_time && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                  type="text"
                  label="Unit Amount"
                  placeholder="Unit Amount"
                  onChange={handleUnitAmountChange}
                  value={unit_amount}
                  required
                />
              </Grid>
              <Grid item xs={8}>
                <SelectField
                  name="unit"
                  label="Unit"
                  items={TIME_UNITS}
                  required
                />
              </Grid>
            </Grid>
          )}

          {methods.watch('price_type') === PriceTypes.pay_per_weight && (
            <SelectField
              name="unit"
              label="Unit"
              items={WEIGHT_UNITS}
              required
            />
          )}

          <Box sx={{ display: 'flex' }}>
            <ToggleField name="prorated" label="Prorated" />
            {/* If no default price yet we show this checkbox */}
            {(!defaultPriceExists || (price && price?.default)) && (
              <ToggleField name="default" label="Set as default price" />
            )}
          </Box>

          {methods.watch('unit') &&
            amount >= 0 &&
            !isNaN(amount) &&
            amount != '' && (
              <Typography variant="h3" textAlign="center">
                {priceDisplayStr}
              </Typography>
            )}

          <FormActions
            onClose={onClose}
            loading={loading}
            disableSubmit={disableSubmit}
          />
        </FormWrapper>
      </Form>
    </FormProvider>
  )

  return (
    <>
      {(price || (getUserRole() !== 'admin' && !price)) && PriceFormElement}
      {getUserRole() === 'admin' && !price && (
        <Tabs
          tabs={[
            {
              label: 'Individual Upload',
              value: 'IndividualUpload',
              children: PriceFormElement,
            },
            {
              label: 'Bulk Upload',
              value: 'BulkUpload',
              children: (
                <BulkUploadEntity
                  entity="pay-per"
                  successForm={success}
                  onClose={onClose}
                  displayMessage={displayMessage}
                />
              ),
            },
          ]}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}
    </>
  )
}

export default PriceForm
