import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Typography,
  TextField as MUITextField,
  CircularProgress,
} from '@mui/material'
import { Form, IDialogType, SideBar } from 'components'
import {
  IDevice,
  ILocation,
  ILocationList,
  IPriceList,
  ISizeList,
} from 'models'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { deviceApi, locationApi, priceApi, sizeApi } from 'resources'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import { getDeviceStatusColor, parseCurrency } from 'utils/helpers'
import DeviceForm from 'pages/Doors/components/devices/DeviceForm'
import useLoadingState from 'hooks/useLoadingState'
import useIsSuperTenantOrg from 'hooks/useIsSuperTenantOrg'
import { DropdownOption } from 'types'

interface ISettingsTabProps {
  location: ILocation | undefined
  onClose: () => void
  success: () => void
  displayMessage?: (message: string | JSX.Element, type?: IDialogType) => void
}

const SettingsTab = ({
  location,
  onClose,
  success,
  displayMessage,
}: ISettingsTabProps) => {
  const [currentDevice, setCurrentDevice] = useState<IDevice>()

  const [search, setSearch] = useState('')

  const [sizes, setSizes] = useState<ISizeList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [prices, setPrices] = useState<IPriceList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [locations, setLocations] = useState<ILocationList>({
    items: [],
    total: 0,
    pages: 0,
  })

  const settings = JSON.parse(localStorage.getItem('settings') || '{}')

  const [assignedDevices, setAssignedDevices] = useState<IDevice[]>([])
  const [devices, setDevices] = useState<IDevice[]>([])
  const [devicesToDelete, setDevicesToDelete] = useState<string[]>([])
  const [devicesToAdd, setDevicesToAdd] = useState<string[]>([])
  const [options, setOptions] = useState<DropdownOption[]>([])
  const [isLoadingDevices, setIsLoadingDevices] = useState<boolean>()
  const [isSubOrg, setIsSubOrg] = useState<boolean>()
  const [useDefaultSupportInformation, setUseDefaultSupportInformation] =
    useState<boolean>(location ? false : true)

  const { getDevices, update, getMany: getLocations } = locationApi()

  const { update: updateDevice, getMany } = deviceApi()
  const { loading, setLoading } = useLoadingState()
  const { getMany: getSizes } = sizeApi()
  const { getMany: getPrices } = priceApi()

  const isSuperTenantOrg = useIsSuperTenantOrg()

  const sizeOptions = useMemo(
    () =>
      sizes.items.map((size) => ({
        value: size.id,
        label: `${size.name} (${size.width}" x ${size.depth}" x ${size.height}")`,
      })),
    [sizes],
  )

  const priceOptions = useMemo(
    () =>
      prices.items.map((price) => ({
        value: price.id,
        label: `${price.name} (${parseCurrency(price.currency)} ${
          price.amount
        } / ${price.unit})`,
        priceType: price.price_type || '',
      })),
    [prices],
  )

  const locationOptions = useMemo(
    () =>
      locations.items.map((location) => ({
        value: location.id,
        label: location.name,
      })),
    [locations],
  )

  const getFormTitle = useCallback(() => {
    if (location && isSuperTenantOrg) {
      return 'Edit Location'
    } else if (!location) {
      return 'Add Location'
    } else if (location && location.shared && !isSuperTenantOrg)
      return 'Location Details'
  }, [isSuperTenantOrg])

  const methods = useForm<ILocation>({
    defaultValues: {
      ...location,
      contact_email:
        useDefaultSupportInformation && settings.default_support_email
          ? settings.default_support_email
          : location?.contact_email,
      contact_phone:
        useDefaultSupportInformation && settings.default_support_phone
          ? settings.default_support_phone
          : location?.contact_phone,
    },
  })

  const fetchDevices = async (): Promise<void> => {
    try {
      const devices = await getMany(1, 10000, '')
      setDevices(devices.items)
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    }
  }

  const fetchAssignedDevices = async (): Promise<void> => {
    try {
      if (location) {
        setIsLoadingDevices(true)
        const devices = await getDevices(location?.id)
        setAssignedDevices(devices)
      }
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setIsLoadingDevices(false)
    }
  }

  const handleSelect = (deviceId: string) => {
    if (devicesToDelete.includes(deviceId)) {
      setDevicesToDelete(devicesToDelete.filter((id) => id !== deviceId))
    } else {
      setDevicesToDelete((prevState) => [...prevState, deviceId])
    }
  }

  const handleToggle = (event: React.SyntheticEvent, value: any) => {
    const devicesSelected: string[] = value.map((option) => option.value)
    setDevicesToAdd(devicesSelected)
  }

  const buildOptions = () => {
    if (devices.length > 0) {
      const notAssignedUsers = devices.filter(
        (device) => !assignedDevices?.some((d) => d.id === device.id),
      )
      const autoCompleteOptions = notAssignedUsers.map((device) => ({
        label: device.name,
        value: device.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setOptions(sortedOptions)
    }
  }

  const handleSubmit = async (data: ILocation) => {
    try {
      setLoading(true)
      if (location) {
        const payload = new FormData()
        payload.append('contact_email', data.contact_email)
        payload.append('contact_phone', data.contact_phone)
        payload.append('name', data.name)
        payload.append('address', data.address)
        payload.append('latitude', data.latitude.toString())
        payload.append('longitude', data.longitude.toString())

        const updatedLocation: ILocation = await update(location.id, payload)
        let finalMessage: JSX.Element | string = 'Location updated successfully'
        if (devicesToAdd.length > 0) {
          const promises = devicesToAdd.map(async (deviceId) => {
            const device = devices.find((device) => device.id === deviceId)
            if (device) {
              const payload = new FormData()
              payload.append('id_location', updatedLocation.id)
              payload.append('name', device.name)
              payload.append('price_required', device.price_required.toString())
              payload.append('id_price', device.id_price || '')
              payload.append(
                'locker_number',
                device.locker_number?.toString() || '',
              )
              return await updateDevice(payload, deviceId)
            }
          })
          const results = await Promise.all(promises)
          const failed = results.filter((promise) => promise?.status !== 200)
          const success = results.filter((promise) => promise?.status === 200)
          const errorMessage = (
            <>
              {failed.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  Error{failed.length > 1 ? 's' : ''}:
                  <br />
                  {failed.map((f, index) => (
                    <Box key={index}>
                      <br /> {`• ${f?.result}`}
                    </Box>
                  ))}
                </Box>
              )}
            </>
          )
          const successMessage = (
            <>
              {success.length > 0 && (
                <Box>
                  {`Successfully assigned ${success.length} device${
                    success.length > 1 ? 's' : ''
                  } to location.`}
                  <br />
                  <br />
                </Box>
              )}
              {success.length === 0 && null}
            </>
          )
          finalMessage = (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {updatedLocation.contact_email !== data.contact_email ||
                (updatedLocation.contact_phone !== data.contact_phone && (
                  <>
                    <Box>
                      Location updated successfully! <br /> <br />
                    </Box>
                  </>
                ))}
              {successMessage}
              {errorMessage}
            </Box>
          )
        }
        onClose()
        success()
        displayMessage?.(finalMessage, 'success')
      }
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const fetchSizes = async (): Promise<void> => {
    const sizes = await getSizes(1, 10000, search)
    setSizes(sizes)
  }

  const fetchPrices = async (): Promise<void> => {
    const prices = await getPrices(1, 10000, search)
    setPrices(prices)
  }

  const fetchLocations = async (): Promise<void> => {
    const locations = await getLocations(1, 10000, search)
    setLocations(locations)
  }

  useEffect(() => {
    buildOptions()
  }, [devices])

  useEffect(() => {
    methods.setValue(
      'contact_email',
      useDefaultSupportInformation ? settings.default_support_email : '',
    )
    methods.setValue(
      'contact_phone',
      useDefaultSupportInformation ? settings.default_support_phone : '',
    )
  }, [useDefaultSupportInformation])

  useEffect(() => {
    fetchDevices()
    fetchSizes()
    fetchPrices()
    fetchLocations()

    if (location) {
      fetchAssignedDevices()
    }
  }, [])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  const isReadOnly = Boolean(location && location.shared && !isSuperTenantOrg)

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit}>
          <FormWrapper title={getFormTitle()}>
            <Typography
              variant="h5"
              sx={{
                textAlign: 'left',
              }}
            >
              Devices assigned to this location
            </Typography>

            {/* Tenant view CRUD permissions */}
            {!isReadOnly && (
              <>
                {!isLoadingDevices && (
                  <Typography variant="caption" color="rgb(173, 176, 187)">
                    {assignedDevices?.length > 0
                      ? `${assignedDevices?.length} device${
                          assignedDevices?.length > 1 ? 's' : ''
                        } assigned to this location.`
                      : `No devices assigned to this location.`}
                  </Typography>
                )}
                {isLoadingDevices && <CircularProgress />}
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    gap: '0.25rem',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {assignedDevices?.length > 0 &&
                    assignedDevices?.map((device) => (
                      <Chip
                        key={device.id}
                        label={device.name}
                        variant="outlined"
                        color="default"
                        sx={{
                          backgroundColor: getDeviceStatusColor(
                            device.status,
                            'available',
                          ),
                        }}
                        onClick={() => setCurrentDevice(device)}
                        disabled={isSubOrg}
                      />
                    ))}
                </Box>

                <Typography
                  variant="h5"
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  Assign devices to location
                </Typography>
                <Typography variant="caption" color="rgb(173, 176, 187)">
                  Select devices that you want to assign to location and hit
                  submit
                </Typography>
                <Autocomplete
                  multiple
                  options={options}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.label}
                  disabled={isSubOrg}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <MUITextField {...params} label="Search devices..." />
                  )}
                  onChange={handleToggle}
                  loading={options.length === 0}
                />
              </>
            )}
            {/* End of Tenant view */}

            {/* Read Only view for sub-orgs without tenant capabilities */}
            {isReadOnly && (
              <>
                <Typography variant="caption" color="rgb(173, 176, 187)">
                  {location?.devices && location.devices.length > 0
                    ? `${location.devices.length} device${
                        location.devices.length > 1 ? 's' : ''
                      } assigned to this location.`
                    : `No devices assigned to this location.`}
                </Typography>

                {location?.devices && location.devices.length > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      flexDirection: 'row',
                      gap: '0.25rem',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {location.devices.map((device) => (
                      <Chip
                        key={device.id}
                        label={device.name}
                        variant="outlined"
                        color="default"
                        sx={{
                          backgroundColor: getDeviceStatusColor(
                            device.status,
                            'available',
                          ),
                        }}
                        // onClick={() => setCurrentDevice(device)}
                        // disabled={isSubOrg}
                      />
                    ))}
                  </Box>
                )}
              </>
            )}
            {/* End of read only view */}

            {!isReadOnly && (
              <FormActions
                onClose={onClose}
                loading={loading}
                disableSubmit={disableSubmit || isSubOrg}
              />
            )}
          </FormWrapper>
        </Form>
      </FormProvider>

      {currentDevice && (
        <SideBar
          open={!!currentDevice}
          onClose={() => setCurrentDevice(undefined)}
        >
          <DeviceForm
            device={currentDevice}
            onClose={() => {
              setCurrentDevice(undefined)
            }}
            sizeOptions={sizeOptions}
            priceOptions={priceOptions}
            locationOptions={locationOptions}
            allowEdit={false}
          />
        </SideBar>
      )}
    </>
  )
}

export default SettingsTab
