import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Menu as MenuIcon,
  KeyboardArrowDown,
  Person,
  QuestionMark,
} from '@mui/icons-material'
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Menu,
  Typography,
  Button,
  CircularProgress,
  Link,
  Tooltip,
  useTheme,
} from '@mui/material'
import { PAGES } from '../../../constants'
import PropTypes from 'prop-types'
import { ProfileDropdown } from 'components'
import { ISession } from 'models/Session'
import HelpItems from './HelpItems'
import FeedbackForm from './FeedbackForm'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import { useDispatch } from 'react-redux'
import { setDarkMode } from 'redux/customizer/Action'

const Header = ({ sx, customClass, toggleSidebar, toggleMobileSidebar }) => {
  const [anchorEl4, setAnchorEl4] = useState(null)
  const [helpSideBarOpen, setHelpSideBarOpen] = useState(false)
  const [feedbackFormOpen, setFeedbackFormOpen] = useState(false)

  const [session, setSession] = useState<ISession>({
    email: '',
    enabled: true,
    first_name: '',
    id_locations: [],
    last_name: '',
    name: '',
    role: '',
    user_id: '',
    user_status: '',
  })

  const location = useLocation()
  const dispatch = useDispatch()
  const theme = useTheme()

  const pageTitle = useMemo(() => PAGES[location.pathname], [location])

  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget)
  }

  const handleClose4 = () => {
    setAnchorEl4(null)
  }

  const handleLogout = () => {
    window.location.href = '/auth/login'
  }

  const toggleHelpSideBar = () => {
    setHelpSideBarOpen((previousValue) => !previousValue)
  }

  const handleThemeChange = () => {
    dispatch(setDarkMode(theme.palette.mode === 'light' ? 'dark' : 'light'))
    localStorage.setItem(
      'themeMode',
      theme.palette.mode === 'light' ? 'dark' : 'light',
    )
  }

  useEffect(() => {
    const getSession = async () => {
      const userSession: ISession = JSON.parse(
        localStorage.getItem('userSession') || '{}',
      )
      setSession(userSession)
    }
    getSession()
  }, [])

  return (
    <>
      <AppBar sx={sx} elevation={0} className={customClass}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleSidebar}
            size="large"
            sx={{
              display: {
                lg: 'flex',
                xs: 'none',
              },
            }}
          >
            <MenuIcon />
          </IconButton>

          <IconButton
            size="large"
            color="inherit"
            aria-label="menu"
            onClick={toggleMobileSidebar}
            sx={{
              display: {
                lg: 'none',
                xs: 'flex',
              },
            }}
          >
            <MenuIcon sx={{ width: '20px', height: '20px' }} />
          </IconButton>
          {/* ------------ End Menu icon ------------- */}
          <Typography variant="h4">{pageTitle}</Typography>
          <Box flexGrow={1} />
          <Tooltip
            title={`Switch to ${
              theme.palette.mode === 'light' ? 'dark' : 'light'
            } mode`}
          >
            {theme.palette.mode === 'light' ? (
              <DarkModeIcon
                onClick={handleThemeChange}
                sx={{ cursor: 'pointer' }}
              />
            ) : (
              <LightModeIcon
                onClick={handleThemeChange}
                sx={{ cursor: 'pointer' }}
              />
            )}
          </Tooltip>
          <Box
            sx={{
              width: '1px',
              backgroundColor: 'rgba(0,0,0,0.1)',
              height: '25px',
              ml: 1,
              mr: 1,
            }}
          />
          <Button
            startIcon={<QuestionMark />}
            variant="text"
            sx={{
              color: '#949db2',
            }}
            onClick={toggleHelpSideBar}
          >
            Help
          </Button>
          {/* ------------------------------------------- */}
          {/* Profile Dropdown */}
          {/* ------------------------------------------- */}
          {!session.email && (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress size={30} />
            </Box>
          )}
          {session.email && (
            <Button
              aria-label="menu"
              color="inherit"
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={handleClick4}
            >
              <Person sx={{ mr: '5px' }} />
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  display: {
                    sm: 'flex',
                    xs: 'none',
                  },
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5" fontWeight="700">
                  Hi,{' '}
                  {session.email.includes('helpdesk') && !session.name
                    ? 'Helpdesk'
                    : session.name}
                </Typography>
                <KeyboardArrowDown sx={{ width: '20px', height: '20px' }} />
              </Box>
            </Button>
          )}
          <Menu
            id="profile-menu"
            anchorEl={anchorEl4}
            keepMounted
            open={Boolean(anchorEl4)}
            onClose={handleClose4}
            sx={{
              '& .MuiMenu-paper': {
                width: '385px',
                right: 0,
                top: '70px !important',
              },
              '& .MuiList-padding': {
                pt: '20px',
                pb: '20px',
                pl: '30px',
                pr: '30px',
              },
            }}
          >
            <ProfileDropdown onClose={handleClose4} session={session} />
            <Link href="/auth/login">
              <Button
                sx={{
                  display: 'block',
                  width: '100%',
                }}
                variant="contained"
                color="primary"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Link>
          </Menu>
        </Toolbar>
      </AppBar>

      <HelpItems
        isOpen={helpSideBarOpen}
        onClose={toggleHelpSideBar}
        setFeedbackFormOpen={setFeedbackFormOpen}
      />

      <FeedbackForm
        isOpen={feedbackFormOpen}
        onClose={() => setFeedbackFormOpen(false)}
      />
    </>
  )
}

Header.propTypes = {
  sx: PropTypes.object,
  customClass: PropTypes.string,
  toggleSidebar: PropTypes.func,
  toggleMobileSidebar: PropTypes.func,
}

export default Header
