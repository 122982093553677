import {
  Box,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Papa from 'papaparse'
import { saveAs } from 'file-saver'
import {
  deviceApi,
  locationApi,
  memberApi,
  membershipApi,
  priceApi,
  promoApi,
  sizeApi,
} from 'resources'
import { Form, IDialogType } from 'components'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { IPrice } from 'models'
import useLoadingState from 'hooks/useLoadingState'
import { Help } from 'components/Help'
import { getUserRole } from 'utils/helpers'
import useHardwareTypes from 'hooks/useHardwareTypes'
import { productApi } from 'resources/product'
import { reservationApi } from 'resources/reservations'

interface IBulkUploadEntityProps {
  entity:
    | 'pay-per'
    | 'subscriptions'
    | 'promos'
    | 'locations'
    | 'devices'
    | 'sizes'
    | 'members'
    | 'products'
    | 'reservations'
  successForm: () => void
  onClose: () => void
  displayMessage?: (message: string | JSX.Element, type?: IDialogType) => void
}

const BulkUploadEntity = ({
  entity,
  successForm,
  onClose,
  displayMessage,
}: IBulkUploadEntityProps) => {
  const role = getUserRole()

  const { uploadCSV: uploadPayPerCSV } = priceApi()
  const { uploadCSV: uploadMembershipsCSV } = membershipApi()
  const { uploadCSV: uploadPromosCSV } = promoApi()
  const { uploadCSV: uploadLocationsCSV } = locationApi()
  const { uploadCSV: uploadDevicesCSV } = deviceApi()
  const { uploadCSV: uploadSizesCSV } = sizeApi()
  const { uploadCSV: uploadMembersCSV } = memberApi()
  const { uploadCSV: uploadProductsCSV } = productApi()
  const { uploadCSV: uploadReservationsCSV } = reservationApi()

  const methods = useForm<IPrice>({})

  const [hardwareTypeSelect, setHardwareTypeSelect] = useState<string>('linka')
  const [csvData, setCsvData] = useState<any[]>([])
  const [csvFile, setCsvFile] = useState<any>(null)

  const [isOjmarOrg] = useState(() => {
    const currentOrg = JSON.parse(localStorage.getItem('currentOrg') || '{}')
    return currentOrg && currentOrg.name === 'ojmar'
  })

  const { orgHardware } = useHardwareTypes()

  const { loading, setLoading } = useLoadingState()

  const theme = useTheme()

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    file ? handleFileUpload({ target: { files: [file] } }) : null
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0]

    if (file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target && typeof e.target.result === 'string') {
          const text = e.target.result
          parseCSV(text)
          setCsvFile(file)
        }
      }
      reader.readAsText(file)
    }
  }

  const handleHardwareTypeChange = (event) => {
    setHardwareTypeSelect(event.target.value)
  }

  // Just in case these values are needed in the future
  const parseCSV = (csvText: string) => {
    const rows = csvText.split('\n')
    const headerRow = rows.shift()?.split(',')
    const file_list: any[] = []

    if (!headerRow) return

    rows.map((row) => {
      const rowData = row.split(',')
      const obj: { [key: string]: string } = {}
      headerRow.forEach((key, index) => {
        const cleanKey = key.replace(/"/g, '').trim()
        const cleanValue = rowData[index]?.replace(/"/g, '').trim()
        if (cleanValue) {
          obj[cleanKey] = cleanValue
        }
      })
      file_list.push(obj)
    })

    setCsvData(file_list)
  }

  const createCsvBlob = (csvString) => {
    const csvData = Papa.unparse(Papa.parse(csvString).data)
    const blob = new Blob([csvData], { type: 'text/csv' })
    return blob
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      let response

      switch (entity) {
        case 'pay-per':
          response = await uploadPayPerCSV(csvFile)
          break

        case 'subscriptions':
          response = await uploadMembershipsCSV(csvFile)
          break

        case 'promos':
          response = await uploadPromosCSV(csvFile)
          break

        case 'locations':
          response = await uploadLocationsCSV(csvFile)
          break

        case 'devices':
          response = await uploadDevicesCSV(csvFile)
          break

        case 'sizes':
          response = await uploadSizesCSV(csvFile)
          break

        case 'members':
          response = await uploadMembersCSV(csvFile)
          break

        case 'products':
          response = await uploadProductsCSV(csvFile)
          break

        case 'reservations':
          response = await uploadReservationsCSV(csvFile)
          break

        default:
          displayMessage?.(
            'Error uploading CSV: This entity is not supported yet.',
            'error',
          )
          return
      }
      const successRows = response.filter((r) => r.detail === true).length
      const errorRows = response.filter((r) => r.detail !== true).length
      displayMessage?.(
        <>
          {successRows > 0 && errorRows > 0 && (
            <>
              <Typography>
                {successRows} row{successRows === 1 ? '' : 's'} was successfully
                added.
              </Typography>
              <Typography>
                {errorRows} row{errorRows === 1 ? '' : 's'} has failed.
              </Typography>{' '}
              <br />
              {response
                .filter((r) => r.detail !== true)
                .map((r, index) => (
                  <Typography key={index}>
                    <strong>Error ocurred on row {index + 1}:</strong>{' '}
                    {r.detail}
                  </Typography>
                ))}
            </>
          )}
          {successRows > 0 && errorRows === 0 && (
            <Typography>Upload was successful!</Typography>
          )}
          {errorRows > 0 && successRows === 0 && (
            <>
              <Typography>Upload was unsuccessful!</Typography>
              <br />
              {response
                .filter((r) => r.detail !== true)
                .map((r, index) => (
                  <Typography textAlign="left" key={index}>
                    <strong>Error ocurred on row {index + 1}:</strong>{' '}
                    {r.detail}
                  </Typography>
                ))}
            </>
          )}
        </>,
        successRows === 0 ? 'error' : 'success',
      )
      successForm()
      onClose()
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const downloadCSVTemplate = () => {
    let csvString: string | unknown = ''

    switch (entity) {
      case 'pay-per':
        csvString =
          'name,amount,currency,prorated,card_on_file,unit,unit_amount,price_type\n' +
          'Test Price CSV,1.0,usd,yes,yes,hour,2,pay_per_time'
        break
      case 'subscriptions':
        csvString =
          'name,description,active,currency,amount,billing_type,billing_period,number_of_payments,membership_type,value\n' +
          'Test Membership CSV, Some description,yes,usd,12.0,recurring,month,0,percentage,10.0'
        break

      case 'promos':
        csvString =
          'name,code,amount,discount_type,start_time,end_time\n' +
          'Test Promo CSV,CVSCVS,25,percentage,2023-10-02T13:06:00.000Z,2023-11-15T13:06:00.000Z'
        break

      case 'locations':
        csvString =
          'name,address,custom_id,hidden,latitude,longitude,contact_email,contact_phone\n' +
          'Test Location CSV,someplace,12345,1,23.13,23.14,,,'
        break

      case 'devices':
        const deviceTemplates = [
          {
            name: 'linka',
            tpl:
              'name,product,location,price,size,' +
              'locker_number,mode,status,mac_address,hardware_type,' +
              'price_required,group\n' +
              `CSV Linka 1,,,,,,rental,available,00:00:00:00:00,linka,false,Sales\n` +
              `CSV Linka 2,,,,,,rental,available,00:00:00:00:00,linka,false,Sales\n` +
              `CSV Linka 3,,,,,,rental,available,00:00:00:00:00,linka,false,Sales\n` +
              `CSV Linka 4,,,,,,rental,available,00:00:00:00:00,linka,false,Sales`,
          },
          {
            name: 'spintly',
            tpl:
              'name,product,location,price,size,' +
              'locker_number,mode,status,integration_id,hardware_type,' +
              'price_required,group\n' +
              'CSV Spintly 1,,,,,,rental,available,1111,spintly,false,Sales\n' +
              'CSV Spintly 2,,,,,,rental,available,2222,spintly,false,Sales\n' +
              'CSV Spintly 3,,,,,,rental,available,3333,spintly,false,Sales\n' +
              'CSV Spintly 4,,,,,,rental,available,4444,spintly,false,Sales',
          },
          {
            name: 'ojmar',
            tpl:
              'name,product,location,price,size,' +
              'locker_number,mode,status,hardware_type,locker_udn,user_code,master_code,' +
              'price_required,group\n' +
              'CSV Ojmar 1,,,,,,rental,available,ojmar,locker_udn,1111,100001,false,Sales\n' +
              'CSV Ojmar 2,,,,,,rental,available,ojmar,locker_udn,2222,100002,false,Sales\n' +
              'CSV Ojmar 3,,,,,,rental,available,ojmar,locker_udn,3333,100003,false,Sales\n' +
              'CSV Ojmar 4,,,,,,rental,available,ojmar,locker_udn,4444,100004,false,Sales',
          },
          {
            name: 'gantner',
            tpl:
              'name,product,location,price,size,' +
              'locker_number,mode,status,gantner_id,hardware_type,' +
              'price_required,group\n' +
              'CSV Gantner 1,,,,,,rental,available,01101689-2248070013-20,gantner,false,Sales\n' +
              'CSV Gantner 2,,,,,,rental,available,01101689-2248070013-21,gantner,false,Sales\n' +
              'CSV Gantner 3,,,,,,rental,available,01101689-2248070013-22,gantner,false,Sales\n' +
              'CSV Gantner 4,,,,,,rental,available,01101689-2248070013-23,gantner,false,Sales',
          },
          {
            name: 'harbor',
            tpl:
              'name,product,location,price,size,' +
              'locker_number,mode,status,harbor_tower_id,harbor_locker_id,hardware_type,' +
              'price_required,group\n' +
              'CSV Harbor 1,,,,,,rental,available,0100000000000014,01,harbor,false,Sales\n' +
              'CSV Harbor 2,,,,,,rental,available,0100000000000014,02,harbor,false,Sales\n' +
              'CSV Harbor 3,,,,,,rental,available,0100000000000014,03,harbor,false,Sales\n' +
              'CSV Harbor 4,,,,,,rental,available,0100000000000014,04,harbor,false,Sales',
          },
          {
            name: 'dclock',
            tpl:
              'name,product,location,price,size,' +
              'locker_number,mode,status,dclock_terminal_no,dclock_box_no,hardware_type,' +
              'price_required,group\n' +
              'CSV DC Lock 1,,,,,,rental,available,DC00000000000,12,dclock,false,Sales\n' +
              'CSV DC Lock 2,,,,,,rental,available,DC00000000000,13,dclock,false,Sales\n' +
              'CSV DC Lock 3,,,,,,rental,available,DC00000000000,14,dclock,false,Sales\n' +
              'CSV DC Lock 4,,,,,,rental,available,DC00000000000,15,dclock,false,Sales',
          },
        ]

        csvString = deviceTemplates.find(
          (tpl) => tpl.name === hardwareTypeSelect,
        )?.tpl

        break

      case 'sizes':
        csvString =
          'name,external_id,width,depth,height\n' +
          'Test Size CSV,SS,1.20,123,1.00'
        break

      case 'members':
        csvString =
          'name,first_name,last_name,email,enabled,role\n' +
          'Cool Guy,Cool,Guy,test@koloni.me,yes,admin'
        break

      case 'products':
        csvString =
          'name,description,price,sales_price,sku,msrp,serial_number,condition\n' +
          'Test Product CSV,Test desc,1.20,123,1.00,123,123,new'
        break

      case 'reservations':
        csvString =
          'tracking_number,user_name,email,phone_number,location,size\n' +
          'ABC123,John Doe,test@koloni.me,50683260526,Some Location,Test'
        break

      default:
        displayMessage?.(
          'Error downloading CSV: This entity is not supported yet.',
          'error',
        )
        return
    }

    const csvBlob = createCsvBlob(csvString)

    saveAs(csvBlob, `${entity.replace('-', '_')}_template.csv`)
  }

  useEffect(() => {
    if (isOjmarOrg) {
      setHardwareTypeSelect('ojmar')
    }
  }, [])

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit}>
          <FormWrapper>
            {entity === 'devices' && (
              <FormControl fullWidth>
                <InputLabel id="hardware-type-label">Hardware Type</InputLabel>
                <Select
                  labelId="hardware-type-label"
                  id="hardware-type-select-template"
                  value={hardwareTypeSelect}
                  label="Hardware Type"
                  onChange={handleHardwareTypeChange}
                  inputProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          backgroundImage: 'none',
                          boxShadow: 3,
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? '#2A2E34'
                              : '#f7f7f7',
                        },
                      },
                    },
                  }}
                >
                  {/* 
                  Short-hand conditionals such as "isOjmarOrg ? () : ()"
                  do not seem to trigger the onChange prop in MUI <Select /> component.
                  */}
                  {isOjmarOrg && <MenuItem value="ojmar">Ojmar</MenuItem>}

                  {!isOjmarOrg &&
                    orgHardware.map((hw, index) => (
                      <MenuItem key={index} value={hw.value}>
                        {hw.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <Help helpText="CSV files allow bulk upload. You can see items in a list view. Upload your CSV file here to see your data in the table">
              <Link
                sx={{
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                }}
                onClick={downloadCSVTemplate}
              >
                Download CSV Template
              </Link>
            </Help>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{
                  border: '2px dashed #aaa',
                  borderRadius: '4px',
                  padding: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  position: 'relative',
                  width: '100%',
                }}
              >
                {csvData.length === 0
                  ? 'Drag and drop a CSV file here or click to upload.'
                  : `${csvData.length} row(s) successfully imported. Click the "Submit" button to upload the imported prices.`}
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleFileUpload}
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    cursor: 'pointer',
                  }}
                />
              </div>
            </Box>
            <FormActions onClose={onClose} />
          </FormWrapper>
        </Form>
      </FormProvider>
    </>
  )
}

export default BulkUploadEntity
