import React, { SyntheticEvent, useState } from 'react'
import { Close, MoneyOff, QrCode } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { IDialogType, PDFGenerator, Tabs } from 'components'
import { ITransaction, TransactionStatus, TransactionType } from 'models'
import { FormProvider, useForm } from 'react-hook-form'
import { TextField } from 'components/Form'
import moment from 'moment'
import { ServicePDF } from 'templates'
import RefundDialog from './RefundDialog'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { RootStateOrAny, useSelector } from 'react-redux'
import { parseCurrency } from 'utils/helpers'
import EndDialog from './EndDialog'
import TransactionHistory from './TransactionHistory'
import ChargeDialog from './ChargeDialog'
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import PenaltyDialog from './PenaltyDialog'

interface ITransactionFormProps {
  transaction: ITransaction
  displayMessage: (message: string, type?: IDialogType) => void
  success: () => void
}

const TransactionForm = ({
  transaction,
  success,
  displayMessage,
}: ITransactionFormProps): React.ReactElement => {
  const [openRefundDialog, setOpenRefundDialog] = useState<boolean>(false)
  const [openEndDialog, setOpenEndDialog] = useState<boolean>(false)
  const [openChargeDialog, setOpenChargeDialog] = useState<boolean>(false)
  const [openPenaltyDialog, setOpenPenaltyDialog] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<string>('form')

  const orgSettings = JSON.parse(localStorage.getItem('settings') || '{}')

  const currency = useSelector(
    (state: RootStateOrAny) => state.currencyReducer.currency,
  )

  // Removed type due to complexity of design, which requests properties
  // from both IDevice and ITransaction. This is intended to be read only
  // either way:
  const methods = useForm({
    defaultValues: {
      ...transaction,
      event_status: TransactionStatus[transaction?.event_status],
      started_at: moment(transaction.started_at).format(
        orgSettings?.default_date_format || 'MM/DD/YYYY hh:mm A',
      ),
      ended_at: moment(transaction.ended_at).format(
        orgSettings?.default_date_format || 'MM/DD/YYYY hh:mm A',
      ),
      location_name: transaction.device?.location?.name,
      device_name: transaction.device?.name,
      total:
        parseCurrency(currency) +
        (transaction.total ? transaction.total.toString() : '0'),
      locker_number: transaction.device.locker_number,
      canceled_at: moment(transaction.canceled_at).format(
        orgSettings?.default_date_format || 'MM/DD/YYYY hh:mm A',
      ),
      refunded_amount:
        parseCurrency(currency) +
        (transaction.refunded_amount
          ? transaction.refunded_amount.toString()
          : '0'),
      penalize_charge:
        parseCurrency(currency) +
        (transaction.penalize_charge
          ? transaction.penalize_charge.toString()
          : '0'),
    },
  })

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }

  const TransactionForm = (
    <>
      <FormProvider {...methods}>
        <FormWrapper title="Transaction Details">
          <TextField name="invoice_id" label="Order number" isReadOnly={true} />
          <TextField name="device_name" label="Device" isReadOnly={true} />
          <TextField name="location_name" label="Location" isReadOnly={true} />
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <TextField
              name="locker_number"
              label="Locker Number"
              isReadOnly={true}
            />
            <TextField name="event_type" label="Mode" isReadOnly={true} />
          </Box>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <TextField name="started_at" label="Start date" isReadOnly={true} />
            <TextField name="ended_at" label="End date" isReadOnly={true} />
          </Box>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <TextField name="total_time" label="Duration" isReadOnly={true} />
            <TextField name="event_status" label="Status" isReadOnly={true} />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <TextField
              name="total"
              label="Amount"
              isReadOnly={true}
              helperText={`${
                transaction.promo && transaction.membership
                  ? 'Promo and membership applied'
                  : transaction.promo
                  ? 'Promo applied'
                  : transaction.membership
                  ? 'Membership applied'
                  : ''
              }`}
            />
            <TextField
              name="refunded_amount"
              label="Refund Amount"
              isReadOnly={true}
            />
          </Box>

          {[
            TransactionStatus.finished,
            TransactionStatus.canceled,
            TransactionStatus.refunded,
            TransactionStatus.expired,
          ].includes(TransactionStatus[transaction.event_status]) && (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <TextField
                name="canceled_at"
                label="Canceled on"
                isReadOnly={true}
              />

              <TextField
                name="canceled_by"
                label="Canceled by"
                isReadOnly={true}
              />
            </Box>
          )}

          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <TextField name="promo" label="Promo code" isReadOnly={true} />
            <TextField
              name="membership"
              label="Membership name"
              isReadOnly={true}
            />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <TextField
              name="penalize_charge"
              label="Penalty Amount"
              isReadOnly={true}
            />
            <TextField
              name="penalize_reason"
              label="Reason for Penalty"
              isReadOnly={true}
            />
          </Box>

          {transaction.courier_pin_code && (
            <>
              <Typography variant="h5">Delivery Details</Typography>
              <TextField
                name="courier_pin_code"
                label="Courier pin code"
                isReadOnly={true}
              />
            </>
          )}

          <Box
            sx={{
              marginTop: '1rem',
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
              gap: '1.5rem',
            }}
          >
            {TransactionStatus[transaction.event_status] ===
              TransactionStatus.finished && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography>Refund</Typography>
                <IconButton
                  size="small"
                  onClick={() => setOpenRefundDialog(true)}
                >
                  <MoneyOff />
                </IconButton>
              </Box>
            )}
            {TransactionStatus[transaction.event_status] !==
              TransactionStatus.finished &&
              TransactionStatus[transaction.event_status] !==
                TransactionStatus.canceled && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography>End</Typography>
                  <IconButton
                    size="small"
                    onClick={() => setOpenEndDialog(true)}
                  >
                    <Close />
                  </IconButton>
                </Box>
              )}
            {TransactionStatus[transaction.event_status] ===
              TransactionStatus.in_progress &&
              TransactionType[transaction.event_type] ===
                TransactionType.service && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography>Charge</Typography>
                  <IconButton
                    size="small"
                    onClick={() => setOpenChargeDialog(true)}
                  >
                    <AddCardOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            {[
              TransactionStatus.finished,
              TransactionStatus.canceled,
              TransactionStatus.refunded,
            ].includes(TransactionStatus[transaction.event_status]) && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography>Charge Penalty</Typography>
                <IconButton
                  size="small"
                  onClick={() => setOpenPenaltyDialog(true)}
                >
                  <CreditCardOutlinedIcon />
                </IconButton>
              </Box>
            )}
            {TransactionStatus[transaction.event_status] !==
              TransactionStatus.finished &&
              TransactionStatus[transaction.event_status] !==
                TransactionStatus.canceled && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography>QR code</Typography>
                  <IconButton size="small">
                    <PDFGenerator
                      fileName={transaction?.id}
                      pageContent={<ServicePDF transaction={transaction} />}
                      pageSize={'A6'}
                      icon={
                        <QrCode
                          sx={{
                            color: (theme) =>
                              theme.palette.mode === 'dark'
                                ? '#ffffff'
                                : '#0000008a',
                          }}
                        />
                      }
                    />
                  </IconButton>
                </Box>
              )}
            {transaction.signature_url && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6">Signature</Typography>
                <Box
                  sx={{
                    marginTop: '10px',
                  }}
                >
                  <img src={transaction.signature_url} width="90" />
                </Box>
              </Box>
            )}
          </Box>
        </FormWrapper>
      </FormProvider>
      <RefundDialog
        open={openRefundDialog}
        onClose={() => setOpenRefundDialog(false)}
        transaction={transaction}
        displayMessage={displayMessage}
        success={success}
      />
      <EndDialog
        open={openEndDialog}
        onClose={() => setOpenRefundDialog(false)}
        transaction={transaction}
        displayMessage={displayMessage}
        success={success}
      />
      <ChargeDialog
        open={openChargeDialog}
        onClose={() => setOpenChargeDialog(false)}
        transaction={transaction}
        displayMessage={displayMessage}
        success={success}
      />
      <PenaltyDialog
        open={openPenaltyDialog}
        onClose={() => setOpenPenaltyDialog(false)}
        transaction={transaction}
        displayMessage={displayMessage}
        success={success}
      />
    </>
  )

  return (
    <Tabs
      tabs={[
        {
          label: 'Transaction Details',
          value: 'form',
          children: TransactionForm,
        },
        {
          label: 'Transaction History Log',
          value: 'history',
          children: <TransactionHistory transaction={transaction} />,
        },
      ]}
      currentTab={currentTab}
      handleChange={handleTabChange}
    />
  )
}

export default TransactionForm
