import { ITableProps } from 'components'
import { Table as MUITable, TableContainer, Card } from '@mui/material'

const Table = ({ children }: ITableProps) => {
  return (
    <Card
      sx={{
        borderRadius: '5px',
        minHeight: '600px',
      }}
    >
      <TableContainer sx={{ maxHeight: 800 }}>
        <MUITable stickyHeader>{children}</MUITable>
      </TableContainer>
    </Card>
  )
}
export default Table
