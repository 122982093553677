import { ChangeEvent, useState, useEffect, useMemo } from 'react'
import {
  Table,
  TableBody,
  TablePagination,
  NotificationDialog,
  Button,
  SideBar,
} from 'components'
import IssueRow from './components/IssueRow'
import { IIssue, IIssueList, IMember, IUser } from 'models'
import { IssueApi, memberApi, settingsApi, userApi } from 'resources'
import { DEFAULT_PAGE, ITEMS_PER_PAGE } from 'constants/pagination'
import useDialog from 'hooks/useDialog'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import IssueForm from './components/IssueForm'
import useSortableHeader from 'hooks/useSortableHeader'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import useColumnFiltering from 'hooks/useColumnFiltering'
import NoData from 'components/PageBase/NoData'
import LoadingData from 'components/PageBase/LoadingData'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import { ToolbarControl } from 'types'
import useLoadingState from 'hooks/useLoadingState'

const Issues = (): React.ReactElement => {
  const [issues, setIssues] = useState<IIssueList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [issuesBackup, setIssuesBackup] = useState<IIssue[]>([])
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)
  const [currentIssue, setCurrentIssue] = useState<IIssue | undefined>()
  const [users, setUsers] = useState<IUser[]>([])
  const [teamMembers, setTeamMembers] = useState<IMember[]>([])

  const { getMany, deleteMany } = IssueApi()
  const { getMany: getUsers } = userApi()
  const { getMany: getTeamMembers } = memberApi()

  const { dialog, displayMessage, closeDialog } = useDialog()
  const { filteredColumns, setFilteredColumns, getDefaultFilters } =
    useColumnFiltering({ displayMessage })
  const { loading, setLoading } = useLoadingState(true)

  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'name',
      entity: 'issues',
      nestedProps: [
        {
          columnValue: 'issueId',
          path: 'issue_id',
          defaultValue: '',
        },
        {
          columnValue: 'userName',
          path: 'user.name',
          defaultValue: '',
        },
        {
          columnValue: 'orderNumber',
          path: 'event.invoice_id',
          defaultValue: '',
        },
        {
          columnValue: 'reportTime',
          path: 'created_at',
          defaultValue: '',
        },
      ],
    })

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => {
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, id]
        : previousValue.filter((rowId) => rowId !== id),
    )
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() =>
      checked ? issues.items.map((device) => device.id) : [],
    )
  }

  const handleDeleteMany = async () => {
    try {
      await deleteMany(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      setSelectedRows([])
      fetchIssues()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }

  const fetchUsers = async (): Promise<void> => {
    try {
      const users = await getUsers(1, 10000, '')
      setUsers(users.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const fetchTeamMembers = async (): Promise<void> => {
    try {
      const teamMembers = await getTeamMembers(1, 150, '')
      setTeamMembers(teamMembers.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const fetchIssues = async (
    showNewestFirst: boolean = false,
  ): Promise<void> => {
    try {
      setLoading(true)
      const issues = await getMany(currentPage + 1, rowsPerPage, search)
      setIssuesBackup([...issues.items])
      setIssues(issues)
      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleEditIssue = (issue: IIssue) => {
    setCurrentIssue(issue)
    setOpenSidebar(true)
  }

  useEffect(() => {
    getDefaultFilters()
    fetchIssues()
    fetchUsers()
    fetchTeamMembers()
  }, [])

  useEffect(() => {
    fetchIssues()
  }, [search, currentPage, rowsPerPage])

  const visibleRows = useMemo(
    () => getVisibleRowsSorted(issues.items, issuesBackup),
    [issues, order, orderBy],
  )

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button
          disabled={loading}
          variant="contained"
          name="addSize"
          onClick={() => setOpenSidebar(true)}
        >
          Add Issue
        </Button>
      ),
    },
  ]

  return (
    <>
      <Toolbar controls={controls.filter((control) => control.display)}>
        <ToolbarControls
          controls={controls.filter((control) => control.display)}
        />
        <ToolbarSearchbar
          handleSearch={handleSearch}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
        />
      </Toolbar>
      {selectedRows.length > 0 && (
        <MultiSelectToolbar
          itemsSelected={selectedRows.length}
          handleAction={handleDeleteMany}
        />
      )}
      {!loading && issues.items.length > 0 && (
        <>
          <Table>
            <SortableTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={filteredColumns.filter((c) => c.active)}
              handleSelectAll={handleSelectAll}
            />
            <TableBody>
              {visibleRows.map((issue) => (
                <IssueRow
                  key={issue.id}
                  issue={issue}
                  success={() => {
                    fetchIssues()
                  }}
                  filteredColumns={filteredColumns}
                  displayMessage={displayMessage}
                  handleSelectRow={handleSelectRow}
                  selected={selectedRows.includes(issue.id)}
                  handleEditIssue={handleEditIssue}
                />
              ))}
            </TableBody>
          </Table>

          <TablePagination
            totalItems={issues.total}
            currentPage={currentPage}
            itemsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setRowsPerPage}
          />
        </>
      )}

      <LoadingData isLoading={loading} />

      <NoData condition={!loading && issues.items.length === 0} />

      <SideBar
        open={openSidebar}
        onClose={() => {
          setCurrentIssue(undefined)
          setOpenSidebar(false)
        }}
      >
        <IssueForm
          issue={currentIssue}
          success={fetchIssues}
          onClose={() => {
            setCurrentIssue(undefined)
            setOpenSidebar(false)
          }}
          displayMessage={displayMessage}
          users={users}
          teamMembers={teamMembers}
        />{' '}
      </SideBar>

      <NotificationDialog
        open={dialog.isOpen}
        onClose={closeDialog}
        message={dialog.message}
        type={dialog.type}
      />
    </>
  )
}

export default Issues
