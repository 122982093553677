import { SelectChangeEvent } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { locationApi, organizationApi, reportApi } from 'resources'
import { IMetricCard } from './components/MetricCard'
import SensorDoorIcon from '@mui/icons-material/SensorDoor'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import PieChartIcon from '@mui/icons-material/PieChart'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import PeopleIcon from '@mui/icons-material/People'
import PlaceIcon from '@mui/icons-material/Place'
import MoneyIcon from '@mui/icons-material/Money'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import {
  IAnalyticsFilters,
  IAverageRevenuePerTransaction,
  IDoorHealth,
  IEarnings,
  INewUserGrowth,
  IOccupancyRate,
  IReportedIssueRate,
  ITotalDoors,
  ITotalLocations,
  ITotalTransactions,
  ITransactionRate,
  ITransactionsPerLocker,
} from 'models/Analytics'
import useDialog from 'hooks/useDialog'
import CustomDateRange from './components/CustomDateRange'
import { ILocation, IOrganization } from 'models'
import {
  parseAverageRevenuePerTransactionValue,
  parseDoorHealthValue,
  parseEarningsValue,
  parseNewTransactionsValue,
  parseNewUserGrowthValue,
  parseOccupancyRateValue,
  parseOptionToDateRange,
  parseOptionToInterval,
  parseReportedIssuesRateValue,
  parseTotalDoorsValue,
  parseTotalLocationsValue,
  parseTotalTransactionsValue,
  parseTotalUsersValue,
  parseTransactionPerLockerValue,
  parseTransactionTimeAverageValue,
} from './helper'
import DashboardFilters from './components/DashboardFilters'
import DashboardContent from './components/DashboardContent'
import { NotificationDialog } from 'components'

const defaultFilters: IAnalyticsFilters = {
  from: null,
  to: null,
}

const Dashboard = () => {
  const [visibleMetrics, setVisibleMetrics] = useState<IMetricCard[]>(() => {
    const array: IMetricCard[] = []
    for (let i = 0; i < 5; i++) {
      array.push({
        metric: null,
        id: i,
        buildMetricObject: async () => {},
        metricsOptions: [],
        loading: false,
        setCurrentFetchingMetric: () => {},
      })
    }
    return array
  })
  const [subOrgs, setSubOrgs] = useState<any[]>([])
  const [filters, setFilters] = useState<IAnalyticsFilters>(defaultFilters)
  const [loading, setLoading] = useState<boolean>(false)
  const [openCustomDateRangeSelector, setOpenCustomDateRangeSelector] =
    useState<boolean>(false)
  const [selectedRange, setSelectedRange] = useState<string>('this_week')
  const [selectedSubOrg, setSelectedSubOrg] = useState<string | undefined>()
  const [currentFetchingMetric, setCurrentFetchingMetric] = useState<string>('')
  const [locations, setLocations] = useState<ILocation[]>([])
  const [selectedLocation, setSelectedLocation] = useState<
    ILocation | undefined
  >()
  const [myOrg, setMyOrg] = useState<IOrganization | null>(null)
  const { getMany } = locationApi()
  const { dialog, displayMessage, closeDialog } = useDialog()
  const {
    getEarnings,
    getUserGrowth,
    getDoorHealth,
    getIssueRate,
    getOccupancyRate,
    getNewTransactionRate,
    getTransactionsPerLocker,
    getTotalTransactions,
    getTransactionTimeAverage,
    getTotalUsers,
    getTotalLocations,
    getAverageRevenuePerTransaction,
    getTotalDoors,
  } = reportApi()

  const { getOrgs, getSelfOrg } = organizationApi()

  const metricsData = [
    {
      icon: <MoneyIcon fontSize="medium" />,
      title: 'Earnings',
      legend: 'Revenue',
      accesor: 'earnings',
      parseFunction: (response: any) =>
        parseEarningsValue(response as IEarnings),
      fetchFunction: async () => {
        try {
          return getEarnings(selectedSubOrg)
        } catch (error) {
          displayMessage(`${(error as Error).message}`, 'error')
        }
      },
    },
    {
      icon: <PersonAddIcon fontSize="medium" />,
      title: 'New User Growth',
      accesor: 'newUserGrowth',
      parseFunction: (response: any) =>
        parseNewUserGrowthValue(response as INewUserGrowth),
      fetchFunction: async () => {
        try {
          return getUserGrowth(
            parseOptionToInterval(selectedRange),
            selectedSubOrg,
          )
        } catch (error) {
          displayMessage(`${(error as Error).message}`, 'error')
        }
      },
    },
    {
      icon: <SensorDoorIcon fontSize="medium" />,
      title: 'Door Health',
      accesor: 'doorHealth',
      parseFunction: (response: any) =>
        parseDoorHealthValue(response as IDoorHealth[], selectedLocation),
      fetchFunction: async () => {
        try {
          return getDoorHealth(selectedSubOrg)
        } catch (error) {
          displayMessage(`${(error as Error).message}`, 'error')
        }
      },
    },
    {
      icon: <WarningAmberIcon fontSize="medium" />,
      title: 'Reported Issues Rate',
      accesor: 'reportedIssuesRate',
      parseFunction: (response: any) =>
        parseReportedIssuesRateValue(response as IReportedIssueRate),
      fetchFunction: async () => {
        try {
          return getIssueRate(
            filters.from || undefined,
            filters.to || undefined,
            selectedLocation ? [selectedLocation.id] : undefined,
            selectedSubOrg,
          )
        } catch (error) {
          displayMessage(`${(error as Error).message}`, 'error')
        }
      },
    },
    {
      icon: <PieChartIcon fontSize="medium" />,
      title: 'Occupancy Rate',
      accesor: 'occupancyRate',
      parseFunction: (response: any) =>
        parseOccupancyRateValue(response as IOccupancyRate[], selectedLocation),
      fetchFunction: async () => {
        try {
          return getOccupancyRate(
            filters.from || undefined,
            filters.to || undefined,
            selectedSubOrg,
          )
        } catch (error) {
          displayMessage(`${(error as Error).message}`, 'error')
        }
      },
    },
    {
      icon: <SyncAltIcon fontSize="medium" />,
      title: 'New Transactions',
      accesor: 'newTransaction',
      parseFunction: (response: any) =>
        parseNewTransactionsValue(response as ITransactionRate),
      fetchFunction: async () => {
        try {
          return getNewTransactionRate(
            filters.from || undefined,
            filters.to || undefined,
            selectedSubOrg,
          )
        } catch (error) {
          displayMessage(`${(error as Error).message}`, 'error')
        }
      },
    },
    {
      icon: <SyncAltIcon fontSize="medium" />,
      title: 'Transaction Per Locker',
      accesor: 'transactionsPerLocker',
      parseFunction: (response: any) =>
        parseTransactionPerLockerValue(response as ITransactionsPerLocker),
      fetchFunction: async () => {
        try {
          return getTransactionsPerLocker(
            selectedLocation ? selectedLocation.id : undefined,
            filters.from || undefined,
            filters.to || undefined,
            selectedSubOrg,
          )
        } catch (error) {
          displayMessage(`${(error as Error).message}`, 'error')
        }
      },
    },
    {
      icon: <SyncAltIcon fontSize="medium" />,
      title: 'Total Transactions',
      accesor: 'totalTransactions',
      parseFunction: (response: any) =>
        parseTotalTransactionsValue(response as ITotalTransactions),
      fetchFunction: async () => {
        try {
          return getTotalTransactions(
            selectedLocation ? selectedLocation.id : undefined,
            undefined,
            selectedSubOrg,
          )
        } catch (error) {
          displayMessage(`${(error as Error).message}`, 'error')
        }
      },
    },
    {
      icon: <AccessTimeIcon fontSize="medium" />,
      title: 'Average Transaction Time',
      accesor: 'transactionTimeAverage',
      parseFunction: (response: any) =>
        parseTransactionTimeAverageValue(response as number),
      fetchFunction: async () => {
        try {
          return getTransactionTimeAverage(
            filters.from || undefined,
            filters.to || undefined,
            selectedSubOrg,
          )
        } catch (error) {
          displayMessage(`${(error as Error).message}`, 'error')
        }
      },
    },
    {
      icon: <PeopleIcon fontSize="medium" />,
      title: 'Active users in transaction',
      accesor: 'totalUsers',
      parseFunction: (response: any) =>
        parseTotalUsersValue(response as number),
      fetchFunction: async () => {
        try {
          return getTotalUsers(selectedSubOrg)
        } catch (error) {
          displayMessage(`${(error as Error).message}`, 'error')
        }
      },
    },
    {
      icon: <PlaceIcon fontSize="medium" />,
      title: 'Total Locations',
      accesor: 'totalLocations',
      parseFunction: (response: any) =>
        parseTotalLocationsValue(response as ITotalLocations),
      fetchFunction: async () => {
        try {
          return getTotalLocations(selectedSubOrg)
        } catch (error) {
          displayMessage(`${(error as Error).message}`, 'error')
        }
      },
    },
    {
      icon: <SyncAltIcon fontSize="medium" />,
      title: 'Average Revenue Per Transaction',
      accesor: 'averageRevenuePerTransaction',
      parseFunction: (response: any) =>
        parseAverageRevenuePerTransactionValue(
          response as IAverageRevenuePerTransaction,
        ),
      fetchFunction: async () => {
        try {
          return getAverageRevenuePerTransaction(selectedSubOrg)
        } catch (error) {
          displayMessage(`${(error as Error).message}`, 'error')
        }
      },
    },

    {
      icon: <SensorDoorIcon fontSize="medium" />,
      title: 'Total Doors',
      accesor: 'totalDoors',
      parseFunction: (response: any) =>
        parseTotalDoorsValue(response as ITotalDoors, selectedLocation),
      fetchFunction: async () => {
        try {
          return getTotalDoors(
            selectedLocation ? selectedLocation.id : undefined,
            selectedSubOrg,
          )
        } catch (error) {
          displayMessage(`${(error as Error).message}`, 'error')
        }
      },
    },
  ]

  const metricOptions = useMemo(() => {
    return metricsData.map((metric) => ({
      value: metric.accesor,
      label: metric.title,
    }))
  }, [])

  const buildMetricObject = async (id: number, metric: string) => {
    try {
      setLoading(true)

      const visibleMetricsClone = [...visibleMetrics]

      const metricData = metricsData.find((m) => m.accesor === metric)

      if (metricData) {
        const response = await metricData.fetchFunction()

        visibleMetricsClone[id] = {
          ...visibleMetricsClone[id],
          metric: {
            metric: metricData.accesor,
            value: metricData.parseFunction(response),
            title: metricData.title,
            icon: metricData.icon,
            legend: metricData.legend,
          },
        }
      }

      setVisibleMetrics(visibleMetricsClone)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setCurrentFetchingMetric('')
      setLoading(false)
    }
  }

  const refreshMetrics = () => {
    visibleMetrics.forEach(async (metric) => {
      if (metric.metric) {
        await buildMetricObject(metric.id, metric.metric.metric)
      }
    })
  }

  const fetchLocations = async (): Promise<void> => {
    try {
      const locations = await getMany(1, 10000)
      setLocations(locations.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const fetchSubOrgs = async (): Promise<void> => {
    try {
      const subOrgs = await getOrgs(1, 1000)
      setSubOrgs(subOrgs.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleCloseCustomDateDialog = () => {
    setOpenCustomDateRangeSelector(false)
  }

  const handleSelectDateRange = (event: SelectChangeEvent): void => {
    setSelectedRange(event.target.value)
  }

  const fetchSelfOrg = async () => {
    try {
      const selfOrg = await getSelfOrg()
      setMyOrg(selfOrg)
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`)
    }
  }

  useEffect(() => {
    setFilters(defaultFilters)
    if (selectedRange !== 'custom') {
      setFilters({
        from: parseOptionToDateRange(selectedRange),
        to: new Date(),
      })
    }
  }, [selectedRange])

  useEffect(() => {
    refreshMetrics()
  }, [selectedLocation, filters, selectedSubOrg])

  useEffect(() => {
    fetchLocations()
    fetchSelfOrg()
    fetchSubOrgs()
  }, [])

  return (
    <>
      <DashboardFilters
        selectedRange={selectedRange}
        setOpenCustomDateRangeSelector={setOpenCustomDateRangeSelector}
        handleSelectDateRange={handleSelectDateRange}
        locations={locations}
        subOrgs={subOrgs}
        selfOrg={myOrg}
        selectedSubOrg={selectedSubOrg}
        setSelectedLocation={setSelectedLocation}
        setSelectedSubOrg={setSelectedSubOrg}
      />
      <DashboardContent
        visibleMetrics={visibleMetrics}
        buildMetricObject={buildMetricObject}
        metricOptions={metricOptions}
        loading={loading}
        currentFetchingMetric={currentFetchingMetric}
        setCurrentFetchingMetric={setCurrentFetchingMetric}
        targetOrg={selectedSubOrg}
      />
      <CustomDateRange
        isOpen={openCustomDateRangeSelector}
        onClose={handleCloseCustomDateDialog}
        selectedRange={selectedRange}
        filters={filters}
        setFilters={setFilters}
      />
      <NotificationDialog
        open={dialog.isOpen}
        onClose={closeDialog}
        message={dialog.message}
        type={dialog.type}
      />
    </>
  )
}
export default Dashboard
