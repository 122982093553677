import { Link } from '@mui/material'
import { TableCell, TableRow } from 'components'
import { IUser } from 'models'
import { Dispatch, SetStateAction } from 'react'

interface IMembershipRowProps {
  membership: any
  filteredColumns: any[]
  openUserForm: () => void
  setCurrentUser: Dispatch<SetStateAction<IUser | undefined>>
  success: () => void
}

const MembershipRow = ({
  openUserForm,
  setCurrentUser,
  membership,
  filteredColumns,
  success,
}: IMembershipRowProps) => {
  return (
    <TableRow>
      {filteredColumns.findIndex((c) => c.value === 'name' && c.active) !==
        -1 && (
        <TableCell align="center">
          <Link
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setCurrentUser(membership.user)
              openUserForm()
            }}
          >
            {membership.user.name}
          </Link>
        </TableCell>
      )}
      {filteredColumns.findIndex((c) => c.value === 'price' && c.active) !==
        -1 && <TableCell align="center">{membership.details.price}</TableCell>}
      {filteredColumns.findIndex(
        (c) => c.value === 'expiration_date' && c.active,
      ) !== -1 && (
        <TableCell align="center">
          {membership.details.expiration_date === null ? (
            <>-</>
          ) : (
            <>{membership.details.expiration_date}</>
          )}
        </TableCell>
      )}

      {filteredColumns.findIndex((c) => c.value === 'recurring' && c.active) !==
        -1 && (
        <TableCell align="center">{membership.details.recurring}</TableCell>
      )}

      {filteredColumns.findIndex(
        (c) => c.value === 'membership_name' && c.active,
      ) !== -1 && (
        <TableCell align="center">{membership.details.name}</TableCell>
      )}

      {filteredColumns.findIndex(
        (c) => c.value === 'no_of_transactions' && c.active,
      ) !== -1 && (
        <TableCell align="center">
          {membership.details.no_of_transactions}
        </TableCell>
      )}
    </TableRow>
  )
}

export default MembershipRow
