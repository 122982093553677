import { useState, SyntheticEvent } from 'react'
import { ILocation } from 'models'
import { IDialogType, Tabs } from 'components'
import General from './GeneralTab'
import SettingsTab from './SettingsTab'
import { BulkUploadEntity } from 'components/BulkUploadEntity'
import { getUserRole } from 'utils/helpers'

interface ILocationFormProps {
  location: ILocation | undefined
  onClose: () => void
  success: (showNewestFirst?: boolean) => void
  allowEdit?: boolean
  displayMessage?: (message: string | JSX.Element, type?: IDialogType) => void
  creatingLocation?: boolean
}

const LocationForm = ({
  location,
  onClose,
  success,
  allowEdit = true,
  displayMessage,
  creatingLocation = false,
}: ILocationFormProps) => {
  const [currentTab, setCurrentTab] = useState<string>('general')
  const [currentLocation, setCurrentLocation] = useState<ILocation | undefined>(
    location ? location : undefined,
  )

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }

  return (
    <>
      {location && (
        <Tabs
          tabs={[
            {
              label: 'General',
              value: 'general',
              children: (
                <General
                  location={currentLocation}
                  onClose={onClose}
                  success={success}
                  allowEdit={allowEdit}
                  displayMessage={displayMessage}
                  setCurrentLocation={(location: ILocation) =>
                    setCurrentLocation(location)
                  }
                  goToDevicesTab={() => setCurrentTab('settings')}
                />
              ),
            },
            {
              label: 'Settings',
              value: 'settings',
              disabled: creatingLocation,
              children: (
                <SettingsTab
                  location={currentLocation}
                  onClose={onClose}
                  displayMessage={displayMessage}
                  success={success}
                />
              ),
            },
          ]}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}
      {getUserRole() !== 'admin' && !location && (
        <General
          location={currentLocation}
          onClose={onClose}
          success={success}
          allowEdit={allowEdit}
          displayMessage={displayMessage}
          setCurrentLocation={(location: ILocation) =>
            setCurrentLocation(location)
          }
          goToDevicesTab={() => setCurrentTab('settings')}
        />
      )}
      {getUserRole() === 'admin' && !location && (
        <Tabs
          tabs={[
            {
              label: 'General',
              value: 'general',
              children: (
                <General
                  location={currentLocation}
                  onClose={onClose}
                  success={success}
                  allowEdit={allowEdit}
                  displayMessage={displayMessage}
                  setCurrentLocation={(location: ILocation) =>
                    setCurrentLocation(location)
                  }
                  goToDevicesTab={() => setCurrentTab('settings')}
                />
              ),
            },
            {
              label: 'Bulk Upload',
              value: 'BulkUpload',
              children: (
                <BulkUploadEntity
                  entity="locations"
                  successForm={success}
                  onClose={onClose}
                />
              ),
            },
          ]}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}
    </>
  )
}

export default LocationForm
