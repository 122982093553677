import React, { useState, useEffect } from 'react'
import {
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { Button, NotificationDialog } from 'components'
import { DropdownOption } from 'types'
import { financialApi, organizationApi, settingsApi } from 'resources'
import { Help } from 'components/Help'
import useDialog from 'hooks/useDialog'
import { getOrganizationFromHost } from 'utils/helpers'
import { IStripeAccount } from 'models'
import useLoadingState from 'hooks/useLoadingState'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { setCurrency } from 'redux/currency/Action'
import { COUNTRY_OPTIONS, PRICE_CURRENCIES } from '../constants'

interface CountryFormProps {
  country: string
}

const FinancialSettings = (): React.ReactElement => {
  const [isStripeEnabled, setIsStripeEnabled] = useState<boolean>(false)
  const [account, setAccount] = useState<IStripeAccount | null>(null)
  const [defaultCountry, setDefaultCountry] = useState<string>('')

  const { createStripeAccount, getStripeAccount, deleteStripeAccount } =
    financialApi()
  const { getSettings, createSettings, updateSettings } = settingsApi()
  const { getOrgDetailsPublic } = organizationApi()
  const { dialog, displayMessage, closeDialog } = useDialog()
  const { loading, setLoading } = useLoadingState(true)

  const dispatch = useDispatch()

  const currency = useSelector(
    (state: RootStateOrAny) => state.currencyReducer.currency,
  )

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const methods = useForm<any>({
    defaultValues: {
      country: 'US',
      ...JSON.parse(localStorage.getItem('settings') || '{}'),
    },
  })

  const disconnectAccount = async (): Promise<void> => {
    try {
      setLoading(true)
      await deleteStripeAccount()
      setAccount(null)
      setIsStripeEnabled(false)
      displayMessage(
        'Your Stripe account has been deleted successfully!',
        'success',
      )
      setLoading(false)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const createAccount = async (): Promise<void> => {
    try {
      setLoading(true)
      const { stripeLink } = await createStripeAccount(methods.watch('country'))
      window.open(stripeLink, '_blank')
      getAccount()
      displayMessage(
        'Your Stripe account has been created successfully!',
        'success',
      )
      setLoading(false)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const completeAccount = async (): Promise<void> => {
    try {
      // can we get this link without creating, but with GET method ?
      const { stripeLink } = await createStripeAccount(methods.watch('country'))
      getAccount()
      window.open(stripeLink, '_blank')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const updateAccount = async (): Promise<void> => {
    try {
      const { stripeLink } = await createStripeAccount(methods.watch('country'))
      window.open(stripeLink, '_blank')
      getAccount()
      displayMessage(
        'Your Stripe account has been updated successfully!',
        'success',
      )
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const getAccount = async (): Promise<void> => {
    try {
      const response = await getStripeAccount()
      setIsStripeEnabled(true)
      setAccount(response)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const getSettingsFn = async () => {
    try {
      const response = await getSettings()
      localStorage.setItem('settings', JSON.stringify(response))
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleCurrencyChange = async (event: SelectChangeEvent<string>) => {
    try {
      const currency = event.target.value
      await updateSettings({ default_currency: currency })
      dispatch(setCurrency(currency))
      displayMessage('Currency has been updated!', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    setDefaultCountry(event.target.value)
  }

  useEffect(() => {
    const fetchStripeData = async () => {
      try {
        const orgName = getOrganizationFromHost()
        const { stripe_enabled } = await getOrgDetailsPublic(orgName)
        setIsStripeEnabled(stripe_enabled)
        if (stripe_enabled) {
          getAccount()
          getSettingsFn()
        }
        setLoading(false)
      } catch (error) {
        displayMessage(`${(error as Error).message}`, 'error')
      }
    }
    fetchStripeData()
  }, [])

  return (
    <>
      {/* <FormProvider {...methods}>
        <Form onSubmit={onSubmit}> */}
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: smDown ? 'center' : undefined,
            // alignItems: smDown ? 'center' : undefined,
            padding: '1rem 2rem',
            gap: '2rem',
          }}
        >
          {!loading && (
            <Help helpText="Capture revenue with an unified payments solution that eliminates the need for one-off merchant account, payment gateway and processor integrations">
              <Typography textAlign="left" variant="h3">
                Stripe
              </Typography>
            </Help>
          )}

          {!account && !isStripeEnabled && !loading && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: smDown ? 'center' : 'flex-start',
                flexDirection: smDown ? 'column' : 'row',
                gap: '1rem',
              }}
            >
              <FormControl
                sx={{ width: smDown ? '100%' : undefined }}
                size="small"
              >
                <InputLabel>Country</InputLabel>
                <Select
                  label="country"
                  placeholder="Select country"
                  onChange={handleCountryChange}
                  sx={{ width: !smDown ? '250px' : undefined }}
                >
                  {COUNTRY_OPTIONS.map((country) => (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                sx={{ width: smDown ? '100%' : undefined }}
                variant="contained"
                color="primary"
                onClick={createAccount}
              >
                Create Stripe Account
              </Button>
            </Box>
          )}

          {account && isStripeEnabled && !loading && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '2rem',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography>Account Details</Typography>
                <br />
                <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                  <Typography>ID: </Typography>
                  <Typography color="text.secondary">{account.id}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                  <Typography>Email: </Typography>
                  <Typography color="text.secondary">
                    {account.email}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                  <Typography>Status: </Typography>
                  <Typography color="text.secondary">
                    {account.details_submitted ? 'Enabled' : 'Not Configured'}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: smDown ? 'center' : undefined,
                  gap: '15px',
                  flexDirection: smDown ? 'column' : 'row',
                  width: '100%',
                }}
              >
                {!account.details_submitted && (
                  <Button
                    sx={{
                      width: smDown ? '100%' : '250px',
                      whiteSpace: 'nowrap',
                    }}
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={
                      account.details_submitted
                        ? updateAccount
                        : completeAccount
                    }
                  >
                    Complete Stripe Account
                  </Button>
                )}
                <Button
                  sx={{
                    width: smDown ? '100%' : '250px',
                    whiteSpace: 'nowrap',
                  }}
                  type="button"
                  variant="contained"
                  color="error"
                  onClick={disconnectAccount}
                >
                  Disconnect Stripe Account
                </Button>
              </Box>
            </Box>
          )}
          {account && isStripeEnabled && !loading && (
            <FormControl
              sx={{ width: smDown ? '100%' : undefined }}
              size="small"
            >
              <InputLabel>Set default currency</InputLabel>
              <Select
                value={currency}
                label="Set default currency"
                placeholder="Select your default currency"
                onChange={handleCurrencyChange}
                sx={{ width: !smDown ? '250px' : undefined }}
              >
                {PRICE_CURRENCIES.map((currency) => (
                  <MenuItem key={currency.value} value={currency.value}>
                    {currency.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      </>
      {/* </Form>
      </FormProvider> */}

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => {}}
      >
        <CircularProgress size={80} color="secondary" />
      </Backdrop>

      <NotificationDialog
        open={dialog.isOpen}
        onClose={closeDialog}
        message={dialog.message}
        type={dialog.type}
      />
    </>
  )
}

export default FinancialSettings
