import { IReservationList, IReservationSettings } from 'models/Reservations'
import { useApiRequest } from 'utils'
import { getErrorMessage } from 'utils/helpers'

const reservationApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const getMany = async (
    page: number,
    size: number,
    search: string = '',
  ): Promise<IReservationList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
    })

    const endpoint = `reservations?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const create = async (reservation) => {
    const endpoint = `reservations`
    const method = 'POST'
    const headers = { 'Content-Type': 'application/json' }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body: JSON.stringify(reservation),
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const update = async (reservation, reservationId) => {
    const endpoint = `reservations/${reservationId}`
    const method = 'PUT'
    const headers = { 'Content-Type': 'application/json' }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body: JSON.stringify(reservation),
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const remove = async (ids: string[]): Promise<any> => {
    const endpoint = `reservations`
    const method = 'DELETE'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getReservationSettings = async (): Promise<IReservationSettings> => {
    const endpoint = 'reservation-settings'
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const updateReservationSettings = async (
    reservationSettings: IReservationSettings,
  ) => {
    // No need for a "POST" endpoint since reservations settings are
    // created automatically by backend on "GET" request if not found.
    const endpoint = 'reservation-settings'
    const method = 'PUT'
    const headers = { 'Content-Type': 'application/json' }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body: JSON.stringify(reservationSettings),
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const uploadCSV = async (file: File) => {
    const endpoint = `reservations/csv`
    const method = 'POST'
    const body = new FormData()

    body.append('file', file)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    getMany,
    create,
    update,
    remove,
    getReservationSettings,
    updateReservationSettings,
    uploadCSV,
  }
}

export default reservationApi
