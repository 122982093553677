import React from 'react'
import { Box, useTheme } from '@mui/material'
import { Form, NotificationDialog } from 'components'
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form'
import { IUser } from 'models'
import { userApi } from '../../../../resources/user'
import { CSVLink } from 'react-csv'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import useLoadingState from 'hooks/useLoadingState'
import { Help } from 'components/Help'

const TEMPLATE = [
  {
    name: 'John',
    last_name: 'Doe',
    address: 'Main Street 43',
    email: 'john.doe@koloni.me',
    phone_number: '654323330',
    user_id: '',
    pincode: '',
  },
]

const HEADERS = [
  { label: 'name', key: 'name' },
  { label: 'last_name', key: 'last_name' },
  { label: 'address', key: 'address' },
  { label: 'email', key: 'email' },
  { label: 'phone_number', key: 'phone_number' },
  { label: 'user_id', key: 'user_id' },
  { label: 'pincode', key: 'pincode' },
]

interface IBulkUploadFormProps {
  successForm: () => void
  onClose: () => void
}

const bulkUploadForm = ({
  successForm,
  onClose,
}: IBulkUploadFormProps): React.ReactElement => {
  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [csvData, setCsvData] = React.useState<any[]>([])

  const { create } = userApi()
  const methods = useForm<IUser>({})

  const { loading, setLoading } = useLoadingState()

  const theme = useTheme()

  const generateInvalidUsersMessage = (invalidUsers: IUser[]): string => {
    const invalidUsersNames = invalidUsers.map((user) => user.name).join(', ')
    return `The following users are missing required fields: ${invalidUsersNames}`
  }

  const processUsers = async (users: IUser[]) => {
    const { validUsers, invalidUsers } = users.reduce(
      (result, user) => {
        const { name, email, phone_number } = user
        if (!name && !email && !phone_number) return result
        if (!name || !email || !phone_number) {
          result.invalidUsers.push(user)
        } else {
          result.validUsers.push(user)
        }
        return result
      },
      { validUsers: [] as IUser[], invalidUsers: [] as IUser[] },
    )

    if (validUsers.length > 0) {
      try {
        await create(validUsers)
      } catch (error) {
        console.error('Error creating users:', error)
      }
    }

    if (invalidUsers.length > 0) {
      setMessage(generateInvalidUsersMessage(invalidUsers))
      setOpen(true)
    }
  }

  const parseCSV = (csvText: string) => {
    const rows = csvText.split('\n')
    const headerRow = rows.shift()?.split(',')
    const file_list: any[] = []

    if (!headerRow) return

    rows.map((row) => {
      const rowData = row.split(',')
      const obj: { [key: string]: string } = {}
      headerRow.forEach((key, index) => {
        const cleanKey = key.replace(/"/g, '').trim()
        const cleanValue = rowData[index]?.replace(/"/g, '').trim()
        if (cleanValue) {
          obj[cleanKey] = cleanValue
        }
      })
      file_list.push(obj)
    })

    setCsvData(file_list)
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0]

    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        if (e.target && typeof e.target.result === 'string') {
          const text = e.target.result
          parseCSV(text)
        }
      }
      reader.readAsText(file)
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    file ? handleFileUpload({ target: { files: [file] } }) : null
  }

  const handleSubmit: SubmitHandler<IUser> = (data) => {
    try {
      setLoading(true)
      ;(async () => {
        await processUsers([data])
        if (csvData.length > 0) {
          await processUsers(csvData)
          successForm()
          onClose()

          setOpen(true)
          setMessage('Users imported successfully')
        }
      })()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit}>
          <FormWrapper>
            <Help helpText="CSV files allow bulk upload. You can see items in a list view. Upload your CSV file here to see your data in the table">
              <CSVLink
                data={TEMPLATE}
                headers={HEADERS}
                filename="users_template.csv"
                enclosingCharacter=""
                style={{
                  color: theme.palette.primary.main,
                }}
              >
                Download CSV Template
              </CSVLink>
            </Help>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{
                  border: '2px dashed #aaa',
                  borderRadius: '4px',
                  padding: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  position: 'relative',
                  width: '100%',
                }}
              >
                {csvData.length === 0
                  ? 'Drag and drop a CSV file here or click to upload.'
                  : csvData.length +
                    ' rows of users. Click to "save" button to upload the imported users.'}
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleFileUpload}
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    cursor: 'pointer',
                  }}
                />
              </div>
            </Box>
            <FormActions onClose={onClose} loading={loading} />

            {open && (
              <NotificationDialog
                open={open}
                onClose={() => setOpen(false)}
                message={message}
              />
            )}
          </FormWrapper>
        </Form>
      </FormProvider>
    </>
  )
}

export default bulkUploadForm
