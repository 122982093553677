import { IUserList, IUser } from 'models'
import { useApiRequest } from 'utils'
import { HTTP_CODES } from 'constants/HTTP'
import { getErrorMessage } from 'utils/helpers'

const userApi = () => {
  const { fetchPartnerEndpoint, fetchEndpoint } = useApiRequest()

  const create = async (data: any): Promise<any> => {
    const endpoint = 'users'
    const method = 'POST'
    const headers = { 'Content-Type': 'application/json' }
    const body = JSON.stringify(data)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getMany = async (
    page: number = 1,
    size: number = 10,
    search: string = '',
    by_phone: boolean = true,
    by_email: boolean = true,
    by_first_name: boolean = true,
    by_user_id: boolean = true,
    by_last_name: boolean = true,
  ): Promise<IUserList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
      by_phone: by_phone.toString(),
      by_email: by_email.toString(),
      by_first_name: by_first_name.toString(),
      by_user_id: by_user_id.toString(),
      by_last_name: by_last_name.toString(),
    })

    const endpoint = `users?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const search = async (
    page: number,
    size: number,
    search: string = '',
  ): Promise<IUserList | null> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
    })
    const endpoint = `users/search?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const deleteUser = async (ids: string[]): Promise<void> => {
    const endpoint = 'users'
    const method = 'DELETE'
    const headers = { 'Content-Type': 'application/json' }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }
  const updateUser = async (id: string, data: any): Promise<void> => {
    const endpoint = `users/${id}`
    const method = 'PUT'
    const headers = { 'Content-Type': 'application/json' }
    const body = JSON.stringify(data)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const assign = async (
    userId: string,
    resourceId: string | undefined,
    type: string,
  ): Promise<any> => {
    const endpoint = `resources/${resourceId}/assign-user?id_user=${userId}&resource_type=${type}`
    const method = 'PATCH'
    const body = {
      resource_id: resourceId,
    }

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method, body })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const assigned = async (id, type) => {
    const endpoint = `resources/${id}/users?resource_type=${type}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const unassign = async (
    userId: string,
    resourceId: string,
    type: string,
  ): Promise<void> => {
    const endpoint = `resources/${resourceId}/remove-user?id_user=${userId}&resource_type=${type}`
    const method = 'PATCH'

    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    getMany,
    search,
    deleteUser,
    updateUser,
    assign,
    assigned,
    unassign,
    create,
  }
}

export default userApi
