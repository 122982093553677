import Papa from 'papaparse'

export const getCookie = (cookieName) => {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.startsWith(cookieName + '=')) {
      return cookie.substring(cookieName.length + 1)
    }
  }
  return null
}

export const getDeviceStatusColor = (
  device_status: string,
  lock_status: string,
) => {
  if (
    (device_status == 'available' && lock_status == 'offline') ||
    (device_status == 'reserved' && lock_status == 'offline')
  ) {
    return 'gray'
  }

  switch (device_status) {
    case 'available':
      return '#12BEC9'
    case 'maintenance':
      return '#FB4671'
    case 'reserved':
      return '#4A42EA'
    default:
      return '#12BEC9'
  }
}

export const parseCurrency = (currency: string) => {
  switch (currency) {
    case 'usd':
      return '$'
    case 'eur':
      return '€'
    case 'gbp':
      return '£'
    case 'aud':
      return 'AU$'
    case 'cad':
      return 'CA$'
    default:
      return '$'
  }
}

export const parseUnit = (unit: string) => {
  switch (unit) {
    case 'minute':
      return 'Min'
    case 'kg':
      return 'Kg'
    case 'lb':
      return 'Lb'
    default:
      return unit.charAt(0).toUpperCase() + unit.slice(1)
  }
}

export const parseRole = (role: string) =>
  role === 'admin'
    ? 'Administrator'
    : role.charAt(0).toUpperCase() + role.slice(1)

export const capitalizeWords = (
  str: string,
  splitter: string = ' ',
  separator: string = ' ',
) => {
  const wordsArray = str.split(splitter)

  const capitalizedWordsArray = wordsArray.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1),
  )

  const capitalizedString = capitalizedWordsArray.join(separator)

  return capitalizedString
}

export const capitalizeFirstLetter = (word: string) => {
  return word?.charAt(0).toUpperCase() + word?.slice(1)
}

export const getUserRole = () => {
  const { role } = JSON.parse(localStorage.getItem('userSession') || '{}')
  return role ? role : null
}

export const getCurrentUserProperty = (property: string) => {
  const user = JSON.parse(localStorage.getItem('userSession') || '{}')
  const prop = user[property]
  return prop ? prop : null
}

export const getCurrentOrgProperty = (property: string) => {
  const org = JSON.parse(localStorage.getItem('currentOrg') || '{}')
  const prop = org[property]
  return prop ? prop : null
}

export const getSettingsProperty = (property: string) => {
  const settings = JSON.parse(localStorage.getItem('settings') || '{}')
  const prop = settings[property]
  return prop ? prop : null
}

export const isSuperTenant = () => {
  const isSuperTenant = getCurrentOrgProperty('isSuperTenant')
  return isSuperTenant
}

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (error) {
    return null
  }
}

export const getWebSocketUrl = (
  API_URL: string | undefined,
  orgId: string,
): string => {
  if (!API_URL || !orgId) return ''
  let replacedProtocol = API_URL?.replace('https://', 'wss://').replace(
    // For local development, in case we're pointing to
    // http://localhost:5002 (LockersAPI)
    'http://',
    'wss://',
  )
  let newUrl = new URL(replacedProtocol).origin
  return `${newUrl}/v3/events/listener/${orgId}`
}

export const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const average = (data: number[]): number => {
  if (data.length < 1) {
    return 0
  }
  return data.reduce((prev, current) => prev + current) / data.length
}

export const secondsToTimeFormat = (seconds: number): string => {
  const roundedSeconds = Math.round(seconds)
  const hours = Math.floor(roundedSeconds / 3600)
  const minutes = Math.floor((roundedSeconds % 3600) / 60)
  const remainingSeconds = roundedSeconds % 60
  if (hours > 0) {
    return `${hours}h ${String(minutes).padStart(2, '0')}m ${String(
      remainingSeconds,
    ).padStart(2, '0')}s`
  } else if (minutes > 0) {
    return `${String(minutes).padStart(2, '0')}m ${String(
      remainingSeconds,
    ).padStart(2, '0')}s`
  } else {
    return `${String(remainingSeconds).padStart(2, '0')}s`
  }
}

export const getErrorMessage = (error: any): string => {
  if (error && Array.isArray(error)) {
    let errorMessage = ''
    error.forEach((err: any) => {
      errorMessage += `${err.msg} `
    })
    return errorMessage
  } else if (error && typeof error === 'string') {
    return error
  } else if (error && error.detail) {
    return JSON.stringify(error.detail)
  } else {
    return 'An unexpected error has ocurred. Please try again later or contact customer support.'
  }
}

export const createCsvBlob = (csvRaw: string) => {
  // This function is used to create a Blob object from a plain string.
  // This also assumes the string passed is of valid CSV format.
  const csvData = Papa.unparse(Papa.parse(csvRaw).data)
  const blob = new Blob([csvData], { type: 'text/csv' })
  return blob
}

export const parseSnakeCaseToTitleCase = (snake: string) =>
  snake
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase())

export const getOrganizationFromHost = (): string => {
  const host: string = window.location.hostname
  const organization: string = host.split('.')[0]

  if (organization === 'localhost' || organization === 'koloni') {
    return process.env.REACT_APP_DEFAULT_ORG || 'demo-org'
  }

  return organization
}

export const validateIntegerField = (value: string | number) => {
  const newValue = String(value).replace(/\D/g, '')
  if (!newValue) return newValue
  return newValue
}

export const validateFloatingField = (value: string) => {
  const newValue = String(value).replace(/[^0-9.]/g, '')
  if (!newValue) return newValue
  return newValue
}

export const parseHexToRGB = (hex: string, opacity: string = '1') => {
  // Use a regular expression to extract the individual color values from the hex string
  const values = hex.match(/\w\w/g)

  if (values) {
    // Convert the hex color values to decimal values using parseInt() and store them in r, g, and b
    const [r, g, b] = values.map((k) => parseInt(k, 16))

    // Create the rgba string using the decimal values and opacity
    const rgba = `rgba( ${r}, ${g}, ${b}, ${opacity})`
    return rgba
  }
}

export const openDoc = (link: string, callback: Function | null = null) => {
  window.open(link, '_blank')
  callback?.()
}

export const imageUrlToFile = async (url: string, fileName: string) => {
  const response = await fetch(url)
  const blob = await response.blob()
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  const file = new File([blob], fileName, {
    type: blob.type,
  })
  return file
}

export const snakeCaseToHumanReadable = (snakeCase: string) => {
  // Replace underscores with spaces
  const withSpaces = snakeCase.replace(/_/g, ' ')

  // Capitalize the first letter of each word
  const capitalized = withSpaces[0].toUpperCase() + withSpaces.slice(1)

  return capitalized
}

export const getDifferenceInMinutes = (date1, date2) => {
  const msInMinute = 1000 * 60
  return Math.abs((date2 - date1) / msInMinute)
}

export const getDifferenceInHours = (date1, date2) => {
  const msInHour = 1000 * 60 * 60
  return Math.abs((date2 - date1) / msInHour)
}

export const getDifferenceInDays = (date1, date2) => {
  const msInDay = 1000 * 60 * 60 * 24
  return Math.abs((date2 - date1) / msInDay)
}

export const getDifferenceInWeeks = (date1, date2) => {
  const msInWeek = 1000 * 60 * 60 * 24 * 7
  return Math.abs((date2 - date1) / msInWeek)
}
