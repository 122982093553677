import { Grid, useMediaQuery } from '@mui/material'
import { ToolbarControl } from 'types'

interface IToolbarControlsProps {
  controls: ToolbarControl[]
}

const ToolbarControls = ({ controls }: IToolbarControlsProps) => {
  const query = useMediaQuery(`(max-width:900px)`)

  return (
    <Grid
      container
      gap={query ? 2 : 1}
      columnGap={1}
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(${
          query &&
          controls.filter(
            (control) =>
              control.display &&
              (control.isMenu === undefined || !control.isMenu),
          ).length === 1
            ? '120px'
            : '150px'
        }, 1fr))`,
      }}
    >
      {controls.map((control) => control.display && control.render)}
    </Grid>
  )
}

export default ToolbarControls
