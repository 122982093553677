import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { Form, IDialogType, SelectField, TextField } from 'components'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import useLoadingState from 'hooks/useLoadingState'
import {
  PAYMENT_TYPES,
  PERIODS,
  RESERVATION_TYPES,
} from 'pages/Events/constants'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

interface ISettingsFormProps {
  onClose: () => void
  displayMessage: (message: string, type?: IDialogType) => void
}

const SettingsForm = ({ onClose, displayMessage }: ISettingsFormProps) => {
  const [delayAction, setDelayAction] = useState<
    'charge_fee' | 'reminder' | 'both' | ''
  >('')
  const [signInToReserve, setSignInToReserve] = useState<
    'yes_reserve' | 'no_reserve' | ''
  >('')
  const [signInToSeeLockerAvailabilty, setSignInToSeeLockerAvailabilty] =
    useState<'yes_locker_availability' | 'no_locker_availability' | ''>('')
  const [bufferTime, setBufferTime] = useState<
    'yes_buffer_time' | 'no_buffer_time' | ''
  >('')
  const [allowExtendReservations, setAllowExtendReservations] = useState<
    'yes_extend' | 'no_extend' | ''
  >('')
  const [calculateCostWhileReserving, setCalculateCostWhileReserving] =
    useState<'yes_calculate' | 'no_calculate' | ''>('')
  const [showBarForWebsite, setShowBarForWebsite] = useState<
    'yes_bar' | 'no_bar' | ''
  >('')

  const methods = useForm<any>()

  const { loading, setLoading } = useLoadingState()

  const handleToggleDelayAction = (event) => {
    const { checked, name } = event.target
    if (checked && name !== delayAction) {
      setDelayAction(name)
    }
  }

  const handleToggleSignInToReserve = (event) => {
    const { checked, name } = event.target
    if (checked && name !== signInToReserve) {
      setSignInToReserve(name)
    }
  }

  const handleToggleSignInToSeeLockerAvailabilty = (event) => {
    const { checked, name } = event.target
    if (checked && name !== signInToSeeLockerAvailabilty) {
      setSignInToSeeLockerAvailabilty(name)
    }
  }

  const handleToggleBufferTime = (event) => {
    const { checked, name } = event.target
    if (checked && name !== bufferTime) {
      setBufferTime(name)
    }
  }

  const handleToggleExtendReservation = (event) => {
    const { checked, name } = event.target
    if (checked && name !== allowExtendReservations) {
      setAllowExtendReservations(name)
    }
  }

  const handleToggleCalculateCostWhileReserving = (event) => {
    const { checked, name } = event.target
    if (checked && name !== calculateCostWhileReserving) {
      setCalculateCostWhileReserving(name)
    }
  }

  const handleToggleShowBarForWebsite = (event) => {
    const { checked, name } = event.target
    if (checked && name !== showBarForWebsite) {
      setShowBarForWebsite(name)
    }
  }

  const onSubmit = () => {
    try {
      setLoading(true)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  console.log(methods.watch('yes_buffer_time'))
  console.log(methods.watch('no_buffer_time'))

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper title="Reservation Settings">
            <Typography variant="h5">Maximum x Time</Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <FormControl sx={{ width: '50%' }}>
                <TextField
                  name="time"
                  type="number"
                  label="Time"
                  placeholder="Time"
                  rules={{
                    required: 'Time is required',
                  }}
                />
              </FormControl>

              <FormControl sx={{ width: '50%' }}>
                <SelectField
                  name="period"
                  label="Period"
                  items={PERIODS}
                  rules={{ required: 'Period is required' }}
                />
              </FormControl>
            </Box>
            <SelectField
              name="payment"
              label="Payment type"
              items={PAYMENT_TYPES}
              rules={{ required: 'Payment type is required' }}
            />
            {methods.watch('payment') === 'partly' && (
              <TextField
                name="partly_percentaje"
                type="number"
                label="Partly payment percentage %"
                placeholder="%"
                rules={{
                  required: 'Partly payment percentage is required',
                }}
              />
            )}
            <Typography variant="h5">If not picked up on time</Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                // width: '100%',
                alignItems: 'center',
              }}
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('charge_fee')}
                      checked={delayAction === 'charge_fee'}
                      onChange={handleToggleDelayAction}
                    />
                  }
                  label="Charge fee"
                />
              </FormControl>

              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('reminder')}
                      checked={delayAction === 'reminder'}
                      onChange={handleToggleDelayAction}
                    />
                  }
                  label="Send reminders"
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('both')}
                      checked={delayAction === 'both'}
                      onChange={handleToggleDelayAction}
                    />
                  }
                  label="Both"
                />
              </FormControl>
            </Box>
            <Typography variant="h5">Sign in to reserve</Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                // width: '100%',
                alignItems: 'center',
              }}
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('yes_reserve')}
                      checked={signInToReserve === 'yes_reserve'}
                      onChange={handleToggleSignInToReserve}
                    />
                  }
                  label="Yes"
                />
              </FormControl>

              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('no_reserve')}
                      checked={signInToReserve === 'no_reserve'}
                      onChange={handleToggleSignInToReserve}
                    />
                  }
                  label="No"
                />
              </FormControl>
            </Box>
            <Typography variant="h5">
              Sign in to see availability of lockers
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                // width: '100%',
                alignItems: 'center',
              }}
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('yes_locker_availability')}
                      checked={
                        signInToSeeLockerAvailabilty ===
                        'yes_locker_availability'
                      }
                      onChange={handleToggleSignInToSeeLockerAvailabilty}
                    />
                  }
                  label="Yes"
                />
              </FormControl>

              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('no_locker_availability')}
                      checked={
                        signInToSeeLockerAvailabilty ===
                        'no_locker_availability'
                      }
                      onChange={handleToggleSignInToSeeLockerAvailabilty}
                    />
                  }
                  label="No"
                />
              </FormControl>
            </Box>
            <SelectField
              name="reservation_type"
              label="Reservation type"
              items={RESERVATION_TYPES}
              rules={{ required: 'Reservation type is required' }}
            />
            <Typography variant="h5">
              Buffer time between each transaction
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                // width: '100%',
                alignItems: 'center',
              }}
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('yes_buffer_time')}
                      checked={bufferTime === 'yes_buffer_time'}
                      onChange={handleToggleBufferTime}
                    />
                  }
                  label="Yes"
                />
              </FormControl>

              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('no_buffer_time')}
                      checked={bufferTime === 'no_buffer_time'}
                      onChange={handleToggleBufferTime}
                    />
                  }
                  label="No"
                />
              </FormControl>
            </Box>
            {bufferTime === 'yes_buffer_time' && (
              <TextField
                name="buffer_time"
                type="number"
                label="Minutes"
                placeholder="Minutes"
                rules={{
                  required: 'Buffer time between each transaction is required',
                }}
              />
            )}
            <Typography variant="h5">
              Allow to extend the reservations
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                // width: '100%',
                alignItems: 'center',
              }}
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('yes_extend')}
                      checked={allowExtendReservations === 'yes_extend'}
                      onChange={handleToggleExtendReservation}
                    />
                  }
                  label="Yes"
                />
              </FormControl>

              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('no_extend')}
                      checked={allowExtendReservations === 'no_extend'}
                      onChange={handleToggleExtendReservation}
                    />
                  }
                  label="No"
                />
              </FormControl>
            </Box>
            {allowExtendReservations === 'yes_extend' && (
              <Box
                sx={{
                  display: 'flex',
                  gap: '1rem',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <FormControl sx={{ width: '50%' }}>
                  <TextField
                    name="extend_reservation_time"
                    type="number"
                    label="Extend reservation time"
                    placeholder="Time"
                    rules={{
                      required: 'Extend reservation time is required',
                    }}
                  />
                </FormControl>

                <FormControl sx={{ width: '50%' }}>
                  <SelectField
                    name="extend_reservation_period"
                    label="Extend reservation period"
                    items={PERIODS}
                    rules={{
                      required: 'Extend reservation period is required',
                    }}
                  />
                </FormControl>
              </Box>
            )}
            <Typography variant="h5">
              Calculate estimated cost of transaction while reserving
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                // width: '100%',
                alignItems: 'center',
              }}
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('yes_calculate')}
                      checked={calculateCostWhileReserving === 'yes_calculate'}
                      onChange={handleToggleCalculateCostWhileReserving}
                    />
                  }
                  label="Yes"
                />
              </FormControl>

              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('no_calculate')}
                      checked={calculateCostWhileReserving === 'no_calculate'}
                      onChange={handleToggleCalculateCostWhileReserving}
                    />
                  }
                  label="No"
                />
              </FormControl>
            </Box>
            <Typography variant="h5">
              Show reservation bar for website
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                // width: '100%',
                alignItems: 'center',
              }}
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('yes_bar')}
                      checked={showBarForWebsite === 'yes_bar'}
                      onChange={handleToggleShowBarForWebsite}
                    />
                  }
                  label="Yes"
                />
              </FormControl>

              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('no_bar')}
                      checked={showBarForWebsite === 'no_bar'}
                      onChange={handleToggleShowBarForWebsite}
                    />
                  }
                  label="No"
                />
              </FormControl>
            </Box>

            <FormActions onClose={onClose} loading={loading} />
          </FormWrapper>
        </Form>
      </FormProvider>
    </>
  )
}

export default SettingsForm
