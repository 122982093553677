import { Build, DeleteForever, QrCode } from '@mui/icons-material'
import { Checkbox, Chip, Link, Tooltip, Typography } from '@mui/material'
import {
  ConfirmDialog,
  IDialogType,
  IconButton,
  QRGenerator,
  TableCell,
  TableRow,
} from 'components'
import { IDevice, DeviceMode, ILocation } from 'models'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { deviceApi } from 'resources'
import { API_URL } from '../../../../constants'
import {
  capitalizeFirstLetter,
  getDeviceStatusColor,
  getUserRole,
} from 'utils/helpers'

interface IDeviceRowProps {
  device: IDevice
  success: () => void
  handleEdit: (device: IDevice) => void
  displayMessage: (message: string, type?: IDialogType) => void
  filteredColumns: any[]
  openLocationForm: () => void
  setCurrentLocation: Dispatch<SetStateAction<ILocation | undefined>>
  handleUnlockButtonState: () => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    device: any,
  ) => void
  selected: boolean
}

const defaultConfirmation = {
  isOpen: false,
  message: '',
  action: '',
}

const DeviceRow = ({
  device,
  success,
  handleEdit,
  displayMessage,
  filteredColumns,
  openLocationForm,
  setCurrentLocation,
  handleUnlockButtonState,
  handleSelectRow,
  selected,
}: IDeviceRowProps): React.ReactElement => {
  const [confirmationMessage, setConfirmationMessage] =
    useState(defaultConfirmation)

  const { generateQRCode } = QRGenerator()
  const { remove, setMaintenanceMode, unlockAll } = deviceApi()

  const role = getUserRole()

  const maintenanceMessage = useMemo(
    () =>
      device.status !== 'maintenance'
        ? 'This action will put the device in maintenance mode. Do you want to continue?'
        : 'Are you sure you want to make this device available?',
    [device],
  )

  const handleUnlock = async () => {
    setConfirmationMessage(defaultConfirmation)

    if (
      (device.hardware_type == 'gantner' && device.lock_status == 'unknown') ||
      (device.hardware_type == 'gantner' && device.lock_status == 'offline')
    ) {
      displayMessage(
        'Unable to reach lock. Please check your lock is connected',
        'error',
      )
      return
    }

    try {
      const response = await unlockAll([device.id])
      displayMessage(
        response?.message?.detail || 'Device unlocked correctly.',
        'success',
      )
      success()
    } catch (error: any) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleMaintenanceMode = async () => {
    try {
      const disable = device.status === 'maintenance' ? false : true
      const response = await setMaintenanceMode([device.id], disable)
      setConfirmationMessage(defaultConfirmation)
      displayMessage(
        device.status !== 'maintenance'
          ? response?.message?.detail || 'Device has been put into maintenance.'
          : 'Device is now available.',
        'success',
      )
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
      setConfirmationMessage(defaultConfirmation)
    }
  }

  const handleDelete = async () => {
    try {
      const response = await remove([device.id])
      setConfirmationMessage(defaultConfirmation)
      displayMessage('Your device was deleted.', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
      setConfirmationMessage(defaultConfirmation)
    }
  }

  const handleQRCode = (): void => {
    generateQRCode(
      device.id,
      `${API_URL?.replace('partner', 'mobile')}device/${device.id}`,
    ) // Requested by the mobile team, they need the mobile url to be in the QR code
  }

  const handleConfirmAction = async (action) => {
    switch (action) {
      case 'maintenance':
        await handleMaintenanceMode()
        break
      case 'delete':
        await handleDelete()
        break
      case 'unlock':
        await handleUnlock()
        break
      default:
        return false
    }
  }

  const handleSelectedDevice = () => {
    const selectedDevices = JSON.parse(
      localStorage.getItem('selectedDevices') || '[]',
    )

    const index = selectedDevices.findIndex((d) => d.id === device.id)

    if (index !== -1) {
      selectedDevices.splice(index, 1)
    } else {
      selectedDevices.push(device)
    }

    localStorage.setItem('selectedDevices', JSON.stringify(selectedDevices))
    handleUnlockButtonState()
  }

  return (
    <>
      <TableRow>
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center" width="100%">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectedDevice()
                  handleSelectRow(event, checked, device)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'name' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role !== 'member' ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (device.status === 'reserved') {
                    displayMessage('Reserved devices cannot be edited.', 'info')
                  } else {
                    handleEdit(device)
                  }
                }}
              >
                {device.name}
              </Link>
            ) : (
              <>{device.name}</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'locker_number' && c.active,
        ) !== -1 && (
          <TableCell align="center">{device.locker_number}</TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'location' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {role !== 'member' ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCurrentLocation(device?.location)
                  openLocationForm()
                }}
              >
                {device?.location?.name}
              </Link>
            ) : (
              <>{device?.location?.name}</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'size' && c.active) !==
          -1 && (
          <TableCell align="center">
            {device?.size ? (
              <Tooltip
                title={`Dimensions: ${device?.size?.width}"x${device?.size?.height}"x${device?.size?.depth}"`}
              >
                <Typography>{device?.size?.name}</Typography>
              </Tooltip>
            ) : (
              '-'
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'hardware_type' && c.active,
        ) !== -1 && (
          <TableCell>{capitalizeFirstLetter(device.hardware_type)}</TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'status' && c.active) !==
          -1 && (
          <TableCell>
            <Chip
              size="small"
              sx={{
                backgroundColor: getDeviceStatusColor(
                  device.status,
                  device.lock_status,
                ),
                opacity: '80%',
                color: 'white',
              }}
              label={capitalizeFirstLetter(device.status)}
            />
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'state' && c.active) !==
          -1 && (
          <TableCell>
            <Chip
              size="small"
              onClick={() =>
                role !== 'member' &&
                setConfirmationMessage({
                  isOpen: true,
                  message:
                    'This will force your device to unlock. Do you want to continue?',
                  action: 'unlock',
                })
              }
              sx={{
                cursor: 'pointer',
                color: 'white',
                opacity:
                  device.lock_status === 'open'
                    ? '70%'
                    : device.lock_status === 'locked'
                    ? '70%'
                    : '80%',
                backgroundColor:
                  device.lock_status === 'open'
                    ? 'red'
                    : device.lock_status === 'locked'
                    ? 'green'
                    : 'gray',
              }}
              label={capitalizeFirstLetter(device.lock_status)}
            />
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'mode' && c.active) !==
          -1 && (
          <TableCell>
            {capitalizeFirstLetter(
              device.mode !== 'rental' ? device.mode : 'asset',
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'item' && c.active) !==
          -1 && (
          <TableCell>
            {DeviceMode.rental === device?.mode && device?.product?.name}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'qr_code' && c.active) !==
          -1 && (
          <TableCell align="center">
            <IconButton
              onClick={handleQRCode}
              sx={{
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              <Tooltip title="QR code">
                <QrCode />
              </Tooltip>
            </IconButton>
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell>
            {role !== 'member' && (
              <>
                <IconButton
                  disabled={device.status === 'reserved'}
                  onClick={() =>
                    device.status === 'reserved'
                      ? displayMessage(
                          'You cannot put a reserved device in maintenance mode.',
                          'warning',
                        )
                      : setConfirmationMessage({
                          isOpen: true,
                          message: maintenanceMessage,
                          action: 'maintenance',
                        })
                  }
                  sx={{
                    color: (theme) =>
                      device.status === 'reserved'
                        ? 'gray'
                        : theme.palette.mode === 'dark'
                        ? '#ffffff'
                        : '#0000008a',
                    '&:hover': {
                      color: 'primary.main',
                    },
                  }}
                >
                  <Tooltip title="Maintenance">
                    <Build />
                  </Tooltip>
                </IconButton>

                <IconButton
                  onClick={() => {
                    setConfirmationMessage({
                      isOpen: true,
                      message: 'Are you sure you want to delete this device?',
                      action: 'delete',
                    })
                  }}
                >
                  <Tooltip title="Delete">
                    <DeleteForever />
                  </Tooltip>
                </IconButton>
              </>
            )}
          </TableCell>
        )}
      </TableRow>
      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() => setConfirmationMessage(defaultConfirmation)}
        onClickConfirm={() => handleConfirmAction(confirmationMessage.action)}
        onClickCancel={() => setConfirmationMessage(defaultConfirmation)}
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default DeviceRow
