import {
  Button,
  NotificationDialog,
  SideBar,
  Table,
  TableBody,
  TablePagination,
} from 'components'
import { DEFAULT_PAGE, DEVICES_PER_PAGE } from 'constants/pagination'
import useDialog from 'hooks/useDialog'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import ProductForm from './ProductForm'
import { IProduct, IProductList } from 'models/Product'
import productApi from 'resources/product/api/productApi'
import ProductRow from './ProductRow'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import { IProductGroup } from 'models/ProductGroup'
import { productGroupApi } from 'resources/product-group'
import useSortableHeader from 'hooks/useSortableHeader'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import useColumnFiltering from 'hooks/useColumnFiltering'
import NoData from 'components/PageBase/NoData'
import LoadingData from 'components/PageBase/LoadingData'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import { ToolbarControl } from 'types'
import useLoadingState from 'hooks/useLoadingState'

const Inventory = () => {
  const [products, setProducts] = useState<IProductList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [productsBackup, setProductsBackup] = useState<IProduct[]>([])
  const [productGroups, setProductGroups] = useState<IProductGroup[]>([])
  const [currentProduct, setCurrentProduct] = useState<IProduct | undefined>()
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEVICES_PER_PAGE)
  const [search, setSearch] = useState<string>('')
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const { getMany, remove } = productApi()
  const { getMany: getProductGroups } = productGroupApi()
  const { dialog, displayMessage, closeDialog } = useDialog()

  const { filteredColumns, setFilteredColumns, getDefaultFilters } =
    useColumnFiltering({ displayMessage })

  const { loading, setLoading } = useLoadingState(true)

  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'name',
      entity: 'inventory',
      nestedProps: [
        {
          columnValue: 'product_name',
          path: 'name',
          defaultValue: '',
        },
        {
          columnValue: 'cost',
          path: 'price',
          defaultValue: 0,
        },
        {
          columnValue: 'sale_price',
          path: 'sales_price',
          defaultValue: 0,
        },
      ],
    })

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => {
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, id]
        : previousValue.filter((rowId) => rowId !== id),
    )
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() =>
      checked ? products.items.map((product) => product.id) : [],
    )
  }

  const handleDeleteMany = async () => {
    try {
      await remove(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      setSelectedRows([])
      fetchProducts()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }

  const handleEditProduct = (product: IProduct) => {
    setCurrentProduct(product)
    setOpenSidebar(true)
  }

  const handleCloseSidebar = () => {
    setOpenSidebar(false)
    setCurrentProduct(undefined)
  }

  const fetchProducts = async (
    showNewestFirst: boolean = false,
  ): Promise<void> => {
    try {
      setLoading(true)
      const products = await getMany(currentPage + 1, rowsPerPage, search)
      setProductsBackup([...products.items])
      setProducts(products)
      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const fetchProductGroups = async (): Promise<void> => {
    try {
      const productGroups = await getProductGroups(1, 1000)
      setProductGroups(productGroups.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  useEffect(() => {
    fetchProducts()
    fetchProductGroups()
    getDefaultFilters()
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [search, currentPage, rowsPerPage])

  const visibleRows = useMemo(
    () => getVisibleRowsSorted(products.items, productsBackup),
    [products, order, orderBy],
  )

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button
          variant="contained"
          name="addSize"
          disabled={loading}
          onClick={() => setOpenSidebar(true)}
        >
          Add Product
        </Button>
      ),
    },
  ]

  return (
    <>
      <Toolbar controls={controls.filter((control) => control.display)}>
        <ToolbarControls
          controls={controls.filter((control) => control.display)}
        />
        <ToolbarSearchbar
          handleSearch={handleSearch}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
        />
      </Toolbar>
      {selectedRows.length > 0 && (
        <MultiSelectToolbar
          itemsSelected={selectedRows.length}
          handleAction={handleDeleteMany}
        />
      )}
      {!loading && visibleRows.length > 0 && (
        <>
          <Table>
            <SortableTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={filteredColumns.filter((c) => c.active)}
              handleSelectAll={handleSelectAll}
            />
            <TableBody>
              {visibleRows.map((product) => (
                <ProductRow
                  key={product.id}
                  product={product}
                  success={fetchProducts}
                  displayMessage={displayMessage}
                  filteredColumns={filteredColumns}
                  handleEditProduct={handleEditProduct}
                  handleSelectRow={handleSelectRow}
                  selected={selectedRows.includes(product.id)}
                  groups={productGroups}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            totalItems={products.total}
            currentPage={currentPage}
            itemsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setRowsPerPage}
          />
        </>
      )}

      <LoadingData isLoading={loading} />

      <NoData condition={!loading && visibleRows.length === 0} />

      {openSidebar && (
        <SideBar open={openSidebar} onClose={handleCloseSidebar}>
          <ProductForm
            product={currentProduct}
            success={fetchProducts}
            onClose={handleCloseSidebar}
            displayMessage={displayMessage}
            groups={productGroups}
          />
        </SideBar>
      )}
      <NotificationDialog
        open={dialog.isOpen}
        onClose={closeDialog}
        message={dialog.message}
        type={dialog.type}
      />
    </>
  )
}

export default Inventory
