import { useState, useEffect, ChangeEvent, useMemo } from 'react'
import { OrgFilter, IOrganizationList, IOrganization } from 'models'
import { Box, Divider, Switch, Typography } from '@mui/material'
import {
  Button,
  SideBar,
  NotificationDialog,
  TablePagination,
  Table,
  TableHead,
  TableBody,
} from 'components'
import { organizationApi } from 'resources'
import OrganizationForm from './components/OrganizationForm'
import useIsSuperTenantOrg from 'hooks/useIsSuperTenantOrg'
import useDialog from 'hooks/useDialog'
import { DEVICES_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination'
import { ORGANIZATION_TABLE_HEADERS } from 'pages/Settings/constants'
import OrganizationRow from './components/OrganizationRow'
import useLoadingState from 'hooks/useLoadingState'
import LoadingData from 'components/PageBase/LoadingData'
import NoData from 'components/PageBase/NoData'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import { ORG_FILTERS } from './constants'
import { ToolbarControl } from 'types'

const Organization = () => {
  const [orgs, setOrgs] = useState<IOrganizationList>({
    items: [],
    pages: 0,
    total: 0,
  })
  const [showSidebar, setShowSidebar] = useState<boolean>(false)
  const [selectedOrg, setSelectedOrg] = useState<IOrganization>()
  const [search, setSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEVICES_PER_PAGE)
  const [currentFilter, setCurrentFilter] = useState<OrgFilter>('active')

  const [myOrg, setMyOrg] = useState<IOrganization>()

  const isSuperTenantOrg = useIsSuperTenantOrg()

  const { dialog, displayMessage, closeDialog } = useDialog()
  const { loading: loadingSelfOrg, setLoading: setLoadingSelfOrg } =
    useLoadingState(true)
  const { loading: loadingOrgs, setLoading: setLoadingOrgs } =
    useLoadingState(true)
  const { getOrgs, getSelfOrg } = organizationApi()

  const fetchSelfOrg = async () => {
    try {
      setLoadingSelfOrg(true)
      const selfOrg = await getSelfOrg()
      setMyOrg(selfOrg)
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`)
    } finally {
      setLoadingSelfOrg(false)
    }
  }

  const fetchOrgs = async () => {
    try {
      setLoadingOrgs(true)
      const orgs = await getOrgs(currentPage + 1, rowsPerPage, search)
      setOrgs(orgs)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoadingOrgs(false)
    }
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }

  const handleLocalCloseDialog = () => {
    closeDialog()

    if (dialog.type === 'success') {
      // Reload UI con organization request success.
      // This allows the navigation sidebar to rerender any enabled/disabled
      // feature pages:
      window.location.reload()
    }
  }

  const handleEditOrganization = (organization: IOrganization) => {
    if (organization.active) {
      setSelectedOrg(organization)
      setShowSidebar(true)
    } else {
      displayMessage(
        'Archived organizations must be restored in order to be edited.',
        'info',
      )
    }
  }

  const visibleRows = useMemo(() => {
    switch (currentFilter) {
      case 'active':
        return orgs.items.filter((org) => org.active)
      case 'archived':
        return orgs.items.filter((org) => !org.active)
      case 'all':
        return orgs.items.sort((a, b) =>
          a.active === b.active ? 0 : a.active ? -1 : 1,
        )
    }
  }, [orgs, currentFilter])

  useEffect(() => {
    fetchSelfOrg()
    fetchOrgs()
  }, [])

  useEffect(() => {
    fetchOrgs()
  }, [search, currentPage, rowsPerPage])

  const controls: ToolbarControl[] = [
    {
      display: isSuperTenantOrg,
      render: (
        <Button
          disabled={loadingSelfOrg || loadingOrgs}
          variant="contained"
          name="addPrice"
          onClick={() => {
            setShowSidebar(true)
          }}
        >
          Add Organization
        </Button>
      ),
    },
  ]

  return (
    <>
      <Toolbar controls={controls.filter((control) => control.display)}>
        <ToolbarControls
          controls={controls.filter((control) => control.display)}
        />
        <ToolbarSearchbar handleSearch={handleSearch}>
          {ORG_FILTERS.map((filter, index) => (
            <Box key={filter.value}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '350px',
                  padding: '10px',
                  cursor: 'pointer',
                }}
              >
                <Typography>{filter.label}</Typography>
                <Switch
                  checked={filter.value === currentFilter}
                  defaultChecked={filter.value === currentFilter}
                  onChange={() => setCurrentFilter(filter.value)}
                />
              </Box>
              {index !== ORG_FILTERS.length - 1 && <Divider light />}
            </Box>
          ))}
        </ToolbarSearchbar>
      </Toolbar>
      {!loadingOrgs && !loadingSelfOrg && visibleRows.length > 0 && (
        <>
          <Table>
            <TableHead headers={ORGANIZATION_TABLE_HEADERS} />
            <TableBody>
              {myOrg &&
                visibleRows.map((org) => (
                  <OrganizationRow
                    key={org.id}
                    organization={org}
                    myOrg={myOrg}
                    success={fetchOrgs}
                    displayMessage={displayMessage}
                    handleEditOrganization={handleEditOrganization}
                  />
                ))}
            </TableBody>
          </Table>
          <TablePagination
            totalItems={orgs.total}
            currentPage={currentPage}
            itemsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setRowsPerPage}
          />
        </>
      )}

      <LoadingData isLoading={loadingSelfOrg || loadingOrgs} />

      <NoData
        condition={!(loadingSelfOrg || loadingOrgs) && visibleRows.length === 0}
      />

      <SideBar
        open={showSidebar}
        onClose={() => {
          setSelectedOrg(undefined)
          setShowSidebar(false)
        }}
      >
        <OrganizationForm
          displayMessage={displayMessage}
          setShowSidebar={setShowSidebar}
          success={fetchOrgs}
          selectedOrg={selectedOrg}
          orgsNames={orgs.items.map((org) => org.name)}
        />
      </SideBar>

      <NotificationDialog
        open={dialog.isOpen}
        message={dialog.message}
        onClose={handleLocalCloseDialog}
        type={dialog.type}
      />
    </>
  )
}

export default Organization
