import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField as MUITextField,
  Chip,
  Autocomplete,
  Switch,
} from '@mui/material'
import {
  ConfirmDialog,
  Form,
  IDialogType,
  Image,
  SelectField,
  SideBar,
  Tabs,
  TextField,
} from 'components'
import { IDevice, IGroup, IUser } from 'models'
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { DEVICE_STATUSES } from '../../constants'
import RentalModeFields from './RentalModeFields'
import HardwareTypeFields from './HardwareTypeFields'
import { deviceApi, groupApi, userApi, sizeApi, locationApi } from 'resources'
import { formatDevicePayload } from '../../helper'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import { BulkUploadEntity } from 'components/BulkUploadEntity'
import useLoadingState from 'hooks/useLoadingState'
import { Help } from 'components/Help'
import { IProduct } from 'models/Product'
import { productApi } from 'resources/product'
import useIsSuperTenantOrg from 'hooks/useIsSuperTenantOrg'
import SizeForm from '../sizes/SizeForm'
import { ToggleSwitch } from 'components/ToggleSwitch'
import { DropdownOption } from 'types'
import useDeviceModes from 'hooks/useDeviceModes'
import ProductForm from 'pages/Products/components/inventory/ProductForm'
import { productGroupApi } from 'resources/product-group'
import { IProductGroup } from 'models/ProductGroup'
import LocationForm from 'pages/Locations/components/LocationForm'
import LoadingFormData from 'components/PageBase/LoadingFormData'
import {
  capitalizeFirstLetter,
  getSettingsProperty,
  getUserRole,
} from 'utils/helpers'
import DeviceHistory from './DeviceHistory'

interface IDeviceFormProps {
  allDevices?: IDevice[]
  device: IDevice | undefined
  success?: (showNewestFirst?: boolean) => void
  onClose: () => void
  sizeOptions: DropdownOption[]
  priceOptions: { value: string; label: string; priceType?: string }[]
  locationOptions: DropdownOption[]
  displayMessage?: (message: string | JSX.Element, type?: IDialogType) => void
  allowEdit?: boolean
}

const DeviceForm = ({
  allDevices = [],
  device,
  success,
  onClose,
  sizeOptions,
  priceOptions,
  locationOptions,
  displayMessage,
  allowEdit = true,
}: IDeviceFormProps): React.ReactElement => {
  const [openSizesSidebar, setOpenSizesSidebar] = useState<boolean>(false)
  const [openProductsSidebar, setOpenProductsSidebar] = useState<boolean>(false)
  const [openLocationsSidebar, setOpenLocationsSidebar] =
    useState<boolean>(false)
  const [confirmationMessage, setConfirmationMessage] = useState({
    isOpen: false,
    message: '',
    type: 'sizes',
  })
  const [productGroups, setProductGroups] = useState<IProductGroup[]>([])
  const [wrongPriceTypeForService, setWrongPriceTypeForServiceError] =
    useState<boolean>(false)

  const orgSettings = JSON.parse(localStorage.getItem('settings') || '{}')

  // Only show Ojmar hardware type if the organization is Ojmar.
  // This is temporary until a better system is in place
  const [isOjmarOrg] = useState(() => {
    const currentOrg = JSON.parse(localStorage.getItem('currentOrg') || '{}')
    return currentOrg && currentOrg.name === 'ojmar'
  })
  const [isGantnerOrg] = useState(() => {
    const currentOrg = JSON.parse(localStorage.getItem('currentOrg') || '{}')
    return currentOrg && currentOrg.name === 'gantnerorg'
  })
  const [currentTab, setCurrentTab] = useState<string>('form')
  const [image, setImage] = useState<File | undefined>()
  const [assignType, setAssignType] = useState<string>(
    device?.restriction?.restriction_type
      ? device.restriction.restriction_type.substring(
          0,
          device.restriction.restriction_type.length - 1,
        )
      : 'user',
  )
  const [users, setUsers] = useState<IUser[]>([])
  const [groups, setGroups] = useState<IGroup[]>([])
  const [loadingResources, setLoadingResources] = useState(true)
  const [usersToAdd, setUsersToAdd] = useState<string[]>([])
  const [groupsToAdd, setGroupsToAdd] = useState<string[]>([])
  const [resourcesToDelete, setResourcesToDelete] = useState<string[]>([])
  const [options, setOptions] = useState<DropdownOption[]>([])
  const [isRestricted, setIsRestricted] = useState<boolean>(
    Boolean(device?.restriction),
  )
  const [priceRequired, setPriceRequired] = useState<boolean>(
    device?.id_price ? true : false,
  )
  const [priceType, setPriceType] = useState('')
  const [products, setProducts] = useState<IProduct[]>([])
  const [productsOptions, setProductsOptions] = useState<
    {
      label: string
      value: string
      serial_number: string
      description: string
    }[]
  >([])
  const [selectedProduct, setSelectedProduct] = useState<{
    label: string
    value: string
    serial_number: string
    description: string
  } | null>(() => {
    if (device && device.product && device.id_product) {
      return {
        label: device.product.name,
        value: device.product.id,
        serial_number: device.product.serial_number,
        description: device.product.description,
      }
    } else {
      return null
    }
  })
  const [assignedProduct, setAssignedProduct] = useState<DropdownOption | null>(
    () => {
      if (device && device.product && device.id_product) {
        return {
          label: device.product.name,
          value: device.product.id,
        }
      } else {
        return null
      }
    },
  )
  const [sizes, setSizes] = useState<DropdownOption[]>([...sizeOptions])
  const [locations, setLocations] = useState<DropdownOption[]>([
    ...locationOptions,
  ])

  const { create, update } = deviceApi()
  const { assign, assigned, unassign, getMany: getUsers } = userApi()
  const {
    getMany: getGroups,
    assign: assignGroup,
    assigned: assignedGroup,
    unassign: unassignGroup,
  } = groupApi()
  const { getMany: getProducts } = productApi()
  const { getMany: getLocations } = locationApi()
  const { getMany: getSizes } = sizeApi()
  const { getMany: getProductGroups } = productGroupApi()

  const { loading, setLoading } = useLoadingState()
  const { loading: isLoadingLocations, setLoading: setLoadingLocations } =
    useLoadingState()
  const { loading: isLoadingProducts, setLoading: setIsLoadingProducts } =
    useLoadingState()

  const { modes, isLoadingModes } = useDeviceModes()

  const isSuperTenantOrg = useIsSuperTenantOrg()

  const methods = useForm<IDevice>({
    defaultValues: {
      ...device,
      mode: device ? device.mode : getSettingsProperty('default_device_mode'),
      item: device?.item,
      item_description: device?.item_description,
      id_size: device?.id_size || orgSettings.default_id_size,
      id_product: device?.id_product,
      id_location: device?.id_location,
      shared: device?.shared,
      status: device ? device.status : 'available',
    },
  })

  const mode = methods.watch('mode')

  const selectedLocation = methods.watch('id_location')

  const lockerNumbers = useMemo(
    () =>
      selectedLocation
        ? allDevices
            .filter((device) => device?.location?.id === selectedLocation)
            .map((device) => device.locker_number)
        : [],
    [selectedLocation],
  )

  const fetchSizes = async () => {
    try {
      const sizes = await getSizes(1, 10000)
      setSizes(
        sizes.items.map((size) => ({
          value: size.id,
          label: size.name,
        })),
      )
    } catch (error) {
      displayMessage?.((error as Error).message)
    }
  }

  const fetchLocations = async () => {
    try {
      setLoadingLocations(true)
      const locations = await getLocations(1, 10000)
      setLocations(
        locations.items.map((size) => ({
          value: size.id,
          label: size.name,
        })),
      )
    } catch (error) {
      displayMessage?.((error as Error).message)
    } finally {
      setLoadingLocations(false)
    }
  }

  const getFormTitle = useCallback(() => {
    if (device && allowEdit && isSuperTenantOrg) {
      return 'Edit Device'
    } else if (!device && allowEdit) {
      return 'Add Device'
    } else if (device && device.shared && !isSuperTenantOrg)
      return 'Device Details'
    else return 'Device Details'
  }, [isSuperTenantOrg])

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }

  const fetchUsers = async (): Promise<void> => {
    try {
      const users = await getUsers(1, 10000, '')
      setUsers(users.items)
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    }
  }

  const fetchGroups = async (): Promise<void> => {
    try {
      const groups = await getGroups(1, 10000, '')
      setGroups(groups.items)
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    }
  }

  const fetchProducts = async (): Promise<void> => {
    try {
      // Set loading state to avoid clicking on empty Autocompletes that
      // get data a second later.
      // This way, we avoid (for example) the "products" array being [] and then
      // getting its payload.
      setIsLoadingProducts(true)
      const products = await getProducts(1, 10000)
      setProducts(products.items)
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setIsLoadingProducts(false)
    }
  }

  const fetchProductGroups = async (): Promise<void> => {
    try {
      setLoading(true)
      const productGroups = await getProductGroups(1, 1000)
      setProductGroups(productGroups.items)
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const handlePriceRequiredChange = (e: any) => {
    setPriceRequired(e.target.checked)
  }

  const handleImageClick = () => {
    document.getElementById('image')?.click()
  }

  const handleProductSelection = (event: React.SyntheticEvent, value: any) => {
    setSelectedProduct(value)
  }

  const handleProductsListClick = () => {
    if (products.length === 0 && !isLoadingProducts) {
      setConfirmationMessage({
        isOpen: true,
        message: 'There are no products. Do you want to add a new product now?',
        type: 'products',
      })
    }
  }

  const handleToggle = (event: React.SyntheticEvent, value: any) => {
    try {
      const selectedResources: string[] = value.map(
        (option: DropdownOption) => option.value,
      )
      if (assignType === 'user') {
        setUsersToAdd([...selectedResources])
      } else if (assignType === 'group') {
        setGroupsToAdd(selectedResources)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const buildUserOptions = () => {
    if (users.length > 0) {
      const notAssignedUsers = users.filter(
        (user) => !device?.restriction?.items.some((u) => u.id === user.id),
      )
      const autoCompleteOptions = notAssignedUsers.map((user) => ({
        label: `${user.name || ''}${user.name && ' '}(${
          user.email || user.phone_number
        })`,
        value: user.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setOptions(sortedOptions)
    }
  }

  const buildGroupOptions = () => {
    if (groups.length > 0) {
      const notAssignedGroups = groups.filter(
        (group) => !device?.restriction?.items.some((g) => g.id === group.id),
      )
      const autoCompleteOptions = notAssignedGroups.map((group) => ({
        label: group.name,
        value: group.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setOptions([...new Set(sortedOptions)])
    }
  }

  const handleRestrictionChange = (newRestriction: string) => {
    if (
      assignType === 'user' &&
      (usersToAdd.length > 0 ||
        (device &&
          device.restriction &&
          device.restriction.items?.length > 0)) &&
      newRestriction === 'group'
    ) {
      displayMessage?.(
        'You have selected/assigned users to this location, please unselect/remove them if you want to change the restriction type.',
        'warning',
      )
      return
    } else if (
      assignType === 'group' &&
      (groupsToAdd.length > 0 ||
        (device &&
          device.restriction &&
          device?.restriction?.items.length > 0)) &&
      newRestriction === 'user'
    ) {
      displayMessage?.(
        'You have selected/assigned groups to this location, please unselect/remove them if you want to change the restriction type.',
        'warning',
      )
      return
    } else if (newRestriction !== assignType) {
      setAssignType(newRestriction)
    }
  }

  const assignUsers = async (deviceId: string) => {
    const promises = usersToAdd.map(
      async (userId) => await assign(userId, deviceId, 'device'),
    )
    await Promise.all(promises)
  }

  const assignGroups = async (deviceId: string) => {
    const promises = groupsToAdd.map(
      async (groupId) => await assignGroup(deviceId, groupId, 'device'),
    )
    await Promise.all(promises)
  }

  const unassignUsers = async (ids: string[] | null = null) => {
    if (device) {
      const resourcesToUnassign = ids || resourcesToDelete
      const promises = resourcesToUnassign.map(
        async (userId) => await unassign(userId, device?.id, 'device'),
      )
      await Promise.all(promises)
    }
  }

  const unassignGroups = async (ids: string[] | null = null) => {
    if (device) {
      const resourcesToUnassign = ids || resourcesToDelete
      const promises = resourcesToUnassign.map(
        async (groupId) => await unassignGroup(device?.id, groupId, 'device'),
      )
      await Promise.all(promises)
    }
  }

  const handleSelect = (resourceId: string) => {
    if (resourcesToDelete.includes(resourceId)) {
      setResourcesToDelete(resourcesToDelete.filter((id) => id !== resourceId))
    } else {
      setResourcesToDelete((prevState) => [...prevState, resourceId])
    }
  }

  const buildProductsOptions = () => {
    if (products.length > 0) {
      const autoCompleteOptions = products
        .filter(
          (product) =>
            !allDevices.some((device) => device?.product?.id === product.id),
        )
        .map((product) => ({
          label: product.name,
          value: product.id,
          serial_number: product.serial_number,
          description: product.description,
        }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setProductsOptions(sortedOptions)
    }
  }

  const handleConfirmAction = () => {
    switch (confirmationMessage.type) {
      case 'sizes':
        setOpenSizesSidebar(true)
        return
      case 'locations':
        setOpenLocationsSidebar(true)
        return
      default:
        return
    }
  }

  const onSubmit = async (newDevice: IDevice): Promise<void> => {
    try {
      if (
        lockerNumberExists &&
        newDevice.locker_number !== device?.locker_number
      ) {
        displayMessage?.(
          'This Locker Number is already in use. Please try another one.',
          'error',
        )
        return
      }
      setLoading(true)
      let deviceResult: IDevice
      if (device) {
        // unassign users or groups to device
        if (
          !isRestricted &&
          device.restriction &&
          device.restriction?.items.length > 0
        ) {
          if (device.restriction?.restriction_type === 'users') {
            await unassignUsers(device.restriction.items.map((user) => user.id))
          } else {
            await unassignGroups(
              device.restriction.items.map((group) => group.id),
            )
          }
        }
        if (resourcesToDelete.length > 0) {
          if (assignType === 'user') {
            await unassignUsers()
          } else if (assignType === 'group') {
            await unassignGroups()
          }
        }

        const result = await update(
          formatDevicePayload({
            ...newDevice,
            image,
            price_required: priceRequired,
            id_price: priceRequired ? newDevice.id_price : null,
            id_product:
              mode === 'rental' || mode === 'vending'
                ? selectedProduct?.value
                : assignedProduct?.value,
          }),
          device?.id,
        )
        deviceResult = result.result

        // assign users or groups to device
        if (assignType === 'user' && usersToAdd.length > 0) {
          await assignUsers(deviceResult.id)
        } else if (assignType === 'group' && groupsToAdd.length > 0) {
          await assignGroups(deviceResult.id)
        }
        onClose()
        setSelectedProduct(null)
        displayMessage?.('Device updated successfully', 'success')
        success?.()
      } else {
        const result = await create(
          formatDevicePayload({
            ...newDevice,
            image,
            price_required: priceRequired,
            id_price: priceRequired ? newDevice.id_price : null,
            id_product:
              mode === 'rental' || mode === 'vending'
                ? selectedProduct?.value
                : assignedProduct?.value,
          }),
        )

        deviceResult = result.result
        // assign users or groups to device
        if (assignType === 'user' && usersToAdd.length > 0) {
          await assignUsers(deviceResult.id)
        } else if (assignType === 'group' && groupsToAdd.length > 0) {
          await assignGroups(deviceResult.id)
        }
        onClose()
        setSelectedProduct(null)
        displayMessage?.('Device created successfully', 'success')
        success?.(true)
      }
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (assignType === 'user') {
      buildUserOptions()
    } else {
      buildGroupOptions()
    }
  }, [users, groups])

  useEffect(() => {
    if (isRestricted) {
      if (assignType === 'user') {
        fetchUsers()
      } else {
        fetchGroups()
      }
    }
  }, [isRestricted, assignType])

  useEffect(() => {
    if (!isRestricted) {
      setUsersToAdd([])
      setGroupsToAdd([])
    }
  }, [isRestricted])

  useEffect(() => {
    buildProductsOptions()
  }, [products])

  useEffect(() => {
    const filter = priceOptions.filter(
      (f) => f.value == methods.watch('id_price'),
    )

    filter.length === 0
      ? setPriceType('')
      : setPriceType(filter[0].priceType || '')
  }, [methods.watch('id_price')])

  useEffect(() => {
    if (!priceRequired && methods.watch('id_price')) {
      // @ts-ignore
      methods.setValue('id_price', '')
    }
  }, [priceRequired])

  useEffect(() => {
    if (mode === 'rental' || mode === 'vending') {
      fetchProducts()
      fetchProductGroups()
    }
    if (mode !== 'rental') {
      methods.setValue('require_image', false)
      return
    }
  }, [mode])

  useEffect(() => {
    setWrongPriceTypeForServiceError(false)

    const [filteredPrice] = priceOptions.filter(
      (p) => p.value === methods.watch('id_price'),
    )

    if (!filteredPrice) return

    if (mode === 'service' && filteredPrice.priceType !== 'pay_per_weight') {
      setWrongPriceTypeForServiceError(true)
    }
  }, [methods.watch('id_price')])

  const lockerNumberExists =
    String(methods.watch('locker_number')) !== '' &&
    lockerNumbers &&
    lockerNumbers.includes(+methods.watch('locker_number')) &&
    allDevices.some(
      (device) =>
        device.mode === mode &&
        device.locker_number === +methods.watch('locker_number'),
    )

  const disableSubmit =
    Object.keys(methods.formState.errors).length > 0 || wrongPriceTypeForService

  const isReadOnly = Boolean(!allowEdit)

  const DeviceFormElement = (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit}>
        <FormWrapper title={getFormTitle()}>
          {isReadOnly && (
            <Typography
              sx={{
                position: 'absolute',
                top: '2em',
                left: '2em',
                cursor: 'pointer',
              }}
              onClick={() => {
                onClose()
                setSelectedProduct(null)
              }}
            >
              ◀
            </Typography>
          )}

          <TextField
            name="name"
            label="Name"
            placeholder="Name"
            rules={{ required: 'Name required' }}
            disabled={isReadOnly}
          />

          <TextField
            name="locker_number"
            label="Locker Number"
            placeholder="0"
            rules={{ required: 'Locker number required' }}
            onlyInteger={true}
            disabled={isReadOnly}
          />
          {lockerNumberExists &&
            device?.locker_number !==
              Number(methods.watch('locker_number')) && (
              <Typography
                sx={{
                  color: '#ff0000',
                  fontSize: '0.9rem',
                  fontWeight: 'bold',
                  margin: '0.1rem',
                }}
              >
                This Locker Number is already in use at this location in{' '}
                {mode === 'rental' ? 'Asset' : capitalizeFirstLetter(mode)}{' '}
                mode. Please try another one.
              </Typography>
            )}

          <SelectField
            name="mode"
            label="Mode"
            items={modes}
            rules={{ required: 'Mode required' }}
            disabled={isReadOnly}
          />

          {mode !== 'vending' && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={handlePriceRequiredChange}
                    checked={priceRequired}
                    disabled={isReadOnly}
                  />
                }
                label="Price required"
              />
              {priceRequired && (
                <>
                  <SelectField
                    name="id_price"
                    label="Price"
                    items={
                      priceOptions.filter((p) => ({
                        label: p.label,
                        value: p.value,
                      })) || []
                    }
                    rules={{
                      required: 'Price required',
                    }}
                    disabled={isReadOnly}
                  />

                  {wrongPriceTypeForService && (
                    <Typography
                      sx={{
                        color: '#ff0000',
                        fontSize: '0.9rem',
                        fontWeight: 'bold',
                        margin: '0.1rem',
                      }}
                    >
                      Service mode can only use pay-per-weight price types.
                    </Typography>
                  )}
                </>
              )}
            </>
          )}

          {!priceRequired &&
            methods.watch('id_price') &&
            mode !== 'service' &&
            priceType === 'pay_per_weight' && (
              <Typography
                sx={{
                  color: 'red',
                }}
              >
                This device must be set to mode &quot;Service&quot; to charge by
                weight
              </Typography>
            )}

          {device && device.image && (
            <>
              <Typography>
                <b>Device Image:</b>
              </Typography>
              <Image src={device?.image ?? ''} alt="" />
            </>
          )}

          {/* Asset Mode & Vending Mode*/}
          <RentalModeFields
            device={device}
            allowEdit={!isReadOnly}
            products={products}
            productsOptions={productsOptions}
            selectedProduct={selectedProduct}
            loading={isLoadingProducts}
            handleProductSelection={handleProductSelection}
            handleProductsListClick={handleProductsListClick}
          />

          {mode !== 'rental' && mode !== 'vending' && (
            <SelectField
              name="id_size"
              label="Size"
              items={sizes}
              rules={{ required: 'Size required' }}
              disabled={isReadOnly}
              defaultValue={orgSettings.default_id_size}
              onOpen={
                sizes.length === 0
                  ? () => {
                      setConfirmationMessage({
                        isOpen: true,
                        message:
                          'There are no sizes to assign to this device. Do you want to create sizes now? ',
                        type: 'sizes',
                      })
                      return {}
                    }
                  : undefined
              }
            />
          )}

          <SelectField
            name="id_location"
            label="Location"
            items={locations.sort((a, b) => {
              if (
                a.label.toLocaleLowerCase().replace("'", '') <
                b.label.toLocaleLowerCase().replace("'", '')
              ) {
                return -1
              }
              if (
                a.label.toLocaleLowerCase().replace("'", '') >
                b.label.toLocaleLowerCase().replace("'", '')
              ) {
                return 1
              }
              return 0
            })}
            rules={{ required: 'Location required' }}
            disabled={isReadOnly}
            onOpen={
              locations.length === 0
                ? () => {
                    setConfirmationMessage({
                      isOpen: true,
                      message:
                        'There are no locations to assign to this device. Do you want to create locations now? ',
                      type: 'locations',
                    })
                    return {}
                  }
                : undefined
            }
          />
          <SelectField
            name="status"
            label="Status"
            items={DEVICE_STATUSES}
            rules={{ required: 'Status required' }}
            disabled={isReadOnly}
          />
          <HardwareTypeFields
            allowEdit={!isReadOnly}
            isOjmarOrg={isOjmarOrg}
            isGantnerOrg={isGantnerOrg}
            loadDefaultHardware={device ? false : true}
          />

          {/* Remove for all modes except rental: */}
          {mode === 'rental' && (
            <FormControlLabel
              control={
                <Switch
                  disabled={isReadOnly}
                  defaultChecked={device?.require_image}
                  {...methods.register('require_image')}
                />
              }
              label="Require image to end transaction"
            />
          )}

          {allowEdit && (
            <>
              <Help helpText="This allows the type of access for the location. Restricted will have a limit of access for the location whereas Unrestricted will have unlimited access for the location">
                <Typography variant="h5">Assign access</Typography>
              </Help>
              <ToggleSwitch
                leftItem={{
                  label: 'Unrestricted',
                  onClick: () => setIsRestricted(false),
                  selected: !isRestricted,
                }}
                rightItem={{
                  label: 'Restricted',
                  onClick: () => setIsRestricted(true),
                  selected: isRestricted,
                }}
              />
            </>
          )}

          {isRestricted && !isReadOnly && (
            <>
              <Typography variant="h5">
                How would you like to assign access?
              </Typography>
              <ToggleSwitch
                leftItem={{
                  label: 'Users',
                  onClick: () => handleRestrictionChange('user'),
                  selected: assignType === 'user',
                }}
                rightItem={{
                  label: 'Groups',
                  onClick: () => handleRestrictionChange('group'),
                  selected: assignType === 'group',
                }}
              />
            </>
          )}

          {isRestricted && assignType && !isReadOnly && (
            <Autocomplete
              disabled={isReadOnly}
              onChange={handleToggle}
              multiple
              options={options}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <MUITextField
                  {...params}
                  label={`Search ${
                    assignType === 'user' ? 'users' : 'groups'
                  }...`}
                />
              )}
              loading={loadingResources}
              loadingText={`Loading ${
                assignType === 'user' ? 'users' : 'groups'
              }...`}
              noOptionsText={`No ${
                assignType === 'user' ? 'users' : 'groups'
              } available`}
            />
          )}

          {isRestricted && assignType && device && (
            <>
              <Typography
                variant="h5"
                sx={{
                  textAlign: 'left',
                }}
              >
                {assignType === 'user' ? 'Users' : 'Groups'} assigned to this
                device
              </Typography>
              {assignType === 'user' && (
                <Typography variant="caption" color="rgb(173, 176, 187)">
                  {device.restriction && device.restriction.items.length > 0
                    ? `Select the users that you want to remove from this device and hit submit`
                    : `No users assigned to this device.`}
                </Typography>
              )}
              {assignType === 'group' && (
                <Typography variant="caption" color="rgb(173, 176, 187)">
                  {device.restriction && device.restriction.items.length > 0
                    ? `Select the groups that you want to remove from this device and hit submit`
                    : `No groups assigned to this device.`}
                </Typography>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  gap: '0.75rem',
                  textAlign: 'center',

                  width: '100%',
                }}
              >
                {device.restriction &&
                  device.restriction.items.length > 0 &&
                  assignType === 'group' &&
                  device?.restriction?.items.map((group) => (
                    <Chip
                      key={group.id}
                      label={group.name}
                      onClick={() => handleSelect(group.id)}
                      onDelete={() => handleSelect(group.id)}
                      deleteIcon={
                        resourcesToDelete.includes(group.id) ? (
                          <DoneIcon />
                        ) : (
                          <DeleteIcon />
                        )
                      }
                      variant={
                        resourcesToDelete.includes(group.id)
                          ? 'filled'
                          : 'outlined'
                      }
                      color={
                        resourcesToDelete.includes(group.id)
                          ? 'primary'
                          : 'default'
                      }
                    />
                  ))}
                {device.restriction &&
                  device.restriction.items.length > 0 &&
                  assignType === 'user' &&
                  device?.restriction?.items.map((user) => (
                    <Chip
                      key={user.id}
                      label={`${user.name || ''} (${
                        user.email || user.phone_number
                      })`}
                      onClick={() => handleSelect(user.id)}
                      onDelete={() => handleSelect(user.id)}
                      deleteIcon={
                        resourcesToDelete.includes(user.id) ? (
                          <DoneIcon />
                        ) : (
                          <DeleteIcon />
                        )
                      }
                      variant={
                        resourcesToDelete.includes(user.id)
                          ? 'filled'
                          : 'outlined'
                      }
                      color={
                        resourcesToDelete.includes(user.id)
                          ? 'primary'
                          : 'default'
                      }
                    />
                  ))}
              </Box>
            </>
          )}

          {!isReadOnly && (
            <FormActions
              onClose={() => {
                onClose()
                setSelectedProduct(null)
              }}
              loading={loading}
              disableSubmit={disableSubmit}
            />
          )}
        </FormWrapper>
      </Form>
    </FormProvider>
  )

  if (isLoadingModes || isLoadingLocations) {
    return <LoadingFormData />
  }

  return (
    <>
      {device && (
        <Tabs
          tabs={[
            {
              label: 'Edit Device',
              value: 'form',
              children: DeviceFormElement,
            },
            {
              label: 'Device History Log',
              value: 'history',
              children: <DeviceHistory device={device} />,
            },
          ]}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}
      {getUserRole() !== 'admin' && !device && DeviceFormElement}
      {getUserRole() === 'admin' && !device && (
        <Tabs
          tabs={[
            {
              label: 'Individual Upload',
              value: 'form',
              children: DeviceFormElement,
            },
            {
              label: 'Bulk Upload',
              value: 'BulkUpload',
              children: (
                <BulkUploadEntity
                  entity="devices"
                  successForm={() => success?.()}
                  onClose={() => {
                    onClose()
                    setSelectedProduct(null)
                  }}
                  displayMessage={displayMessage}
                />
              ),
            },
          ]}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}

      <SideBar
        open={openSizesSidebar}
        onClose={() => setOpenSizesSidebar(false)}
      >
        <SizeForm
          size={undefined}
          success={fetchSizes}
          onClose={() => setOpenSizesSidebar(false)}
          displayMessage={displayMessage}
        />
      </SideBar>

      <SideBar
        open={openProductsSidebar}
        onClose={() => setOpenProductsSidebar(false)}
      >
        <ProductForm
          product={undefined}
          groups={productGroups}
          success={fetchProducts}
          onClose={() => setOpenProductsSidebar(false)}
          displayMessage={displayMessage}
        />
      </SideBar>

      <SideBar
        open={openLocationsSidebar}
        onClose={() => setOpenLocationsSidebar(false)}
      >
        <LocationForm
          location={undefined}
          success={fetchLocations}
          onClose={() => setOpenLocationsSidebar(false)}
          displayMessage={displayMessage}
        />
      </SideBar>

      <ConfirmDialog
        open={
          confirmationMessage.isOpen && confirmationMessage.type === 'sizes'
        }
        message={confirmationMessage.message}
        onClose={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
            type: '',
          })
        }
        onClickConfirm={() => {
          handleConfirmAction()
          setConfirmationMessage({
            isOpen: false,
            message: '',
            type: '',
          })
        }}
        onClickCancel={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
            type: '',
          })
        }
        confirmText={
          confirmationMessage.type === 'sizes' ? 'Add Sizes' : 'Add Locations'
        }
        cancelText="Cancel"
      />

      <ConfirmDialog
        open={
          confirmationMessage.isOpen && confirmationMessage.type === 'products'
        }
        message={confirmationMessage.message}
        onClose={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
            type: '',
          })
        }
        onClickConfirm={() => {
          setOpenProductsSidebar(true)
          setConfirmationMessage({
            isOpen: false,
            message: '',
            type: '',
          })
        }}
        onClickCancel={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
            type: '',
          })
        }
        confirmText="Add Product"
        cancelText="Cancel"
      />
    </>
  )
}

export default DeviceForm
