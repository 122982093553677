import { ISignIn, PublicOrg } from 'models'
import { LogoIcon } from 'layouts'
import { Link } from 'react-router-dom'
import { SignInStyles } from './styles'
import { Koloni } from 'assets'
import { useState, useEffect } from 'react'
import { organizationApi, useAuth } from 'resources'
import CancelIcon from '@mui/icons-material/Cancel'
import { Grid, Box, Typography, CircularProgress } from '@mui/material'
import { PageContainer, SignInForm, Image } from 'components'
import { SIGN_IN_PAGE } from '../../constants'
import { getOrganizationFromHost } from 'utils/helpers'

const SignIn = () => {
  const { STYLES, SX } = SignInStyles()

  const [err, setErr] = useState<string | null>(null)

  const [org, setOrg] = useState<PublicOrg | undefined>()

  const { signIn, configureAmplify } = useAuth()
  const { getOrgDetailsPublic } = organizationApi()

  const fetchPublicOrgData = async () => {
    const orgName = getOrganizationFromHost()
    const orgData = await getOrgDetailsPublic(orgName)
    setOrg(orgData)
  }

  const initAmplify = async () => {
    configureAmplify()
  }

  initAmplify()

  useEffect(() => {
    fetchPublicOrgData()
  }, [])

  const onSignIn = async ({ email, password }: ISignIn): Promise<void> => {
    setErr(null)
    try {
      await signIn(email.trim(), password, org)
    } catch (error: any) {
      setErr(error.message)
    }
  }

  return (
    <PageContainer
      title={SIGN_IN_PAGE.TITLE}
      description={SIGN_IN_PAGE.DESCRIPTION}
    >
      <Grid container spacing={0} sx={SX.GRID_CONTAINER}>
        <Grid item xs={12} sm={12} lg={6} sx={SX.GRID_ITEM}>
          <Box sx={SX.BOX_LOGO}>
            <LogoIcon />
          </Box>
          <Box sx={SX.BOX_IMAGE_CONTAINER}>
            <Box sx={SX.BOX_IMAGE}>
              {!org ? (
                <CircularProgress />
              ) : (
                <Image
                  src={org?.app_logo || Koloni}
                  alt="logo"
                  style={{ width: 400, height: 250 }}
                  isLogo={true}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} lg={6} display="flex" alignItems="center">
          <Grid container spacing={0} display="flex" justifyContent="center">
            <Grid item xs={12} lg={9} xl={6}>
              <Box sx={SX.BOX_WELCOME}>
                <Typography fontWeight="700" variant="h2">
                  {SIGN_IN_PAGE.WELCOME}
                  {org ? `${org.name}` : ''}
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: '0 2rem',
                  display: 'flex',
                }}
              >
                {err && (
                  <>
                    <CancelIcon
                      sx={{
                        color: 'error.main',
                        marginRight: '0.5rem',
                      }}
                    />
                    <Typography color="error">{err}</Typography>
                  </>
                )}
              </Box>

              <SignInForm onSignIn={onSignIn}>
                <Box sx={SX.RESET_LINK_CONTAINER}>
                  <Typography>
                    {SIGN_IN_PAGE.FORGOT_PASSWORD}
                    <Link to="/auth/reset-password" style={STYLES.RESET_LINK}>
                      {SIGN_IN_PAGE.CHANGE_PASSWORD}
                    </Link>
                  </Typography>
                </Box>
              </SignInForm>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  )
}
export default SignIn
