import { Navigate } from 'react-router-dom'
import {
  Dashboard,
  SignIn,
  SignUp,
  Error,
  ResetPassword,
  SetNewPassword,
  Users,
  Issues,
  Notifications,
  Locations,
  Groups,
  Devices,
  Sizes,
  Inventory,
  ProductGroups,
  TeamMembers,
  Reporting,
} from '../pages'
import { Authenticated } from 'components'
import { FullLayout, BlankLayout } from '../layouts'
import { Pricing } from 'pages/Pricing'
import FinancialSettings from 'pages/Pricing/components/FinancialSettings'
import { Settings } from 'pages/Settings'
import ProtectedRoute from 'resources/auth/guards/permission/PermissionGuard'
import { getUserRole } from 'utils/helpers'
import { LockerWall } from 'pages/LockerWall'
import Conditions from 'pages/Products/components/conditions/Conditions'
import Reservations from 'pages/Events/components/reservations/Reservations'
import Transactions from 'pages/Events/components/transactions/Transactions'
import { RootStateOrAny, useSelector } from 'react-redux'
import { Membership } from 'pages/Events'
import useIsSuperTenantOrg from 'hooks/useIsSuperTenantOrg'
import Organization from 'pages/Organization/Organization'
import APIs from "pages/Toolbox/API's/APIs"
import AppsAndWidgets from 'pages/Toolbox/Apps&Widgets/AppsAndWidgets'
import Integrations from 'pages/Toolbox/Integrations/Integrations'

const Router = () => {
  const lastLocation = useSelector(
    (state: RootStateOrAny) => state.lastLocationReducer.lastLocationId,
  )
  const isSuperTenantOrg = useIsSuperTenantOrg()

  const routes = [
    {
      path: '/',
      exact: true,
      element: <Navigate to="/home" />,
    },
    {
      path: 'auth',
      element: <BlankLayout />,
      children: [
        { path: '404', element: <Error /> },
        {
          path: 'login',
          element: <SignIn />,
        },
        {
          path: 'register',
          element: <SignUp />,
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'welcome', element: <SetNewPassword /> },
        { path: '*', element: <Navigate to="/auth/404" /> },
      ],
    },
    {
      path: '/',
      element: (
        <Authenticated>
          <FullLayout />
        </Authenticated>
      ),
      children: [
        {
          path: '/home',
          exact: true,
          element: <Dashboard />,
        },
        {
          path: '/reporting',
          exact: true,
          element: <Reporting />,
        },
        {
          path: '/pricing-set-up',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <FinancialSettings />
            </ProtectedRoute>
          ),
        },
        {
          path: '/pay-per',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Pricing identifier="pay-per" />
            </ProtectedRoute>
          ),
        },
        {
          path: '/subscriptions',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Pricing identifier="subscriptions" />
            </ProtectedRoute>
          ),
        },
        {
          path: '/promo-codes',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Pricing identifier="promo-codes" />
            </ProtectedRoute>
          ),
        },
        {
          path: '/locations',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Locations />
            </ProtectedRoute>
          ),
        },
        {
          path: '/devices',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Devices />
            </ProtectedRoute>
          ),
        },
        {
          path: '/sizes',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Sizes />
            </ProtectedRoute>
          ),
        },
        {
          path: '/inventory',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Inventory />
            </ProtectedRoute>
          ),
        },
        {
          path: '/product-groups',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <ProductGroups />
            </ProtectedRoute>
          ),
        },
        {
          path: '/conditions',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Conditions />
            </ProtectedRoute>
          ),
        },
        {
          path: '/users',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Users />
            </ProtectedRoute>
          ),
        },
        {
          path: '/groups',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Groups />
            </ProtectedRoute>
          ),
        },
        {
          path: '/team-members',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <TeamMembers />
            </ProtectedRoute>
          ),
        },
        {
          path: '/transactions',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Transactions />
            </ProtectedRoute>
          ),
        },
        {
          path: '/reservations',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Reservations />
            </ProtectedRoute>
          ),
        },
        {
          path: '/subscribers',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Membership />
            </ProtectedRoute>
          ),
        },
        {
          path: '/settings',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Settings />
            </ProtectedRoute>
          ),
        },
        {
          path: '/locker-wall',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <LockerWall lastLocation={lastLocation} />
            </ProtectedRoute>
          ),
        },
        {
          path: '/issues',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Issues />
            </ProtectedRoute>
          ),
        },
        {
          path: '/notifications',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() === 'viewer'}>
              <Notifications />
            </ProtectedRoute>
          ),
        },
        {
          path: '/multi-tenant',
          exact: true,
          element: (
            <ProtectedRoute restricted={getUserRole() !== 'admin'}>
              <Organization />
            </ProtectedRoute>
          ),
        },
        {
          path: '/apis',
          exact: true,
          element: (
            <ProtectedRoute
              restricted={
                getUserRole() === 'viewer' ||
                process.env.REACT_APP_CURRENT_ENV === 'production'
              }
            >
              <APIs />
            </ProtectedRoute>
          ),
        },
        {
          path: '/apps-widgets',
          exact: true,
          element: (
            <ProtectedRoute
              restricted={
                getUserRole() === 'viewer' ||
                process.env.REACT_APP_CURRENT_ENV === 'production'
              }
            >
              <AppsAndWidgets />
            </ProtectedRoute>
          ),
        },
        {
          path: '/integrations',
          exact: true,
          element: (
            <ProtectedRoute
              restricted={
                getUserRole() === 'viewer' ||
                process.env.REACT_APP_CURRENT_ENV === 'production'
              }
            >
              <Integrations />
            </ProtectedRoute>
          ),
        },

        { path: '*', element: <Navigate to="/auth/404" /> },
      ],
    },
  ]

  return routes
}
export default Router
