import { TableRow as MUITableRow, TableRowProps, useTheme } from '@mui/material'
import { useState } from 'react'
import { parseHexToRGB } from 'utils/helpers'

interface ITableRowProps extends TableRowProps {}

const TableRow = ({ children, sx }: ITableRowProps) => {
  const [isHover, setIsHover] = useState<boolean>(false)

  const theme = useTheme()

  return (
    <MUITableRow
      sx={
        sx
          ? { ...sx }
          : {
              background: isHover
                ? parseHexToRGB(theme.palette.primary.main, '0.25')
                : undefined,
            }
      }
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {children}
    </MUITableRow>
  )
}
export default TableRow
