import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IProduct, ProductTrackingState } from 'models/Product'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { SideBar } from 'components'
import { useState } from 'react'
import UserHistory from 'pages/People/components/users/UserHistory'
import { IUser } from 'models'

interface IProductTrackingProps {
  product: IProduct
}

const ProductTracking = ({ product }: IProductTrackingProps) => {
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null)
  const theme = useTheme()

  const buildLogText = (state: ProductTrackingState) => {
    switch (state) {
      case 'new':
        return 'Product Created'
      case 'incoming':
        return 'In'
      case 'outgoing':
        return 'Out'
      case 'maintenance':
        return 'Maintenance Ticket'
    }
  }

  // Get the creation log in order to display it on top of the Tracking Log
  const creationLog = product.product_tracking.find(
    (log) => log.state === 'new',
  )

  // Sort the rest of the logs by creation date
  const logs = product.product_tracking
    .filter((log) => log.state !== 'new')
    .sort((a, b) => (new Date(a.created_at) > new Date(b.created_at) ? 1 : -1))

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Asset History Log</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ overflowX: 'auto', padding: 0 }}>
          <Timeline
            sx={{
              fontSize: '8px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              height: 'auto',
              width: 'auto',
              padding: 0,
            }}
          >
            {creationLog && (
              <TimelineItem
                sx={{
                  minHeight: '60px',
                  width: '100% !important',
                  '&::before': {
                    flex: 'none !important',
                  },
                }}
              >
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector sx={{ height: '40px' }} />
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    whiteSpace: 'nowrap',
                    color: theme.palette.primary.main,
                    display: 'flex',
                    justifyContent: 'justify-content',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: '0.75rem', width: '100%' }}>
                    <Typography>{buildLogText(creationLog.state)}</Typography>
                  </Box>
                  <Box>
                    <Typography>
                      {new Date(creationLog.created_at).toLocaleString(
                        'en-US',
                        {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        },
                      )}
                    </Typography>
                  </Box>
                </TimelineContent>
              </TimelineItem>
            )}
            {logs.map((log, index) => (
              <TimelineItem
                key={index}
                sx={{
                  minHeight: '60px',
                  width: '100% !important',
                  '&::before': {
                    flex: 'none !important',
                  },
                }}
              >
                <TimelineSeparator>
                  <TimelineDot
                    color={log.state === 'maintenance' ? 'error' : undefined}
                  />
                  <TimelineConnector sx={{ height: '40px' }} />
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    whiteSpace: 'nowrap',
                    color: log.state === 'maintenance' ? '#F02607' : undefined,
                    display: 'flex',
                    justifyContent: 'justify-content',
                    width: '100% !important',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: '0.75rem' }}>
                      <Typography>{buildLogText(log.state)}</Typography>
                      {(log.state === 'incoming' ||
                        log.state === 'outgoing') && (
                        <Typography
                          color="primary"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => setSelectedUser(log.user)}
                        >
                          {log.user?.name}
                        </Typography>
                      )}
                    </Box>
                    {log.condition && (
                      <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                        <Typography fontSize={12}>
                          Product condition:
                        </Typography>

                        <Typography fontSize={12} color="primary">
                          {log.condition.name}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box>
                    <Typography>
                      {new Date(log.created_at).toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </Typography>
                  </Box>
                </TimelineContent>
              </TimelineItem>
            ))}
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
              </TimelineSeparator>
            </TimelineItem>
          </Timeline>
        </AccordionDetails>
      </Accordion>
      {selectedUser && (
        <SideBar
          open={Boolean(selectedUser)}
          onClose={() => {
            setSelectedUser(null)
          }}
        >
          <UserHistory
            user={selectedUser}
            goBackCallback={() => {
              setSelectedUser(null)
            }}
          />
        </SideBar>
      )}
    </>
  )
}

export default ProductTracking
