import { Box, Typography } from '@mui/material'
import {
  Button,
  NotificationDialog,
  SideBar,
  Table,
  TableBody,
  TablePagination,
} from 'components'
import { DEFAULT_PAGE, ITEMS_PER_PAGE } from 'constants/pagination'
import {
  IPrice,
  IPriceList,
  IPromo,
  IPromoList,
  ISubscription,
  ISubscriptionList,
} from 'models'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { membershipApi, organizationApi, priceApi, promoApi } from 'resources'
import ItemRow from './components/ItemRow'
import PriceForm from './components/PriceForm'
import PromoForm from './components/PromoForm'
import useDialog from 'hooks/useDialog'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import useSortableHeader from 'hooks/useSortableHeader'
import useColumnFiltering from 'hooks/useColumnFiltering'
import { getOrganizationFromHost } from 'utils/helpers'
import { useNavigate } from 'react-router'
import NoData from 'components/PageBase/NoData'
import LoadingData from 'components/PageBase/LoadingData'
import SubscriptionForm from './components/SubscriptionForm'
import { Toolbar } from 'components/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import { ToolbarControl } from 'types'
import useLoadingState from 'hooks/useLoadingState'

interface IPricingProps {
  identifier: 'pay-per' | 'subscriptions' | 'promo-codes'
}

const Pricing = ({ identifier }: IPricingProps) => {
  const [data, setData] = useState<IPriceList | ISubscriptionList | IPromoList>(
    {
      items: [],
      total: 0,
      pages: 0,
    },
  )
  const [dataBackup, setDataBackup] = useState<
    IPrice[] | ISubscription[] | IPromo[]
  >([])
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE)
  const [currentItem, setCurrentItem] = useState<
    IPrice | ISubscription | IPromo | undefined
  >()
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [search, setSearch] = useState('')

  const [isStripeEnabled, setIsStripeEnabled] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const { dialog, displayMessage, closeDialog } = useDialog()
  const navigate = useNavigate()

  const { getMany: getPrices, remove: deletePrice } = priceApi()
  const { getMany: getSubscriptions, remove: deleteMembership } =
    membershipApi()
  const { getMany: getPromos, remove: deletePromo } = promoApi()
  const { getOrgDetailsPublic } = organizationApi()

  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'name',
      entity: identifier,
      nestedProps: [],
    })
  const { filteredColumns, setFilteredColumns, getDefaultFilters } =
    useColumnFiltering({ displayMessage })

  const { loading, setLoading } = useLoadingState(true)

  const defaultPriceExists = useMemo(() => {
    if (identifier === 'pay-per') {
      return (data as IPriceList).items.some((price) => price.default === true)
    }
    return false
  }, [, identifier, data])

  const addButtonText = useMemo(() => {
    switch (identifier) {
      case 'pay-per':
        return 'Add Price'
      case 'subscriptions':
        return 'Add Subscription'
      case 'promo-codes':
        return 'Add Promo'
    }
  }, [identifier])

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => {
    console.log(event, checked, id)
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, id]
        : previousValue.filter((rowId) => rowId !== id),
    )
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() =>
      checked ? data.items.map((device) => device.id) : [],
    )
  }

  const handleDeleteMany = async () => {
    try {
      await deleteMany()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const fetchData = useCallback(async () => {
    if (identifier === 'pay-per') {
      getDefaultFilters()
      setCurrentPage(DEFAULT_PAGE)
      setRowsPerPage(ITEMS_PER_PAGE)
      fetchPrices()
    } else if (identifier === 'subscriptions') {
      getDefaultFilters()
      setCurrentPage(DEFAULT_PAGE)
      setRowsPerPage(ITEMS_PER_PAGE)
      fetchSubscriptions()
    } else if (identifier === 'promo-codes') {
      getDefaultFilters()
      setCurrentPage(DEFAULT_PAGE)
      setRowsPerPage(ITEMS_PER_PAGE)
      fetchPromos()
    }
  }, [identifier])

  const deleteMany = useCallback(async () => {
    if (identifier === 'pay-per') {
      await deletePrice(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      fetchPrices()
      closeDialog()
    } else if (identifier === 'subscriptions') {
      await deleteMembership(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      fetchSubscriptions()
      closeDialog()
    } else if (identifier === 'promo-codes') {
      await deletePromo(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      fetchPromos()
      closeDialog()
    }
    setSelectedRows([])
  }, [identifier, selectedRows])

  const handleClose = () => {
    setOpenForm(false)
    setCurrentItem(undefined)
  }

  const handleSearch = (event) => {
    setSearch(event.target.value)
  }

  const fetchPrices = async (showNewestFirst: boolean = false) => {
    try {
      setLoading(true)
      const data = await getPrices(currentPage + 1, rowsPerPage, search)
      setDataBackup([...data.items])
      setData(data)
      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const fetchSubscriptions = async (showNewestFirst: boolean = false) => {
    try {
      setLoading(true)
      const data = await getSubscriptions(currentPage + 1, rowsPerPage, search)
      setDataBackup([...data.items])
      setData(data)
      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const fetchPromos = async (showNewestFirst: boolean = false) => {
    try {
      setLoading(true)
      const data = await getPromos(currentPage + 1, rowsPerPage, search)
      setDataBackup([...data.items])
      setData(data)
      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenForm = async () => {
    if (isStripeEnabled) {
      setOpenForm(true)
    } else {
      displayMessage(
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>There is no Stripe account connected.</Typography>
          <Typography>Lets get your account setup</Typography>
        </Box>,
        'info',
      )
    }
  }

  const getFetchFunction = () => {
    if (identifier === 'pay-per') {
      return fetchPrices
    } else if (identifier === 'subscriptions') {
      return fetchSubscriptions
    } else {
      return fetchPromos
    }
  }

  useEffect(() => {
    if (currentItem) {
      setOpenForm(true)
    }
  }, [currentItem])

  useEffect(() => {
    if (selectedRows.length > 0) {
      setSelectedRows([])
    }
    fetchData()
  }, [identifier])

  useEffect(() => {
    getFetchFunction()()
  }, [search, currentPage, rowsPerPage])

  useEffect(() => {
    const fetchStripeData = async () => {
      const orgName = getOrganizationFromHost()
      const { stripe_enabled } = await getOrgDetailsPublic(orgName)
      setIsStripeEnabled(stripe_enabled)
    }
    fetchStripeData()
  }, [])

  const Form: JSX.Element = useMemo(() => {
    switch (identifier) {
      case 'pay-per':
        return (
          <PriceForm
            price={currentItem as IPrice}
            defaultPriceExists={defaultPriceExists}
            onClose={handleClose}
            displayMessage={displayMessage}
            success={fetchPrices}
          />
        )
      case 'subscriptions':
        return (
          <SubscriptionForm
            membership={currentItem as ISubscription}
            onClose={handleClose}
            displayMessage={displayMessage}
            success={fetchSubscriptions}
          />
        )
      case 'promo-codes':
        return (
          <PromoForm
            promo={currentItem as IPromo}
            onClose={handleClose}
            displayMessage={displayMessage}
            success={fetchPromos}
          />
        )
    }
  }, [currentItem, identifier, data])

  const visibleRows = useMemo(
    () => getVisibleRowsSorted(data.items, dataBackup),
    [data, order, orderBy],
  )

  const dialogButtonText = useMemo(
    () => (!isStripeEnabled ? 'Setup Stripe' : 'Ok'),
    [isStripeEnabled],
  )

  const onCloseDialogHandler = useMemo(
    () =>
      !isStripeEnabled
        ? () => {
            navigate('/pricing-set-up')
            closeDialog()
          }
        : closeDialog,
    [isStripeEnabled],
  )

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button
          variant="contained"
          name="addDevice"
          onClick={handleOpenForm}
          disabled={loading}
        >
          {addButtonText}
        </Button>
      ),
    },
  ]

  return (
    <>
      <Toolbar controls={controls.filter((control) => control.display)}>
        <ToolbarControls
          controls={controls.filter((control) => control.display)}
        />
        <ToolbarSearchbar
          handleSearch={handleSearch}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
        />
      </Toolbar>
      {selectedRows.length > 0 && (
        <MultiSelectToolbar
          itemsSelected={selectedRows.length}
          handleAction={handleDeleteMany}
        />
      )}
      {!loading && data.items.length > 0 && (
        <>
          <Table>
            <SortableTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={filteredColumns.filter((c) => c.active)}
              handleSelectAll={handleSelectAll}
            />
            <TableBody>
              {visibleRows.map((item) => (
                <ItemRow
                  key={item.id}
                  item={item}
                  setCurrentItem={setCurrentItem}
                  identifier={identifier}
                  filteredColumns={filteredColumns}
                  displayMessage={displayMessage}
                  success={getFetchFunction()}
                  handleSelectRow={handleSelectRow}
                  selectedRows={selectedRows}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            totalItems={data.total}
            currentPage={currentPage}
            itemsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setRowsPerPage}
          />
        </>
      )}

      <LoadingData isLoading={loading} />

      <NoData condition={!loading && data.items.length === 0} />

      {openForm && (
        <SideBar open={openForm} onClose={handleClose}>
          {Form}
        </SideBar>
      )}
      <NotificationDialog
        open={dialog.isOpen}
        onClose={onCloseDialogHandler}
        message={dialog.message}
        buttonText={dialogButtonText}
        type={dialog.type}
      />
    </>
  )
}

export default Pricing
