import { Chip, Link, Tooltip, Typography } from '@mui/material'
import {
  ConfirmDialog,
  IDialogType,
  IconButton,
  TableCell,
  TableRow,
} from 'components'
import { IOrganization } from 'models'
import moment from 'moment'
import { InfoOutlined, RefreshRounded } from '@mui/icons-material'
import HistoryIcon from '@mui/icons-material/History'
import { useState } from 'react'
import { organizationApi } from 'resources'

const defaultConfirmation = {
  isOpen: false,
  message: '',
  action: '',
}

interface IProductRowProps {
  organization: IOrganization
  myOrg: IOrganization
  success: () => void
  displayMessage: (message: string, type?: IDialogType) => void
  handleEditOrganization: (organization: IOrganization) => void
}

const OrganizationRow = ({
  organization,
  myOrg,
  success,
  displayMessage,
  handleEditOrganization,
}: IProductRowProps) => {
  const [confirmationMessage, setConfirmationMessage] =
    useState(defaultConfirmation)

  const { archive, restore } = organizationApi()

  const archiveOrganization = async () => {
    try {
      setConfirmationMessage({ isOpen: false, message: '', action: '' })
      await archive(organization.id)
      displayMessage('Organization has been archived!', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const restoreOrganization = async () => {
    try {
      setConfirmationMessage({ isOpen: false, message: '', action: '' })
      await restore(organization.id)
      displayMessage('Organization has been restored!', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }
  return (
    <>
      <TableRow>
        <TableCell>
          <Link
            sx={{
              display: 'flex',
              justifyContent: 'center',
              cursor: organization.active ? 'pointer' : undefined,
              textDecoration: 'none',
              color: !organization.active ? 'text.secondary' : undefined,
            }}
            onClick={() => handleEditOrganization(organization)}
          >
            <Typography>
              {organization.white_label?.app_name || organization.name}
            </Typography>
            {!organization.active && (
              <Chip
                color="default"
                label="Archived"
                size="small"
                sx={{
                  marginLeft: '0.5rem',
                }}
              />
            )}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            sx={{
              cursor: organization.active ? 'pointer' : undefined,
              textDecoration: 'none',
              color: !organization.active ? 'text.secondary' : undefined,
            }}
            href={
              organization.active
                ? `https://${organization.name}.koloni.io`
                : undefined
            }
          >
            {organization.name}.koloni.io
          </Link>
        </TableCell>
        <TableCell>
          <Typography
            fontSize={14}
            color={!organization.active ? 'text.secondary' : undefined}
          >
            {organization.white_label?.organization_owner || '-'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            fontSize={14}
            color={!organization.active ? 'text.secondary' : undefined}
          >
            {moment(organization.created_at).format('MM/DD/YYYY hh:mm A')}
          </Typography>
        </TableCell>
        <TableCell>
          {myOrg.id === organization.id ? (
            <>
              <IconButton
                onClick={() => {
                  displayMessage(
                    'You cannot archive the organization you are currently logged into.',
                    'error',
                  )
                }}
              >
                <InfoOutlined />
              </IconButton>
            </>
          ) : (
            <>
              {!organization.active && (
                <IconButton
                  onClick={() => {
                    setConfirmationMessage({
                      isOpen: true,
                      message: `Are you sure you want to restore this organization?`,
                      action: 'cancelDelete',
                    })
                  }}
                >
                  <Tooltip title="Restore Organization">
                    <RefreshRounded />
                  </Tooltip>
                </IconButton>
              )}

              {organization.active && (
                <IconButton
                  onClick={() => {
                    setConfirmationMessage({
                      isOpen: true,
                      message: `Are you sure you want to archive this organization?`,
                      action: 'delete',
                    })
                  }}
                >
                  <Tooltip title="Archive Organization">
                    <HistoryIcon />
                  </Tooltip>
                </IconButton>
              )}
            </>
          )}
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() => {
          setConfirmationMessage({ isOpen: false, message: '', action: '' })
        }}
        onClickConfirm={
          confirmationMessage.action === 'delete'
            ? archiveOrganization
            : restoreOrganization
        }
        onClickCancel={() => {
          setConfirmationMessage({ isOpen: false, message: '', action: '' })
        }}
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default OrganizationRow
