import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  useTheme,
} from '@mui/material'
import { Button } from 'components'
import { DATE_RANGES_OPTIONS } from '../constants'
import { useMemo } from 'react'
import { ILocation, IOrganization } from 'models'
import { Toolbar } from 'components/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import { ToolbarControl } from 'types'

interface IDashboardFilters {
  selectedRange: string
  setOpenCustomDateRangeSelector: React.Dispatch<React.SetStateAction<boolean>>
  handleSelectDateRange: (event: SelectChangeEvent) => void
  locations: ILocation[]
  subOrgs: any[]
  selfOrg: IOrganization | null
  selectedSubOrg: string | undefined
  setSelectedLocation: React.Dispatch<
    React.SetStateAction<ILocation | undefined>
  >
  setSelectedSubOrg: React.Dispatch<React.SetStateAction<string | undefined>>
}

const DashboardFilters = ({
  selectedRange,
  setOpenCustomDateRangeSelector,
  handleSelectDateRange,
  locations,
  subOrgs,
  selfOrg,
  selectedSubOrg,
  setSelectedLocation,
  setSelectedSubOrg,
}: IDashboardFilters) => {
  const theme = useTheme()

  const locationOptions = useMemo(
    () =>
      !selectedSubOrg
        ? [
            { id: 'all', label: 'All' },
            ...locations.map((location) => ({
              id: location.id,
              label: location.name,
            })),
          ]
        : locations
            .filter((location) => location.id_org === selectedSubOrg)
            .map((location) => ({
              id: location.id,
              label: location.name,
            })),
    [locations, selectedSubOrg],
  )

  const organizationOptions = useMemo(
    () => [
      ...subOrgs.map((org) => ({
        id: org.id,
        label: org.name,
      })),
    ],
    [subOrgs],
  )

  const handleSelectLocation = (
    event: React.SyntheticEvent,
    value: {
      id: string
      label: string
    } | null,
  ) => {
    const location = locations.find((l) => l.id === value?.id)
    setSelectedLocation(location)
  }

  const handleSelectOrganization = (
    event: React.SyntheticEvent,
    value: {
      id: string
      label: string
    } | null,
  ) => {
    const org = subOrgs.find((o) => o.id === value?.id)
    if (!org) {
      // Get information from the main organization if the user
      // clears out the target selector
      setSelectedSubOrg(undefined)
    } else {
      setSelectedSubOrg(org.id)
    }
  }

  const controls: ToolbarControl[] = [
    {
      display: selectedRange === 'custom',
      render: (
        <Button
          variant="contained"
          onClick={() => setOpenCustomDateRangeSelector(true)}
        >
          Select Dates
        </Button>
      ),
    },
    {
      display: true,
      render: (
        <FormControl size="small">
          <InputLabel>Date Range</InputLabel>
          <Select
            value={selectedRange}
            label="Date Range"
            onChange={handleSelectDateRange}
            inputProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundImage: 'none',
                    boxShadow: 3,
                    backgroundColor:
                      theme.palette.mode === 'dark' ? '#2A2E34' : '#f7f7f7',
                  },
                },
              },
            }}
          >
            {DATE_RANGES_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },
    {
      display: true,
      render: (
        <Autocomplete
          disablePortal
          options={organizationOptions}
          renderInput={(params) => (
            <TextField {...params} label="Organization..." />
          )}
          onChange={handleSelectOrganization}
          size="small"
        />
      ),
    },
    {
      display: true,
      render: (
        <Autocomplete
          disablePortal
          options={locationOptions}
          renderInput={(params) => (
            <TextField {...params} label="Location..." />
          )}
          onChange={handleSelectLocation}
          size="small"
        />
      ),
    },
  ]

  return (
    <Toolbar
      controls={controls.filter(
        (control) =>
          control.display && (control.isMenu === undefined || !control.isMenu),
      )}
    >
      <ToolbarControls
        controls={controls.filter((control) => control.display)}
      />
    </Toolbar>
  )
}

export default DashboardFilters
