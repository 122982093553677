import { DeleteForever } from '@mui/icons-material'
import { Checkbox, Link, Tooltip } from '@mui/material'
import {
  ConfirmDialog,
  IDialogType,
  IconButton,
  TableCell,
  TableRow,
} from 'components'
import { IReservation } from 'models/Reservations'
import { useState } from 'react'
import { reservationApi } from 'resources/reservations'
import { capitalizeFirstLetter, getUserRole } from 'utils/helpers'
import moment from 'moment'

interface IReservationRowProps {
  reservation: IReservation
  success: () => void
  handleEdit: (condition: IReservation) => void
  filteredColumns: any[]
  displayMessage: (message: string, type?: IDialogType) => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selected: boolean
}

const ReservationRow = ({
  reservation,
  success,
  handleEdit,
  filteredColumns,
  displayMessage,
  handleSelectRow,
  selected,
}: IReservationRowProps) => {
  const [confirmationMessage, setConfirmationMessage] = useState({
    isOpen: false,
    message: '',
  })

  const { remove } = reservationApi()

  const handleDeleteReservation = async (): Promise<void> => {
    try {
      await remove([reservation.id])
      success()
      displayMessage('Reservation deleted successfully!', 'success')
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const role = getUserRole()

  return (
    <>
      <TableRow>
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectRow(event, checked, reservation.id)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'mode' && c.active) !==
          -1 && (
          <TableCell align="center">
            {reservation.device?.mode === 'rental'
              ? 'Asset'
              : capitalizeFirstLetter(reservation.device?.mode)}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'tracking_number' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {reservation.tracking_number ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleEdit(reservation)
                }}
              >
                {reservation.tracking_number}
              </Link>
            ) : (
              '-'
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'user' && c.active) !==
          -1 && (
          <TableCell align="center">
            {reservation.user?.name ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleEdit(reservation)
                }}
              >
                {reservation.user.name}
              </Link>
            ) : (
              <>-</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'phone' && c.active) !==
          -1 && (
          <TableCell align="center">
            {reservation.user.phone_number ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleEdit(reservation)
                }}
              >
                {reservation.user.phone_number}
              </Link>
            ) : (
              <>-</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'email' && c.active) !==
          -1 && (
          <TableCell align="center">
            {reservation.user.email ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleEdit(reservation)
                }}
              >
                {reservation.user.email}
              </Link>
            ) : (
              <>-</>
            )}
          </TableCell>
        )}

        {filteredColumns.findIndex(
          (c) => c.value === 'duration' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {!reservation.recurring && reservation.recurring !== false
              ? '-'
              : reservation.recurring === true
              ? 'Recurring'
              : 'Custom'}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'created_at' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {moment(reservation.created_at).format('MM/DD/YYYY hh:mm A')}
          </TableCell>
        )}

        {filteredColumns.findIndex(
          (c) => c.value === 'start_date' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {reservation.start_date
              ? moment(reservation.start_date).format('MM/DD/YYYY hh:mm A')
              : '-'}
          </TableCell>
        )}

        {filteredColumns.findIndex(
          (c) => c.value === 'assigned_locker' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {reservation.device?.locker_number}
          </TableCell>
        )}

        {filteredColumns.findIndex(
          (c) => c.value === 'location' && c.active,
        ) !== -1 && (
          <TableCell align="center">{reservation.location?.name}</TableCell>
        )}

        {filteredColumns.findIndex((c) => c.value === 'size' && c.active) !==
          -1 && <TableCell align="center">{reservation.size?.name}</TableCell>}

        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role !== 'member' && (
              <IconButton
                onClick={() => {
                  setConfirmationMessage({
                    isOpen: true,
                    message: `Are you sure you want to delete this reservation? This cannot be undone.`,
                  })
                }}
              >
                <Tooltip title="Delete">
                  <DeleteForever />
                </Tooltip>
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>

      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
          })
        }
        onClickConfirm={handleDeleteReservation}
        onClickCancel={() =>
          setConfirmationMessage({
            isOpen: false,
            message: '',
          })
        }
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default ReservationRow
