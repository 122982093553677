import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material'
import useLoadingState from 'hooks/useLoadingState'
import { ITransaction, ITransactionLog } from 'models'
import { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab'
import { transactionsApi } from 'resources'
import TransactionLog from './TransactionLog'

interface ITransactionHistory {
  transaction: ITransaction
}

const TransactionHistory = ({ transaction }: ITransactionHistory) => {
  const [transactionHistory, setTransactionHistory] = useState<
    ITransactionLog[]
  >([])

  const { loading, setLoading } = useLoadingState()

  const { getTransactionHistory } = transactionsApi()

  const fetchTransactionHistory = async () => {
    try {
      setLoading(true)
      const history = await getTransactionHistory(transaction.id)
      setTransactionHistory(history)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTransactionHistory()
  }, [])

  return (
    <>
      {loading && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && transactionHistory.length === 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography color="text.secondary">
            No logs available for this transaction.
          </Typography>
        </Box>
      )}

      {!loading && transactionHistory.length > 0 && (
        <Box
          sx={{
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: '1.5rem',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <Typography variant="h3">Transaction History</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h5">
                Device: {transaction.device.name}
              </Typography>
              <Typography variant="h5">
                Door number: {transaction.device.locker_number}
              </Typography>
              <Typography variant="h5">
                Location: {transaction.device.location.name}
              </Typography>
            </Box>
          </Box>
          <Accordion defaultExpanded sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Logs...</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                overflowX: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                padding: 0,
              }}
            >
              <Timeline sx={{ padding: 0 }}>
                {transactionHistory.map((log, index) => (
                  <TimelineItem
                    key={index}
                    sx={{
                      '&::before': {
                        flex: 'none',
                      },
                    }}
                  >
                    <TimelineSeparator>
                      <TimelineDot color="primary" />
                      {index !== transactionHistory.length - 1 && (
                        <TimelineConnector />
                      )}
                    </TimelineSeparator>
                    <TimelineContent>
                      <TransactionLog log={log} />
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </>
  )
}

export default TransactionHistory
