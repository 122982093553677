import { Box, useTheme } from '@mui/material'

interface IToggleItem {
  label: string
  onClick: Function
  selected: boolean | undefined
}

interface IToggleSwitch {
  leftItem: IToggleItem
  rightItem: IToggleItem
  isReadOnly?: boolean
}

const ToggleSwitch = ({
  leftItem,
  rightItem,
  isReadOnly = false,
}: IToggleSwitch) => {
  const theme = useTheme()

  const handleClickLeftItem = () => {
    if (rightItem.selected) {
      leftItem.onClick()
    }
  }

  const handleClickRightItem = () => {
    if (leftItem.selected) {
      rightItem.onClick()
    }
  }

  return (
    <Box
      mx="auto"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        padding: '0.5rem 0.5rem',
        gap: '0.25rem',
        width: 'fit-content',
        background:
          theme.palette.mode === 'dark'
            ? theme.palette.cancel.main
            : theme.palette.grey[100],
      }}
    >
      <Box
        onClick={!isReadOnly ? handleClickLeftItem : undefined}
        sx={{
          cursor: 'pointer',
          borderRadius: 0.5,
          padding: '0.5rem 1rem',
          background: leftItem.selected
            ? theme.palette.primary.main
            : undefined,
          color:
            leftItem.selected && theme.palette.mode === 'light'
              ? 'white'
              : undefined,
          opacity: isReadOnly ? '0.5' : undefined,
        }}
      >
        {leftItem.label}
      </Box>
      <Box
        onClick={!isReadOnly ? handleClickRightItem : undefined}
        sx={{
          cursor: 'pointer',
          borderRadius: 0.5,
          padding: '0.5rem 1rem',
          background: rightItem.selected
            ? theme.palette.primary.main
            : undefined,
          color:
            rightItem.selected && theme.palette.mode === 'light'
              ? 'white'
              : undefined,
          opacity: isReadOnly ? '0.5' : undefined,
        }}
      >
        {rightItem.label}
      </Box>
    </Box>
  )
}

export default ToggleSwitch
