import React, { SyntheticEvent, useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  Typography,
  TextField as MUITextField,
  CircularProgress,
  Chip,
  Autocomplete,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { Form, TextField, ErrorMessage, IDialogType, Tabs } from 'components'
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form'
import { IGroup, IUser } from 'models'
import { userApi } from '../../../../resources/user'
import { groupApi } from 'resources'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import useLoadingState from 'hooks/useLoadingState'
import { Help } from 'components/Help'
import { DropdownOption } from 'types'
import UserHistory from './UserHistory'
import BulkUploadForm from './bulkUploadForm'
import { getUserRole } from 'utils/helpers'

interface IUserFormProps {
  successForm?: (showNewestFirst?: boolean) => void
  onClose: () => void
  user: IUser | undefined
  displayMessage?: (message: string, type?: IDialogType) => void
  allowEdit?: boolean
}

const UserForm = ({
  successForm,
  onClose,
  user,
  displayMessage,
  allowEdit = true,
}: IUserFormProps): React.ReactElement => {
  const [assignedGroups, setAssignedGroups] = useState<IGroup[]>([])
  const [groups, setGroups] = useState<IGroup[]>([])
  const [groupsToAdd, setGroupsToAdd] = useState<string[]>([])
  const [groupsToRemove, setGroupsToRemove] = useState<string[]>([])
  const [options, setOptions] = useState<DropdownOption[]>([])
  const [isLoadingGroups, setIsLoadingGroups] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<string>('form')

  const { updateUser, create } = userApi()
  const { getMany, removeUser } = groupApi()
  const { loading, setLoading } = useLoadingState()

  const methods = useForm<IUser>({
    defaultValues: { ...user },
  })

  const fetchGroups = async (): Promise<void> => {
    try {
      const groups = await getMany(1, 10000, '')
      setGroups(groups.items)
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    }
  }

  const getGoupsAssignedToUser = async () => {
    try {
      setIsLoadingGroups(true)
      const groups = await getMany(1, 1000)
      const userGroups = groups.items.filter((group) =>
        group.users.some((u) => u.id === user?.id),
      )
      setAssignedGroups(userGroups)
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setIsLoadingGroups(false)
    }
  }

  const handleSubmit: SubmitHandler<IUser> = async (data) => {
    try {
      setLoading(true)
      if (user) {
        if (groupsToRemove.length > 0) {
          const promises = groupsToRemove.map(async (id) => {
            return removeUser(id, user.id)
          })
          await Promise.all(promises)
        }
        await updateUser(data.id, {
          ...data,
          user_id: data.user_id || null,
          pin_code: data.pin_code || null,
          email: data.email?.toLowerCase().trim(),
          groups: [...groupsToAdd],
        })

        successForm?.()
        onClose()
        displayMessage?.('User updated correctly', 'success')
      } else {
        await create([
          {
            ...data,
            user_id: data.user_id || null,
            pin_code: data.pin_code || null,
            email: data.email?.toLowerCase().trim(),
            groups: [...groupsToAdd],
          },
        ])
        successForm?.(true)
        onClose()
        displayMessage?.('User created correctly', 'success')
      }
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleToggle = (event: React.SyntheticEvent, value: any) => {
    const groupsSelected: string[] = value.map((option) => option.value)
    setGroupsToAdd(groupsSelected)
  }

  const buildOptions = () => {
    if (groups.length > 0) {
      const notAssignedGroups = groups.filter(
        (group) => !assignedGroups.some((g) => g.id === group.id),
      )
      const autoCompleteOptions = notAssignedGroups.map((group) => ({
        label: `${group.name}`,
        value: group.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setOptions(sortedOptions)
    }
  }

  const handleSelect = (userId: string) => {
    if (groupsToRemove.includes(userId)) {
      setGroupsToRemove(groupsToRemove.filter((id) => id !== userId))
    } else {
      setGroupsToRemove((prevState) => [...prevState, userId])
    }
  }

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }

  useEffect(() => {
    buildOptions()
  }, [assignedGroups])

  useEffect(() => {
    fetchGroups()
    getGoupsAssignedToUser()
  }, [])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  const UserForm = (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit}>
        <FormWrapper
          title={
            user ? 'Edit User' : allowEdit && user ? 'User Details' : 'Add User'
          }
        >
          <TextField
            name="name"
            label="Name"
            placeholder="Name"
            isReadOnly={!allowEdit}
          />

          <FormControl fullWidth>
            <MUITextField
              {...methods.register('email', {
                validate: {
                  required: (value) =>
                    !methods.watch('phone_number') && !value
                      ? 'Email is required'
                      : true,
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
              fullWidth
              label="Email"
              placeholder="example@gmail.com"
              InputProps={{
                readOnly: !allowEdit,
              }}
            />
            <ErrorMessage errors={methods.formState.errors} name="email" />
          </FormControl>

          <TextField
            name="address"
            label="Address"
            placeholder="Address"
            isReadOnly={!allowEdit}
          />

          <FormControl fullWidth>
            <MUITextField
              {...methods.register('phone_number', {
                validate: {
                  required: (value) =>
                    !methods.watch('email') && !value
                      ? 'Phone number is required'
                      : true,
                },
              })}
              fullWidth
              label="Phone Number"
              placeholder="(123) 456-7890"
              InputProps={{
                readOnly: !allowEdit,
              }}
            />
            <ErrorMessage
              errors={methods.formState.errors}
              name="phone_number"
            />
          </FormControl>

          <TextField
            name="user_id"
            label="User ID"
            placeholder="User ID"
            isReadOnly={!allowEdit}
          />

          <TextField
            name="pin_code"
            label="PIN Code"
            placeholder="0000"
            isReadOnly={!allowEdit}
            rules={{
              pattern: {
                value: /^[0-9]{4}$/,
                message: 'Invalid Pin Code',
              },
            }}
          />

          <Help helpText="Authorized transaction code deliveries will send an unique code to the user. This code will be used at the delivery to verify the user.">
            <Typography variant="h5" sx={{ textAlign: 'left' }}>
              Authorized transaction code deliveries
            </Typography>
          </Help>

          <FormControl>
            <RadioGroup
              row
              aria-labelledby="require-auth-radio-group-label"
              defaultValue={user?.require_auth ? 'yes' : 'no'}
              {...methods.register('require_auth')}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="No"
                sx={{
                  marginLeft: '1em',
                }}
              />
            </RadioGroup>
          </FormControl>

          {user && (
            <>
              <Typography
                variant="h5"
                sx={{
                  textAlign: 'left',
                }}
              >
                Groups assigned to this user
              </Typography>
              <Typography variant="caption" color="rgb(173, 176, 187)">
                {assignedGroups?.length > 0
                  ? 'Select groups that you want to assign to this user'
                  : `No groups assigned to this user.`}
                {isLoadingGroups && <CircularProgress />}
              </Typography>
              {assignedGroups?.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    gap: '0.25rem',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {assignedGroups.map((group) => (
                    <Chip
                      key={group.id}
                      label={group.name}
                      onClick={() => handleSelect(group.id)}
                      onDelete={() => handleSelect(group.id)}
                      deleteIcon={
                        groupsToRemove.includes(group.id) ? (
                          <DoneIcon />
                        ) : (
                          <DeleteIcon />
                        )
                      }
                      variant={
                        groupsToRemove.includes(group.id)
                          ? 'filled'
                          : 'outlined'
                      }
                      color={
                        groupsToRemove.includes(group.id)
                          ? 'primary'
                          : 'default'
                      }
                    />
                  ))}
                </Box>
              )}
            </>
          )}

          <Typography
            variant="h5"
            sx={{
              textAlign: 'left',
            }}
          >
            Assign user to groups
          </Typography>
          <Typography variant="caption" color="rgb(173, 176, 187)">
            Select groups that you want to assign to this user to and hit submit
          </Typography>
          <Autocomplete
            multiple
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => (
              <MUITextField {...params} label="Search groups..." />
            )}
            onChange={handleToggle}
            loading={isLoadingGroups}
            loadingText={
              groups.length === assignedGroups.length
                ? 'No groups to add...'
                : 'Loading...'
            }
          />

          {allowEdit && (
            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={disableSubmit}
            />
          )}
        </FormWrapper>
      </Form>
    </FormProvider>
  )

  return (
    <>
      {user && (
        <Tabs
          tabs={[
            {
              label: 'Edit User',
              value: 'form',
              children: UserForm,
            },
            {
              label: 'User History Log',
              value: 'history',
              children: <UserHistory user={user} />,
            },
          ]}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}
      {getUserRole() !== 'admin' && !user && UserForm}
      {getUserRole() === 'admin' && !user && (
        <Tabs
          tabs={[
            {
              label: 'Individual Upload',
              value: 'form',
              children: UserForm,
            },
            {
              label: 'Bulk Upload',
              value: 'BulkUpload',
              children: (
                <BulkUploadForm
                  successForm={() => successForm?.()}
                  onClose={onClose}
                />
              ),
            },
          ]}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}
    </>
  )
}

export default UserForm
