import { Box } from '@mui/material'
import {
  NotificationDialog,
  SideBar,
  Table,
  TableBody,
  TablePagination,
} from 'components'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { DEFAULT_PAGE, ITEMS_PER_PAGE } from 'constants/pagination'
import useDialog from 'hooks/useDialog'
import { membershipApi } from 'resources'
import MembershipRow from './MembershipRow'
import useSortableHeader from 'hooks/useSortableHeader'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import useColumnFiltering from 'hooks/useColumnFiltering'
import { IUser } from 'models'
import UserForm from 'pages/People/components/users/UserForm'
import NoData from 'components/PageBase/NoData'
import LoadingData from 'components/PageBase/LoadingData'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'

const Membership = () => {
  const [userMemberships, setUserMemberships] = useState<any>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [userMembershipsBackup, setUserMembershipsBackup] = useState<any>([])
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openUserForm, setOpenUserForm] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<IUser | undefined>()

  const { dialog, displayMessage, closeDialog } = useDialog()
  const { filteredColumns, setFilteredColumns, getDefaultFilters } =
    useColumnFiltering({ displayMessage })

  const { getMany } = membershipApi()

  const { order, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'name',
      entity: 'subscribers',
      nestedProps: [
        {
          columnValue: 'membership_name',
          path: 'name',
          defaultValue: '',
        },
        {
          columnValue: 'name',
          path: 'user.name',
          defaultValue: '',
        },
        {
          columnValue: 'price',
          path: 'amount',
          defaultValue: 0,
        },
        {
          columnValue: 'no_of_transactions',
          path: 'number_of_payments',
          defaultValue: 0,
        },
        {
          columnValue: 'recurring',
          path: 'billing_type',
          defaultValue: '',
        },
      ],
    })

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }

  const fetchUserMemberships = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const userMemberships = await getMany(
        currentPage + 1,
        rowsPerPage,
        '',
        true, // Return memberships with subscriptions only
      )

      const subscribedUsersWithMembershipDetails =
        userMemberships.items.flatMap((item) => {
          const membershipDetails = {
            price: item.amount,
            currency: item.currency,
            expiration_date: item.expiration_date,
            recurring: item.billing_type === 'recurring' ? 'Yes' : 'No',
            name: item.name,
            no_of_transactions: item.number_of_payments,
          }

          return item.users.map((user) => ({
            details: membershipDetails,
            user: user,
          }))
        })

      setUserMembershipsBackup([...subscribedUsersWithMembershipDetails])

      setUserMemberships({
        items: subscribedUsersWithMembershipDetails,
        total: userMemberships.total,
        pages: userMemberships.pages,
      })
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUserMemberships()
    getDefaultFilters()
  }, [search, currentPage, rowsPerPage])

  const visibleRows = useMemo(
    () => getVisibleRowsSorted(userMemberships.items, userMembershipsBackup),
    [userMemberships, order, orderBy],
  )

  return (
    <>
      <Toolbar controls={[]}>
        <ToolbarSearchbar
          handleSearch={handleSearch}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
        />
      </Toolbar>

      {!isLoading && userMemberships.items.length > 0 && (
        <>
          <Table>
            <SortableTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={filteredColumns.filter((c) => c.active)}
              handleSelectAll={() => {}}
            />
            <TableBody>
              {userMemberships.items.length > 0 &&
                visibleRows.map((membership) => (
                  <MembershipRow
                    key={membership.user.id}
                    membership={membership}
                    openUserForm={() => {
                      setOpenUserForm(true)
                    }}
                    setCurrentUser={setCurrentUser}
                    filteredColumns={filteredColumns}
                    success={fetchUserMemberships}
                  />
                ))}
            </TableBody>
          </Table>
          <TablePagination
            totalItems={userMemberships.total}
            currentPage={currentPage}
            itemsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setRowsPerPage}
          />
        </>
      )}

      <LoadingData isLoading={isLoading} />

      <NoData condition={!isLoading && userMemberships.items.length === 0} />

      {openUserForm && (
        <SideBar open={openUserForm} onClose={() => setOpenUserForm(false)}>
          <UserForm
            user={currentUser}
            onClose={() => {
              setOpenUserForm(false)
              setCurrentUser(undefined)
            }}
            allowEdit={false}
          />
        </SideBar>
      )}
      <NotificationDialog
        message={dialog.message}
        open={dialog.isOpen}
        onClose={closeDialog}
        type={dialog.type}
      />
    </>
  )
}

export default Membership
