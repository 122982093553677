import { ContentCopy, DeleteForever, QrCode } from '@mui/icons-material'
import { Checkbox, Link, Tooltip } from '@mui/material'
import {
  ConfirmDialog,
  IDialogType,
  IconButton,
  Image,
  QRGenerator,
  TableCell,
  TableRow,
} from 'components'
import { IProduct } from 'models/Product'
import { IProductGroup } from 'models/ProductGroup'
import { useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { productApi } from 'resources/product/api'
import { getUserRole, parseCurrency } from 'utils/helpers'

interface IProductRowProps {
  product: IProduct
  success: () => void
  displayMessage: (message: string, type?: IDialogType) => void
  filteredColumns: any[]
  handleEditProduct: (product: IProduct) => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selected: boolean
  groups: IProductGroup[]
}

const defaultConfirmation = {
  isOpen: false,
  message: '',
  action: '',
}

const ProductRow = ({
  product,
  success,
  displayMessage,
  filteredColumns,
  handleEditProduct,
  handleSelectRow,
  selected,
  groups,
}: IProductRowProps) => {
  const [confirmationMessage, setConfirmationMessage] =
    useState(defaultConfirmation)
  const [image, setImage] = useState<File | null>(null)
  const [productToDuplicate, setProductToDuplicate] = useState<IProduct | null>(
    null,
  )

  const { remove, duplicate } = productApi()
  const { generateQRCode } = QRGenerator()

  const currency = useSelector(
    (state: RootStateOrAny) => state.currencyReducer.currency,
  )

  const role = getUserRole()

  const handleDeleteProduct = async () => {
    try {
      await remove([product?.id])
      displayMessage('Product deleted successfully', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleDuplicateProduct = async () => {
    try {
      if (!productToDuplicate) return

      await duplicate(productToDuplicate.id)

      displayMessage?.('Product created successfully!', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleQRCode = (): void => {
    generateQRCode(product.id, product.id)
  }

  return (
    <>
      <TableRow>
        {filteredColumns.findIndex((c) => c.value === 'select' && c.active) !==
          -1 && (
          <TableCell align="center">
            {role === 'admin' ? (
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent, checked: boolean) => {
                  handleSelectRow(event, checked, product.id)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={true}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'image' && c.active) !==
          -1 && (
          <TableCell align="center">
            {product.image && (
              <Image
                src={product.image}
                alt={product?.name}
                style={{
                  outline: 'none',
                  borderRadius: '5px',
                }}
              />
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'product_name' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {role !== 'member' ? (
              <Link
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => handleEditProduct(product)}
              >
                {product?.name}
              </Link>
            ) : (
              <>{product?.name}</>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'serial_number' && c.active,
        ) !== -1 && (
          <TableCell align="center">{product.serial_number}</TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'assigned_locker' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {product.devices[0]?.locker_number || 'Unassigned'}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'assigned_location' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {product.devices[0]?.location?.name || 'No location'}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'cost' && c.active) !==
          -1 && (
          <TableCell align="center">
            {product.price && (
              <>
                {parseCurrency(currency)}
                {product?.price}
              </>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex(
          (c) => c.value === 'sale_price' && c.active,
        ) !== -1 && (
          <TableCell align="center">
            {product.sales_price && (
              <>
                {parseCurrency(currency)}
                {product?.sales_price}
              </>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'sku' && c.active) !==
          -1 && <TableCell align="center">{product?.sku}</TableCell>}
        {filteredColumns.findIndex((c) => c.value === 'msrp' && c.active) !==
          -1 && (
          <TableCell align="center">
            {product.msrp && (
              <>
                {parseCurrency(currency)}
                {product.msrp}
              </>
            )}
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'groups' && c.active) !==
          -1 && (
          <TableCell align="center">
            {
              groups.find((group) => group.id === product.id_product_group)
                ?.name
            }
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'qr_code' && c.active) !==
          -1 && (
          <TableCell align="center">
            <IconButton
              onClick={handleQRCode}
              sx={{
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              <QrCode />
            </IconButton>
          </TableCell>
        )}
        {filteredColumns.findIndex((c) => c.value === 'action' && c.active) !==
          -1 && (
          <TableCell align="center">
            <IconButton
              onClick={() => {
                setProductToDuplicate(product)
                setConfirmationMessage({
                  isOpen: true,
                  message: 'Are you sure you want to duplicate this product?',
                  action: 'duplicate',
                })
              }}
            >
              <Tooltip title="Duplicate">
                <ContentCopy />
              </Tooltip>
            </IconButton>

            <IconButton
              onClick={() => {
                setConfirmationMessage({
                  isOpen: true,
                  message: 'Are you sure you want to delete this product?',
                  action: 'delete',
                })
              }}
            >
              <Tooltip title="Delete">
                <DeleteForever />
              </Tooltip>
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <ConfirmDialog
        open={
          confirmationMessage.isOpen && confirmationMessage.action === 'delete'
        }
        message={confirmationMessage.message}
        onClose={() => setConfirmationMessage(defaultConfirmation)}
        onClickConfirm={handleDeleteProduct}
        onClickCancel={() => setConfirmationMessage(defaultConfirmation)}
        confirmText="Yes"
        cancelText="No"
      />
      <ConfirmDialog
        open={
          confirmationMessage.isOpen &&
          confirmationMessage.action === 'duplicate'
        }
        message={confirmationMessage.message}
        onClose={() => setConfirmationMessage(defaultConfirmation)}
        onClickConfirm={handleDuplicateProduct}
        onClickCancel={() => setConfirmationMessage(defaultConfirmation)}
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default ProductRow
